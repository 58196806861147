import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";
import ClientTotalUtil from '../../../../util/ClientTotalUtil';
import Maths from '../../../../util/Maths';
import CollaboratorsModal from "./CollaboratorsModal";
import { removeCollaborator } from "../../../../actions/collaborators/collaborators";
import Paginator from "../../../sub/Paginator";
import { getOldProducts } from "../../../../actions/products/oldProducts";
import CollaboratorTypes from "./collaboratorTypes/CollaboratorTypes";
import Roles from "../../../../enums/Roles";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import ActionMenu from "../../../sub/ActionMenu";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class Collaborators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null,
        };

        this.paginator = new Paginator(this);
    }

    openCollabsModal(collaborator) {
        var collaboratorId = collaborator ? collaborator._id : null;

        this.setState({
            modal: (
                <CollaboratorsModal
                    close={() => this.closeModal()}
                    collaboratorId={collaboratorId}
                    onCollaboratorCreated={(collaborator) =>
                        this.onCollaboratorCreated(collaborator)
                    }
                />
            ),
        });
    }

    openConfModal(user) {
        let content = <div>
            <p><FormattedMessage id="User.Remove.Confirmation1" /></p>
            <p><FormattedMessage id="User.Remove.Confirmation2" values={{ name: `${user.first_name} ${user.name}` }} /></p>
        </div>

        this.setState({
            modal: (
                <ConfirmationModal
                    context={"danger"}
                    title={<FormattedMessage id="Warning" />}
                    mandatoryConfirmation
                    content={content}
                    successCallback={() => this.props.onRemoveCollaborators({ collaboratorId: user._id })}
                    closeModal={() => this.closeModal()}
                />
            ),
        });
    }

    onCollaboratorCreated(collaborator) {
        this.setState({ modal: null }, () => this.openCollabsModal(collaborator));
    }

    roleBadge(roleId) {
        let badgeClass = "success";

        switch (roleId) {
            case Roles.ADMIN:
            case Roles.SUPER_ADMIN:
                badgeClass = "danger";
                break;
            default:
                break;
        }

        return (
            <div className={"badge badge-" + badgeClass}>
                <FormattedMessage id={"Role." + roleId} />
            </div>
        );
    }

    closeModal() {
        this.setState({ modal: null });
    }

    render() {
        if (this.props.collaborators.length === 0) {
            return (
                <React.Fragment>
                    <TableToolbar>
                        <div className="alert alert-info w-100">
                            <FormattedMessage id="Empty.Collaborators" />
                        </div>
                        <button
                            className="btn btn-info ml-auto"
                            onClick={(e) => this.openCollabsModal()}
                        >
                            <FormattedMessage id="Add.Collaborator" />
                        </button>
                    </TableToolbar>

                    {this.state.modal}
                </React.Fragment>
            );
        }

        this.paginator.init();
        var collaboratorsNode = this.props.collaborators
            .filter((collaborator) => collaborator._id !== this.props.user._id)
            .map((collaborator) => {
                if (!this.paginator.keep()) return null;

                var prescribersNbr = 0;
                if (collaborator.prescribers) prescribersNbr = collaborator.prescribers.length;

                var totalHT = 0;

                if (collaborator.prescribers) {
                    var collaboratorCLientsId = [];

                    for (var client of this.props.clients) {
                        for (var prescriber of collaborator.prescribers) {
                            if (client._id === prescriber.prescriber_id) collaboratorCLientsId.push(client)
                        }
                    }
                    let prescriberTotalUtil = new ClientTotalUtil(
                        collaboratorCLientsId, this.props.orders, this.props.products, this.props.oldProducts, (data) => this.props.onGetOldProducts(data));

                    totalHT += Maths.round(prescriberTotalUtil.getTotalHT());
                }

                totalHT = Maths.round(totalHT);

                let menuItems = [];

                menuItems.push(
                    {
                        icon: "pen-to-square",
                        action: () => this.openCollabsModal(collaborator),
                        text: <FormattedMessage id="Modify" />,
                        disabled: collaborator.role === Roles.ADMIN || collaborator.role === Roles.SUPER_ADMIN
                    },
                    {
                        icon: "trash",
                        action: () => this.openConfModal(collaborator),
                        text: <FormattedMessage id="Delete" />,
                        disabled: collaborator.role === Roles.ADMIN || collaborator.role === Roles.SUPER_ADMIN
                    },
                );

                const menuAction = (
                    <ActionMenu
                        items={menuItems}
                        disabled={collaborator.role === Roles.ADMIN || collaborator.role === Roles.SUPER_ADMIN}
                    />
                );

                return (
                    <tr key={collaborator._id} className="d-flex">
                        <td className="col">{collaborator.name}</td>
                        <td className="col">{collaborator.first_name}</td>
                        <td className="col">{collaborator.username}</td>
                        <td className="col">{this.roleBadge(collaborator.role)}</td>
                        <td className="col d-md-table-cell">{prescribersNbr}</td>
                        <td className="col">{totalHT}€</td>
                        <td className="col col-1 text-center">
                            {menuAction}
                        </td>
                    </tr>
                );
            });

        return (
            <React.Fragment>
                <Tabs defaultActiveKey="collaborators" id="collaboratorsTabs">
                    <Tab
                        eventKey="collaborators"
                        title={this.props.intl.formatMessage({ id: "List" })}
                    >
                        {!this.props.limit && (
                            <TableToolbar>
                                <button
                                    className="btn btn-info ml-auto"
                                    onClick={(e) => this.openCollabsModal()}
                                >
                                    <FormattedMessage id="Add.Collaborator" />
                                </button>
                            </TableToolbar>
                        )}

                        <table className="table tablee4mad col-12">
                            <thead>
                                <tr className="d-flex">
                                    <th scope="col" className="col">
                                        <FormattedMessage id="Name" />
                                    </th>
                                    <th scope="col" className="col">
                                        <FormattedMessage id="First.Name" />
                                    </th>
                                    <th scope="col" className="col">
                                        <FormattedMessage id="Username" />
                                    </th>
                                    <th scope="col" className="col">
                                        <FormattedMessage id="Role" />
                                    </th>
                                    <th scope="col" className="col">
                                        <FormattedMessage id="Clients.Nbr" />
                                    </th>
                                    <th scope="col" className="col"><FormattedMessage id="Total.Cmd.Excl.Tax" /></th>
                                    <th scope="col" className="col col-1 text-center">
                                        <FormattedMessage id="Actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{collaboratorsNode}</tbody>
                        </table>

                        {this.paginator.render()}

                        {this.state.modal}
                    </Tab>
                    <Tab
                        eventKey="collaboratorTypes"
                        title={this.props.intl.formatMessage({ id: "Collaborators.Types" })}
                    >
                        <CollaboratorTypes collaborators={this.props.collaborators} />
                    </Tab>
                </Tabs>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        collaborators: state.collaborators,
        products: state.products,
        oldProducts: state.oldProducts,
        orders: state.orders,
        clients: state.clients,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetOldProducts: (data) => dispatch(getOldProducts(data)),
        onRemoveCollaborators: (data) => dispatch(removeCollaborator(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Collaborators));
