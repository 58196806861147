import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import CheckpointsRow from "./CheckpointsRow";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import CreateCheckpointModal from "./CreateCheckpointModal";
import { nanoid } from "nanoid";
import { Alert } from "react-bootstrap";

function Checkpoints({
  auditDMId,
  checkpoints,
  onSort,
  onUpdateCheckpoint,
  onDeleteCheckpoint,
}) {
  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  const openEditModal = (checkpoint) => {
    if (!checkpoint) return;
    setModal(
      <CreateCheckpointModal
        auditDMId={auditDMId}
        checkpoint={checkpoint}
        onUpdateCheckpoint={(data) => onUpdateCheckpoint(data)}
        close={() => closeModal()}
      />
    );
  };

  const openConfModal = (title, content, successCallback) => {
    setModal(
      <ConfirmationModal
        title={title}
        content={content}
        successCallback={successCallback}
        closeModal={() => closeModal()}
      />
    );
  };

  const checkpointsNode =
    checkpoints &&
    checkpoints.map((checkpoint) => {
      return (
        <CheckpointsRow
          key={nanoid()}
          checkpoints={checkpoints}
          checkpoint={checkpoint}
          onSort={(checkpoint, direction) => onSort(checkpoint, direction)}
          onDeleteCheckpoint={(checkpoint) => onDeleteCheckpoint(checkpoint)}
          openEditModal={(checkpoint) => openEditModal(checkpoint)}
          openConfModal={(title, content, successCallback) =>
            openConfModal(title, content, successCallback)
          }
        />
      );
    });

  return (
    <>
      {checkpoints && checkpoints.length > 0 ? (
        <table className="table table-striped table-bordered col-12 tablee4mad">
          <thead>
            <tr>
              <th className="col-10">
                <FormattedMessage id="Checkpoint" />
              </th>
              <th className="col-2 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>

          <tbody>{checkpointsNode}</tbody>
        </table>
      ) : (
        <Alert variant="info">
          <FormattedMessage id="Empty.Checkpoint" />
        </Alert>
      )}

      {modal}
    </>
  );
}

export default Checkpoints;
