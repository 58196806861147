import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DateUtil from '../../util/DateUtil';
import APIUrl from '../../APIUrl';
import Roles from '../../enums/Roles';
import AuditCommentModal from './AuditCommentModal';
import { deleteAudit, getAuditsAdmin, sendAudit } from "../../actions/audits/audits";
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import NotificationModal from "../sub/modals/NotificationModal";
import FileUtil from '../../util/FileUtil';
import ErrorModal from '../sub/modals/ErrorModal';
import Util from "../../util/Util";
import EmailModal from '../sub/modals/EmailModal';
import { Modal } from 'react-bootstrap';
import MenuButton from "../sub/bootstrap/MenuButton";

class PatientAudit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            patientAuditsNode: null,
        };
    }

    getAuditsPatient(audits, patient) {
        return audits.filter((p) => p.patient_id === patient._id);
    }

    commentModal(audit) {

        this.setState({ modal: null },
            () => this.setState({
                modal: <AuditCommentModal
                    isOpen={true}
                    audit={(audit) ? audit : null}
                    close={() => this.closeModal()}
                />
            })
        );
    }

    closeModal() {
        this.setState({ modal: null });
    }

    emailModal(audit) {
        this.setState({ modal: null },
            () => this.setState({
                modal: (
                    <ModalBlock
                        audit={audit ? audit : null}
                        patient={this.props.patient}
                        documents={this.props.documents}
                        prescribers={this.props.prescribers}
                        helpers={this.props.helpers}
                        sendEmails={(emails, documents, audit) =>
                            this.sendEmails(emails, documents, audit)
                        }
                        close={() => this.closeModal()}
                    />
                ),
            })
        );
    }

    sendEmails(emails, documents, audit) {
        if (emails.length > 0) {
            let data = {
                patientId: this.props.patient._id,
                auditId: audit._id,
                emailsToSend: emails,
            };

            if (documents) data.documents = documents;

            this.setState({
                disabled: true,
            });

            let successCallback = (response) => {
                let title = <FormattedMessage id="Mail.Audit.Sent.Title" />;
                let content = <FormattedMessage id="Mail.Audit.Sent.Content" />;
                if (response.data !== 'OK') {
                    content = <FormattedMessage id="Mail.Audit.Sent.Content.Error" />;
                    this.setState({
                        modal: <NotificationModal
                            key={this.state.audit_id}
                            title={title}
                            content={content}
                            context="danger"
                            buttonLabel={this.props.intl.formatMessage({ id: "Close" })}
                            successCallback={() => { return null; }}
                            closeModal={() => { this.closeModal() }} />
                    });
                } else {
                    this.setState({
                        modal: <NotificationModal
                            key={this.state.audit_id}
                            title={title}
                            content={content}
                            context="success"
                            buttonLabel={this.props.intl.formatMessage({ id: "Close" })}
                            successCallback={() => { return null; }}
                            closeModal={() => { this.closeModal() }} />
                    });
                }
            };

            this.props.onSendAudit(data, successCallback);
        } else {
            this.props.close();
        }
    }

    // DELETE AN AUDIT
    _deleteAudit(auditId) {
        const onConfirm = () => {
            let data = {
                auditId: auditId,
                patienId: this.props.patient._id,
            };

            this.props.onDeleteAudit(data, () => {
                this.props.onGetAuditsAdmin();
            });
        }

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={<FormattedMessage id="Warning" />}
                content={<><FormattedMessage id="Confirm.Audit.Removal" /><p><FormattedMessage id="Please.Confirm" /></p></>}
                successCallback={onConfirm}
                context="danger"
                closeModal={() => this.closeModal()} />
        });
    }

    render() {
        const { audits, patient } = this.props;

        var patientAuditsNode = null;

        if (!patient || !audits) return null;

        const { user } = this.props;
        const { role } = user;

        if (role === undefined) return null;

        let auditsPatient = this.getAuditsPatient(audits, patient);
        let fileNameAudit = "audit_" + this.props.patient.first_name + "_" + this.props.patient.name + "_";

        if (auditsPatient.length > 0) {

            patientAuditsNode = auditsPatient.map((audit) => {

                var hasCmt = 0;
                if (audit.private_comments && audit.private_comments.length) {
                    audit.private_comments.forEach((cmt) => {
                        if (cmt.comment) { hasCmt = 1; }
                    });
                }

                let failureCallback = (error) => { this.setState({ modal: <ErrorModal isOpen={true} title={<FormattedMessage id="Error" />} content={<FormattedMessage id="Documents.NotFound.Content" />} closeModal={() => this.closeModal()} /> }) };
                //fetch(APIUrl.getAuditsPDF + audit.patient_id + "/cerfa_" + audit._id + "?token=" + APIUrl.jwtToken).then(response =>{res = response.status})

                return (
                    <tr key={"audit-" + audit._id} className={"order-tr order-tr"}>
                        <td className="d-md-table-cell align-middle">{(audit) && DateUtil.toDate(audit.createdAt)}</td>
                        <td className="d-md-table-cell text-center">
                            <MenuButton
                                icon="download"
                                onClick={() => FileUtil.dowloadFileHack(APIUrl.getAuditsPDF + audit.patient_id + "/" + audit._id, fileNameAudit + DateUtil.toyyyyMMdd(audit.createdAt), 'pdf', (error) => failureCallback(error))}
                                hover={<FormattedMessage id="Display.PDF" />}
                            />
                        </td>
                        <td className="d-md-table-cell text-center">
                            <MenuButton
                                icon="download"
                                onClick={() => FileUtil.dowloadFileHack(APIUrl.getAuditsPDF + audit.patient_id + "/cerfa-pdf-" + audit._id + "?token=" + APIUrl.jwtToken, "cerfa-pdf-" + audit._id, 'pdf', (error) => failureCallback(error))}
                                hover={<FormattedMessage id="Display.PDF" />}
                            />
                        </td>

                        {role !== Roles.CLIENT && <>
                            <td className="d-md-table-cell text-center">
                                <MenuButton
                                    icon="paper-plane"
                                    onClick={(e) => FileUtil.checkRemoteFileExists(APIUrl.getCheckAuditsPDF + audit.patient_id + "/" + audit._id, (error) => failureCallback(error), () => this.emailModal(audit))}
                                    hover={<FormattedMessage id="Mail.Resend" />}
                                />
                            </td>

                            {audit._id && !audit.products && hasCmt ?
                                <td className="d-md-table-cell text-center">
                                    <MenuButton
                                        icon="comment"
                                        variant="warning"
                                        onClick={(e) => this.commentModal(audit)}
                                        hover={<FormattedMessage id="Available.Comment" />}
                                    />
                                </td>
                                :
                                <td className="d-md-table-cell text-center">
                                    <MenuButton
                                        icon="comment-slash"
                                        variant="outline-info"
                                        onClick={() => { }}
                                        disabled={true}
                                    />
                                </td>
                            }

                            <td className="d-md-table-cell tdaction text-center">
                                <MenuButton
                                    icon="trash"
                                    onClick={(e) => this._deleteAudit(audit._id)}
                                    hover={<FormattedMessage id="Delete" />}
                                />
                            </td></>}
                    </tr>
                );
            });
        }


        return (
            <React.Fragment>

                {(role !== Roles.CLIENT && patient && auditsPatient && auditsPatient.length <= 0) &&

                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id="Empty.Audit.Done" />
                    </div>
                }

                {(role === Roles.CLIENT && patient && auditsPatient && auditsPatient.length <= 0) &&

                    <div className="alert alert-secondary mt-3" role="alert">
                        {/* <FormattedMessage id="Client.Empty.ElementsPdfs" /> */}
                        <FormattedMessage id="Empty.Audit.Done" />
                    </div>
                }

                {

                    (patient && auditsPatient && auditsPatient.length > 0) &&
                    <table className="table tablee4mad mt-3">
                        <thead>
                            <tr className="tablerowhead">
                                <th className="d-md-table-cell"><FormattedMessage id="Creation.Date" /></th>
                                <th className="d-none d-md-table-cell text-center"><FormattedMessage id="Elements.PDF.Title" /></th>
                                <th className="d-none d-md-table-cell text-center"><FormattedMessage id="Elements.PDF.Cerfa" /></th>
                                {role !== Roles.CLIENT && <>
                                    <th className="d-none d-md-table-cell text-center"><FormattedMessage id="Email" /></th>
                                    <th className="d-md-table-cell text-center"><FormattedMessage id="Internal.Comment" /></th>
                                    <th className="d-none d-md-table-cell text-center col-1"><FormattedMessage id="Actions" /></th></>}
                            </tr>
                        </thead>
                        <tbody>

                            {patientAuditsNode}

                        </tbody>
                    </table>
                }

                {this.state.modal}

            </React.Fragment >
        );
    }

}

class ModalBlock extends React.Component {
    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.props.close()}
                backdrop={"static"}
                size={"xl"}
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="close left"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.props.close()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <EmailModal
                        patient={this.props.patient}
                        prescribers={this.props.prescribers}
                        documents={this.props.documents}
                        helpers={this.props.helpers}
                        audit={this.props.audit}
                        origin={"list"}
                        sendEmails={(emails, documents, audit) =>
                            this.props.sendEmails(emails, documents, audit)
                        }
                        close={() => this.props.close()}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
        user: state.user,
        clients: state.clients,
        helpers: Util.filterClients(state.clients, 'helper'),
        prescribers: Util.filterClients(state.clients, 'prescriber'),
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteAudit: (data, successCallback) => dispatch(deleteAudit(data, successCallback)),
        onGetAuditsAdmin: (data, successCallback) => dispatch(getAuditsAdmin(data, successCallback)),
        onSendAudit: (data, successCallback) => dispatch(sendAudit(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientAudit));
