import CustomLabel from "../sub/CustomLabel";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Util from "../../util/Util";
import MercurialStatus from "../../enums/MercurialStatus";
import { checkIfEmailIsValid, checkIfUsernameExists } from "../../actions/user/user";
import { updPatientAdmin } from "../../actions/patients/patients";
import { addClient } from "../../actions/clients/clients";

function PatientAccountModal({ patient, closeModal, company, intl, mercurials, onCheckIfUsernameExists, onCheckIfEmailIsValid, onUpdPatientAdmin, onAddClient }) {
    const now = new Date();

    const [email, setEmail] = useState(patient.email || "");
    const [emailError, setEmailError] = useState("");

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");

    const [mercurial_Id, setMercurial_Id] = useState("");
    const [notifyByEmail, setNotifyByEmail] = useState(false);

    const onChange = (field, value) => {
        if (field === "username") {
            setUsernameError("");
            setUsername(value)

            if (Util.emptyString(value)) return setUsernameError(<FormattedMessage id="Empty.Username" />);

            const checkUsername = Util.isValidUserName(value, 3, 20);

            if (checkUsername instanceof Object === true) {
                return setUsernameError(<FormattedMessage id="Invalid.Username" values={{ minLength: checkUsername.minLength, maxLength: checkUsername.maxLength }} />);
            } else {
                setUsernameError("");
            }

            onCheckIfUsernameExists(
                value,
                () => setUsernameError(<FormattedMessage id="Username.Already.Exists" values={{ username: value }} />),
                () => setUsernameError("")
            );

        } else if (field === "email") {
            if (Util.emptyString(value)) setEmailError("");
            setEmail(value);
            if (!Util.emptyString(value) && !Util.isEmail(value)) {
                setEmailError(<FormattedMessage id="Invalid.Email.Error" />);
            } else if (!Util.emptyString(value) && Util.isEmail(value)) {
                onCheckIfEmailIsValid(
                    value,
                    (response) => {
                        setEmailError("");
                    },
                    (response) => {
                        setEmailError(<span><FormattedMessage id="Invalid.Email.Error" /> ({response.data.reason})</span>);
                    }
                );
            }
        }
    }

    const onSubmit = () => {
        const newClient = {
            first_name: patient.first_name,
            name: patient.name,
            address: patient.address,
            addressAdditionnal: patient.addressAdditionnal,
            postal_code: patient.postal_code,
            city: patient.city,
            phone: patient.phone,
            mobile: patient.mobile,
            email: email,
            username: username,
            client_type: "patient",
            account_type: "0",
            notifyByEmail: notifyByEmail,
            patient_id: patient._id,
        };

        if (mercurial_Id !== "") newClient.mercurial_Id = mercurial_Id;

        const successCallback = (client) => {
            const updatedPatient = {
                _id: patient._id,
                user_id: client._id,
                email: email,
            };

            onUpdPatientAdmin(updatedPatient, closeModal());
        }

        if (patient.user_id.length === 0) return onAddClient(newClient, successCallback);
    };

    return (
        <Modal
            show={true}
            onHide={() => closeModal()}
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id="Patient.Account" /></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group as={Row}>
                    <CustomLabel
                        label={intl.formatMessage({ id: "Email" })}
                        htmlFor="email"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required />
                    <Col>
                        <input className="form-control" type="text" id="email" autoComplete="off"
                            value={email} onChange={(e) => onChange("email", e.target.value)} />
                        <div className="text-danger"><small>{emailError}</small></div>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <CustomLabel
                        label={intl.formatMessage({ id: "Username.Connexion" })}
                        htmlFor="username"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required />
                    <Col>
                        <input className="form-control col-12 col-md-7 d-inline" type="text" id="username" autoComplete="off"
                            value={username} onChange={(e) => onChange("username", e.target.value.toLowerCase())} />
                        <span className="col-12 col-md-3">-{company.url}</span>
                        <div className="text-danger"><small>{usernameError}</small></div>
                    </Col>
                </Form.Group>

                {!Util.emptyString(username) && !usernameError && <Form.Group as={Row}>
                    <CustomLabel
                        label={intl.formatMessage({ id: "Mercurial.Linked" })}
                        htmlFor="mercurial_Id"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <Col>
                        <select className="form-control w-100"
                            value={mercurial_Id} onChange={(e) => setMercurial_Id(e.target.value)}>
                            <option value="">{intl.formatMessage({ id: "None.Feminine" })}</option>
                            {
                                mercurials.map(t => {
                                    const hasExpired = (new Date(t.start_date) > now || new Date(t.end_date) < now);
                                    if (hasExpired || t.status === MercurialStatus.INACTIVE) return null;

                                    return (
                                        <option key={t._id} value={t._id} disabled={hasExpired}>{t.name}</option>
                                    )
                                })
                            }
                        </select>
                    </Col>
                </Form.Group>}

                <Form.Group as={Row}>
                    <CustomLabel
                        label={intl.formatMessage({ id: "Send.Mail.Notify.Created.Account" })}
                        htmlFor="notifyByEmail"
                        labelClassName="col-12 col-sm-5 col-form-label"
                    />
                    <Col>
                        <input type={"checkbox"}
                            id="notifyByEmail"
                            onChange={(e) => setNotifyByEmail(e.target.checked)}
                            checked={notifyByEmail}
                        />
                    </Col>
                </Form.Group>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}><FormattedMessage id="Cancel" /></Button>
                <Button variant="info" onClick={() => onSubmit()}><FormattedMessage id="Update" /></Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        company: state.company,
        mercurials: state.mercurials,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) => dispatch(checkIfUsernameExists(username, existsCallback, noExistsCallback)),
        onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) => dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
        onUpdPatientAdmin: (patient, successCallback) => dispatch(updPatientAdmin(patient, successCallback)),
        onAddClient: (client, successCallback) => dispatch(addClient(client, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientAccountModal));