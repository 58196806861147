import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from "prop-types";

import Roles from '../../enums/Roles';

import Account from '../account/Account';
import Budget from '../budget/Budget';
import Cart from '../cart/Cart';
import Estimates from '../estimates/Estimates';
import Families from '../families/Families';
import FamiliesAdmin from '../families/FamiliesAdmin';
import Mercurials from '../mercurials/Mercurials';
import NotFound from '../NotFound';
import OrdersAdmin from '../orders/admin/OrdersAdmin';
import Orders from '../orders/Orders';
import Patients from '../patients/Patients';
import Prescribers from '../prescribers/Prescribers';
import Products from '../products/Products';
import MenuSettingsAdmin from '../settings/admin/MenuSettingsAdmin';
import SplitHome from './SplitHome/SplitHome';
import Interventions from '../intervention/Interventions';
import Changelog from "../changelog/changelog";
// [E4MAD-489] TO DO: Uncommente when E4MAD-534 & E4MAD-227 are commit in dev
import DocumentsDb from '../documents/DocumentsDb';

const propTypes = {
    userRole: PropTypes.number.isRequired
};

const defaultProps = {
    userRole: Roles.CLIENT
};

class SwitchComponent extends React.Component {
    render() {
        const {userRole} = this.props;

        let isAdminOrCollaborator = userRole === Roles.ADMIN || userRole === Roles.SALES_REP;
        let isAdmin = userRole === Roles.ADMIN;
        let isClient = userRole === Roles.CLIENT;

        return (
            <Switch>
                {isClient && <Route path="/home/products/mercurial/:mercurialId/:famille/:product" component={Products} />}
                {isClient && <Route path="/home/products/mercurial/:mercurialId/:famille" component={Products} />}
                {isAdminOrCollaborator && <Route path="/home/products/mercurial/:mercurialId/:clientId/:famille/:product" component={Products}/>}
                {isAdminOrCollaborator && <Route path="/home/products/mercurial/:mercurialId/:clientId/:famille" component={Products}/>}
                <Route path="/home/mercuriales" component={isAdminOrCollaborator ? Mercurials : NotFound}/>
                <Route path="/home/families/:mercurialId/:clientId" component={isAdminOrCollaborator ? FamiliesAdmin : NotFound}/>
                <Route path="/home/families" component={isClient ? Families : NotFound} />
                <Route path="/home/orders" component={isAdminOrCollaborator ? OrdersAdmin : Orders} />
                <Route path="/home/clients" component={isAdminOrCollaborator ? Prescribers : NotFound}/>
                <Route path="/home/patients" component={Patients} />
                {/* [E4MAD-489] TO DO: Uncommente when E4MAD-534 & E4MAD-227 are commit in dev */}
                {isAdminOrCollaborator && <Route path="/home/documents" component={DocumentsDb} />}
                <Route path="/home/estimates" component={Estimates} />
                <Route path="/home/budget" component={Budget} />
                <Route path="/home/settings" component={isAdmin ? MenuSettingsAdmin : NotFound}/>
                <Route path="/home/account" component={Account} />
                <Route path="/home/interventions" component={Interventions} />
                <Route path="/home/cart" component={isAdminOrCollaborator ? NotFound : Cart} />
                <Route path="/home/changelog" component={Changelog} />
                {/* /home need to be last in route to prevent routing bugs */}
                <Route path="/home" component={SplitHome} />
            </Switch>
        );
    }
}

SwitchComponent.propTypes = propTypes;
SwitchComponent.defaultProps = defaultProps;

export default SwitchComponent;
