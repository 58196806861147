import {GET_MAINTENANCES} from "../../actions/maintenances/maintenances";

export default function maintenances(state=[], action) {

  switch (action.type) {
    case GET_MAINTENANCES:
      return action.maintenances;
    default:
      return state;
  }

};