import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import DocumentModal from "./DocumentModal";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import axios from 'axios';
import FileExplorer from './FileExplorer';
import APIUrl from "../../APIUrl";
import DocumentFolderModal from "./DocumentFolderModal";
import { addDocumentFile, createDocument, deleteDocument, updateDocument } from "../../actions/documents/documents";
import { Alert } from "react-bootstrap";
import { deleteDocumentsCategories } from "../../actions/documents/categories";
import Roles from "../../enums/Roles";

class DocumentsDb extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            documentsFolders: null,
        };
    }

    openFolderModal(parent, folder = null) {
        this.setState({
            modal:
                <DocumentFolderModal
                    parentId={parent._id}
                    parentName={parent.name}
                    folder={folder}
                    syncData={() => this.getFolders()}
                    close={() => this.closeModal()}
                />
        });
    }

    openFileModal(parent, document = null) {
        this.setState({
            modal:
                <DocumentModal
                    parentId={parent._id}
                    parent={parent}
                    document={document}
                    addDocument={(data, successCallback) => this.props.onAddDocumentData(data, successCallback)}
                    addDocumentFile={(documentId, file, successCallback) => this.props.onAddDocumentFile(documentId, file, successCallback)}
                    updateDocument={(data, successCallback) => this.props.onUpdateDocumentData(data, successCallback)}
                    syncData={() => this.getFolders()}
                    close={() => this.closeModal()}
                />
        });
    }

    deleteFile(document) {
        if (!document) return;

        const successCallback = () => {
            this.closeModal();
            this.getFolders();
        }

        this.setState({
            modal: <ConfirmationModal
                context="danger"
                title={<FormattedMessage id="Confirm" />}
                content={<><Alert variant="danger"><FormattedMessage id="Warning.Document.Removal" /></Alert><FormattedMessage id="Confirm.Document.Removal" /></>}
                successCallback={() => this.props.onDeleteDocument(document._id, successCallback)}
                closeModal={() => this.closeModal()} />
        });
    }

    deleteFolder(folder) {
        if (!folder) return;

        const successCallback = () => {
            this.closeModal();
            this.getFolders();
        }

        this.setState({
            modal: <ConfirmationModal
                context="danger"
                title={<FormattedMessage id="Confirm" />}
                content={<>
                    <Alert variant="danger"><FormattedMessage id="Warning.Folder.Removal" values={{ folder_name: <strong>"{folder.name}"</strong> }} /></Alert>
                    <FormattedMessage id="Confirm.Folder.Removal" />
                </>}
                successCallback={() => this.props.onDeleteDocumentCategory(folder._id, successCallback)}
                closeModal={() => this.closeModal()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    getFolders() {
        axios.get(APIUrl.getDocumentsDbCategoriesTree)
            .then((response) => {
                this.setState({
                    documentsFolders: response.data
                });
            });
    }

    componentWillMount() {
        this.getFolders();
    }

    render() {
        if (!this.state.documentsFolders) return null;
        return (
            <div className="file-explorer">
                <FileExplorer
                    data={this.state.documentsFolders}
                    appScope={"e4mad"}
                    openFolderModal={(parent, folder) => this.openFolderModal(parent, folder)}
                    openFileModal={(parent, document) => this.openFileModal(parent, document)}
                    deleteFolder={(folder) => this.deleteFolder(folder)}
                    deleteFile={(file) => this.deleteFile(file)}
                    isAdmin={this.props.user.role === Roles.ADMIN || this.props.user.role === Roles.SALES_REP}
                    isPrescriber={this.props.user.role === Roles.CLIENT}
                    isUpload={this.props.isUpload}
                    setParentState={(file) => this.props.setParentState(file)}
                    isPatient={this.props.isPatient}
                />
                {this.state.modal}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAddDocumentData: (data, successCallback) => dispatch(createDocument(data, successCallback)),
        onAddDocumentFile: (dataId, file, successCallback) => dispatch(addDocumentFile(dataId, file, successCallback)),
        onUpdateDocumentData: (data, successCallback) => dispatch(updateDocument(data, successCallback)),
        onDeleteDocument: (dataId, successCallback) => dispatch(deleteDocument(dataId, successCallback)),
        onDeleteDocumentCategory: (dataId, successCallback) => dispatch(deleteDocumentsCategories(dataId, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentsDb));
