import PriceUtil from "./PriceUtil";
import Maths from "./Maths";

export default class ProductsTotalUtil {

    constructor(products, productsCatalog, oldProductsCatalog=[], getProductsFn, settings) {
        if(!settings) settings = {urgent: false, min_order_amount: 0, shipping_costs: [{min: 0, max: +Infinity, cost: 0}]};

        // Constants
        this.urgent_shipping_cost = settings.urgent;
        this.min_cost_to_order = settings.min_order_amount;
        this.settings = settings;

        // Function to fetch products
        this.getProductsFn = getProductsFn;

        // Helper class for prices calculations
        var priceUtil = new PriceUtil();

        this._urgent = false;

        // If the mercurial is outdated, we might have to fetch the old products
        this.missingProducts = [];

        this.containsOldProducts = false;
        this.containsMissingProducts = false;

        products.map((p) => {
            if(p.urgent) this._urgent = true;
            
            // Get corresponding product
            var product = this.getProduct(p.id_product, productsCatalog);

            // Old product? Let's try to get it from the BE
            if(!product){
                product = this.getOldProduct(p.id_product, oldProductsCatalog);
                this.containsOldProducts = true;
            }

            // No product at all? This order might have been passed on a deleted mercurial
            if(!product){
                this.missingProducts.push(p);
                this.containsMissingProducts = true;
                return null;
            }

            // Add it to PriceUtil
            priceUtil.add(product, p.quantity);

            return null;
        });

        // Fetch missing products
        this.getOldProducts(this.missingProducts);

        this._totalHt = priceUtil.totalHt();
        this._totalTtc = priceUtil.totalTtc();
        this._totalTva = priceUtil.totalTva();
    }

    // ==================================================================
    // ========================= GET PRODUCTS ===========================
    // ==================================================================

    getProduct(productId, productsCatalog) {
        for (let product of productsCatalog) {
            if (product._id === productId) return product;
        }

        return null;
    }

    getOldProduct(productId, oldProductsCatalog){
        if (oldProductsCatalog) {
            for(let oldProduct of oldProductsCatalog){
                if (oldProduct && oldProduct._id === productId) return oldProduct;
            }
        }
        return null;
    }

    getOldProducts(products){
        if(products.length === 0) return;

        let missingProductsIds = [];
        for(let product of products){
            var id = (product._id || product.id_product);
            missingProductsIds.push(id);
        }

        // Ask the BE at once
        if(missingProductsIds.length > 0){
            if(this.getProductsFn){
                this.getProductsFn({ids: missingProductsIds});
            } 
        } 
    }

    // ==================================================================
    // =========================== FUNCTIONS ============================
    // ==================================================================

    aboveMinToOrder() {
        return (this._totalHt >= this.min_cost_to_order);
    }

    freeShipping() {
        // Let's tart with the calculated total
        if (this.settings.shipping_costs.length > 0) {
            var lastSetting = this.settings.shipping_costs[this.settings.shipping_costs.length - 1];

            if (!lastSetting.max && lastSetting.cost === 0) {
                return (this._totalHt < lastSetting.min);
            } else if (lastSetting.max) {
                return (this._totalHt < lastSetting.max);
            }
        }

        return false;
    }

    getTotalHT(urgent = false, shipping = false) {
        // Let's start with the calculated total
        var totalHt = this._totalHt;

        // If the order is under the free shipping amount, increase price with shipping fees
        if (shipping && totalHt >= this.settings.min_order_amount) {
            totalHt = Maths.round(totalHt + this.shippingCost);
        }

        // If the order is urgent, increase price
        if (urgent && this._urgent) {
            totalHt = Maths.round(totalHt + this.urgent_shipping_cost);
        }

        return totalHt;
    }

    getTotal(urgent = false, shipping = false) {
        // Let's start with the calculated total
        var totalTtc = this._totalTtc;
        var totalHt = this._totalHt;

        // If the order is urgent, increase price
        if (urgent && this._urgent) {
            totalTtc = Maths.round(totalTtc + (this.urgent_shipping_cost*1.2));
        }

        // If the order is under the free shipping amount, increase price with shipping fees
        if (shipping && totalHt >= this.settings.min_order_amount) {
            totalTtc = Maths.round(totalTtc + (this.shippingCost*1.2));
        }

        return totalTtc;
    }

    getTotalTva(urgent = false, shipping = false) {
        // Let's start with the calculated total
        var totalTva = this._totalTva;
        var totalHt = this._totalHt;

        // If the order is urgent, increase price
        if (urgent && this._urgent) {
            totalTva = Maths.round(totalTva + (this.urgent_shipping_cost)*20/100);
        }

        // If the order is under the free shipping amount, increase price with shipping fees
        if (shipping && totalHt >= this.settings.min_order_amount) {
            totalTva = Maths.round(totalTva + (this.shippingCost)*20/100);
        }

        return totalTva;
    }

    // ==================================================================
    // ======================= GETTERS & SETTERS ========================
    // ==================================================================

    get totalHt() {
        return this.getTotalHT(true, true);
    }

    set totalHt(e) {
        // Read-only
    }

    get totalHtCart() {
        return this._totalHt;
    }

    set totalHtCart(e) {
        // Read-only
    }

    get totalTtc() {
        return this.getTotal(true, true);
    }

    set totalTtc(e) {
        // Read-only
    }

    get totalTva() {
        return this.getTotalTva(true, true);
    }

    set totalTva(e) {
        // Read-only
    }

    get urgent() {
        return this._urgent;
    }

    set urgent(e) {
        // Read-only
    }

    get shippingCost() {
        let total = this._totalHt;

        var price = 0;
        if (this.settings.shipping_costs.length > 0) {
            for (var shipping_cost of this.settings.shipping_costs) {
                if (!shipping_cost.max && total >= shipping_cost.min) {
                    price = shipping_cost.cost;
                } else if (total >= shipping_cost.min && total <= shipping_cost.max) {
                    price = shipping_cost.cost;
                }
            }

            return price;
        }

        return price;
    }
}
