export default class ArrayUtil {

    // Get the difference between two arrays
    // https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript

    /**
     * intersection
     * For [1,2,3] [2,3] it will yield [2,3]. On the other hand, for [1,2,3] [2,3,5] will return the same thing.
     * @param {*} arr1 
     * @param {*} arr2 
     */
    static intersection(arr1, arr2) {
        return arr1.filter(x => arr2.includes(x));
    }

    /**
     * difference
     * For [1,2,3] [2,3] it will yield [1]. On the other hand, for [1,2,3] [2,3,5] will return the same thing.
     * @param {*} arr1 
     * @param {*} arr2 
     */
    static difference(arr1, arr2) {
        return arr1.filter(x => !arr2.includes(x));
    }

    static sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

}