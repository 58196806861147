import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { addAuditRoom, updateAuditRoom, getAuditRoom, addAuditRoomFile } from "../../../../actions/settings/auditRooms/admin/auditRooms";
import FileDropZone from '../../../sub/FileDropZone';
import Util from '../../../../util/Util';
import APIUrl from '../../../../APIUrl';
import TextareaCounter from 'react-textarea-counter';
import CustomLabel from "../../../sub/CustomLabel";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class AuditRoomsAddModalAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            default_text: "",
            nameError: null,
            hidden: false,
            disabled: false,
            modal: null,
        };

        if (this.props.auditRoomId) {
            const room = this.props.auditRooms.find(r => r._id === this.props.auditRoomId);

            if (room) {
                this.state = {
                    name: room.name,
                    default_text: room.default_text,
                    hidden: room.hidden || false,

                    nameError: null,
                    disabled: false,
                    modal: null,
                };
            }
        }
    }

    close() {
        this.props.closeModal();
    }

    onChange(key, value) {

        if (key === "hidden") this.setState({ hidden: !this.state.hidden });
        else this.setState({ [key]: value });

        if (key !== 'name') return;

        if (Util.emptyString(value)) return;

        let successCallback = (auditRoom) => {
            if (auditRoom) this.setState({ nameError: <FormattedMessage id="Room.Exist" /> });
            else this.setState({ nameError: null })
        };

        if (this.props.auditRoomId) {
            const room = this.props.auditRooms.find(r => r._id === this.props.auditRoomId);
            if (room && value === room.name) return;

            this.props.onGetAuditRoom(value, successCallback);
        } else {
            this.props.onGetAuditRoom(value, successCallback);
        }
    }

    onDropFile(file, clearCallback) {
        if (!file || file.length === 0) return;

        // Update mode. Immediately send file to BE
        if (this.props.auditRoomId) {
            this.setState({ disabledFileUpload: true });

            let formData = new FormData();
            formData.append(file.name, file);

            let successCallback = () => {
                this.setState({ disabledFileUpload: false });
                clearCallback();
            };

            // Send to BE
            this.props.onAddAuditRoomFile(this.props.auditRoomId, formData, successCallback);
        }
        // Create mode. Store the file and wait for the onComplete event
        else {
            this.setState({ file: file });
        }
    }

    onSubmit() {
        let sendfile = (auditRoom) => {
            if (!this.state.file || this.state.file.length === 0) return this.props.closeModal();

            let formData = new FormData();
            formData.append(this.state.file.name, this.state.file);

            this.props.onAddAuditRoomFile(auditRoom._id, formData, () => this.props.closeModal());
        };

        let imageExist = (!!this.state.file);

        let data = {
            name: this.state.name.toUpperCase().trim(),
            default_text: this.state.default_text,
            image: imageExist,
            hidden: this.state.hidden,
            sortIndex: this.props.auditRooms.length + 1
        };

        this.setState({ disabled: true, disabledFileUpload: true });

        this.props.onAddAuditRoom(data, sendfile);
    }

    stopEvent(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    onUpdate(updatedField, updatedValue) {
        if (!this.props.auditRoomId || this.checkErrors()) return;

        if (updatedField === "name" && Util.emptyString(updatedValue)) return;

        let data = {
            auditRoomId: this.props.auditRoomId,
            updatedField: updatedField,
            updatedValue: updatedValue
        }

        this.props.onUpdateAuditRoom(data);
    }

    checkErrors() {
        return (this.state.nameError ||
            this.state.disabled ||
            Util.emptyString(this.state.name))
    }

    getAuditRoom(auditRoomId) {
        for (let auditRoom of this.props.auditRooms) {
            if (auditRoom._id === auditRoomId) return auditRoom;
        }
    }

    render() {
        let img, auditRoom;
        const { auditRooms, auditRoomId } = this.props;

        if (auditRoomId) {
            auditRoom = auditRooms.find(r => r._id === auditRoomId);
            if (!auditRoom) return null;

            let imgSrc = APIUrl.getAuditRoomImg + auditRoom._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken;
            //console.log('imgSrc',imgSrc,APIUrl.getAuditRoomImg);
            img = <img src={imgSrc} className="col-form-label m-3" alt={"audit_Room_img" + Math.random()} height="150" width="150" />;
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 col-lg-8">
                        {auditRoom &&
                            <div className="d-none d-lg-block w-150 rounded light-card text-center">
                                <div className="m-0 p-2">
                                    {img}
                                    {auditRoom.image &&
                                        <MenuButton
                                            icon="trash"
                                            variant="outline-dark"
                                            onClick={(e) => this.onUpdate("image", false)}
                                            hover={<FormattedMessage id="Restore.Default.Image" />}
                                        />
                                    }
                                </div>
                            </div>
                        }

                        <div className="form-group row">
                            <label htmlFor="logo" className="col-12 col-md-5 col-form-label"><FormattedMessage id="Logo" /></label>
                            <div className="col-12 col-md-7">
                                <FileDropZone
                                    disabled={this.state.disabledDropZone}
                                    onDropFile={(file, clearCallback) => this.onDropFile(file, clearCallback)}
                                    acceptedExtensions={["jpg", "png", "jpeg"]}
                                    multiple={false}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="name" className="col-12 col-md-4 col-form-label"><FormattedMessage id="Name" /></label>
                            <div id="name" className="col-12 col-md-8">
                                <input className="form-control" type="text" id="name" autoComplete="off"
                                    value={this.state.name}
                                    onChange={(e) => this.onChange("name", e.target.value.toUpperCase())}
                                    onBlur={(e) => this.onUpdate("name", this.state.name.toUpperCase())} />
                                <small className="text-danger">{this.state.nameError}</small>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="defaultText" className="col-12 col-md-4 col-form-label"><FormattedMessage id="Default.Text" /></label>
                            <div id="defaultText" className="col-12 col-md-8">
                                <TextareaCounter countLimit={1000} className="textareaCounter" type="text" initialValue={this.state.default_text} id="defaultText" rows="10"
                                    placeholder={this.default_text}
                                    onChange={(e) => this.onChange("default_text", e.target.value)}
                                    onBlur={(e) => this.onUpdate("default_text", this.state.default_text)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Audit.Room.Hidden" })}
                                htmlFor="hidden"
                                labelClassName="col-12 col-md-4 col-form-label" />
                            <div className="col-12 col-md-8">
                                <div className="custom-control custom-switch mx-auto switch-success text-left pt-2">
                                    <input
                                        type="checkbox"
                                        id="hidden-switch"
                                        className="custom-control-input switch-bg-blue"
                                        checked={this.state.hidden}
                                        value={this.state.hidden}
                                        onChange={(e) => this.onChange("hidden")}
                                        onBlur={(e) => this.onUpdate("hidden", this.state.hidden)} />
                                    <label className="custom-control-label" htmlFor="hidden-switch"></label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {(!auditRoomId) &&
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.close()}><FormattedMessage id="Cancel" /></button>
                        <button type="button" className="btn btn-info" onClick={() => this.onSubmit()}
                            disabled={this.checkErrors()}><FormattedMessage id="Add" /></button>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auditRooms: state.auditRooms,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddAuditRoom: (data, successCallback) => dispatch(addAuditRoom(data, successCallback)),
        onGetAuditRoom: (data, successCallback) => dispatch(getAuditRoom(data, successCallback)),
        onUpdateAuditRoom: (data) => dispatch(updateAuditRoom(data)),
        onAddAuditRoomFile: (auditRoomId, data, successCallback) => dispatch(addAuditRoomFile(auditRoomId, data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuditRoomsAddModalAdmin));