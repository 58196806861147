import axios from 'axios';

export default class FileUtil{

    static extension(fileName){
        var fragments = fileName.split(".");
        var extension = fragments[fragments.length-1];
        // Convert to lowercase for eval
        return extension.toLowerCase();
    }

    static extensionIsOk(fileName, acceptedExtensions){
        var extension = FileUtil.extension(fileName);

        for(let ext of acceptedExtensions){
            if(extension === ext) return true;
        }

        return false;
    }

    static withoutExtension(fileName){
        let extension = FileUtil.extension(fileName);
        return fileName.replace("." + extension, "");
    }

    // This will normalize the name to fit file names requirements (no accents, lowercase, special characters etc.)
    static toFileName(name){
        return name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9]/gi, '_').toLowerCase();
    }

    static dowloadFileHack(url, fileName, type, failureCallback, data) {

        let method = (typeof (data) !== 'undefined') ? "post" : "get";

        let contentType;
        let responseType;

        switch (type) {
            case 'pdf':
                contentType = "application/pdf";
                responseType = "blob";
                break;
            case 'xlsx':
                contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                responseType = "blob";
                break;
            default:
                contentType = "text/plain";
                responseType = "arrayBuffer";
                break;
        }

        axios({
            url: url,
            method: method,
            responseType: responseType
        }).then((result) => {
            let fileURL = window.URL.createObjectURL(new Blob([result.data], { type: contentType }));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName + "." + type); //or any other extension
            document.body.appendChild(fileLink);

            fileLink.click();

            document.body.removeChild(fileLink);
        }).catch(function (error) {
            return failureCallback(error);
            //throw error;
        });
    };

    static bytesToSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return 'n/a'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
        if (i === 0) return `${bytes} ${sizes[i]})`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }

    static checkRemoteFileExists(url, failureCallback, successCallback) {

        let method = (typeof (data) !== 'undefined') ? "post" : "get";

        axios({
            url: url,
            method: method,
        }).then((result) => {
            return successCallback();
        }).catch(function (error) {
            return failureCallback(error);
            //throw error;
        });
    };

}