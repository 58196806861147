import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DateUtil from '../../util/DateUtil';
import Util from '../../util/Util';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import PriceUtil from '../../util/PriceUtil';
import CustomLabel from "../sub/CustomLabel";

class ProductsBudget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            graphType: 'pie',
            graphData: 'quantity',
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: Date.now()
        };
    }

    onChange(field, value) {
        this.setState({ [field]: value });
    }

    currentMonth() {
        var date = new Date();
        this.setState({
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: Date.now()
        });
    }

    currentYear() {
        this.setState({
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: Date.now()
        });
    }

    sortMap(map) {
        return Object
            .keys(map)
            .sort((a, b) => map[b] - map[a])
            .reduce((_sortedObj, key) => ({
                ..._sortedObj,
                [key]: map[key]
            }), {});
    }

    buildChartData(priceUtilPerFamily, isLine = false) {
        let priceUtilData = {};
        for (let famille of Object.keys(priceUtilPerFamily)) {
            if (this.state.graphData === 'quantity') priceUtilData[famille] = priceUtilPerFamily[famille].quantity();
            else if (this.state.graphData === 'totalHT') priceUtilData[famille] = priceUtilPerFamily[famille].totalHt();
            else if (this.state.graphData === 'totalTTC') priceUtilData[famille] = priceUtilPerFamily[famille].totalTtc();
        }

        priceUtilData = this.sortMap(priceUtilData);

        let data = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                pointBackgroundColor: [],
                pointBorderColor: [],
                borderColor: [],
                borderWidth: []
            }]
        };

        for (let famille of Object.keys(priceUtilData)) {
            // Label 
            data.labels.push(famille);

            // Data
            data.datasets[0].data.push(priceUtilData[famille]);

            // BG color
            let color = Util.stringToColor(famille);
            if (!isLine) data.datasets[0].backgroundColor.push(color);
            else {
                data.datasets[0].pointBackgroundColor.push(color);
                data.datasets[0].pointBorderColor.push(color);
                data.datasets[0].backgroundColor.push('rgba(0, 0, 0, 0)');
                data.datasets[0].borderColor.push(color);
                data.datasets[0].borderWidth.push(5);
            }
        }

        return data;
    }

    render() {
        if (!this.props.orders) return null;

        // Gather up all products from all targeted orders
        var orderProducts = [];
        for (let order of this.props.orders) {
            // Careful with start & end dates
            if (new Date(order.creation_date) < new Date(this.state.startDate) || new Date(order.creation_date) > new Date(this.state.endDate)) continue;

            for (let product of order.products) orderProducts.push(product);
        }

        let priceUtilPerFamily = {};

        for (let orderProduct of orderProducts) {
            if (!priceUtilPerFamily[orderProduct.famille])
                priceUtilPerFamily[orderProduct.famille] = new PriceUtil();
            priceUtilPerFamily[orderProduct.famille].add(
                orderProduct,
                orderProduct.quantity
            );
        }

        let options = {
            legend: {
                display: (this.state.graphType === 'pie')
            }
        };

        if (this.state.graphData === 'totalHT' || this.state.graphData === 'totalTTC') {
            options.tooltips = {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: (tooltipItems, data) => {
                        let value = tooltipItems.yLabel;
                        if (!value) value = data.datasets[0].data[tooltipItems.index];

                        if (!tooltipItems.label) return " " + data.labels[tooltipItems.index] + ' : ' + value + '€';
                        return value + '€';
                    }
                }
            };
        }
        return (
            <React.Fragment>

                <div className="row pt-3">

                    <div className="col-12 col-lg-9 mb-5">
                    {(Object.keys(priceUtilPerFamily).length === 0) && <div className="alert alert-secondary" role="alert">
                            <FormattedMessage id="Empty.Stats.Orders" />
                        </div>}
                        {(this.state.graphType === 'pie') && <Doughnut key={Math.random()} options={options} data={this.buildChartData(priceUtilPerFamily)} />}
                        {(this.state.graphType === 'bar') && <Bar key={Math.random()} options={options} data={this.buildChartData(priceUtilPerFamily)} />}
                        {(this.state.graphType === 'line') && <Line key={Math.random()} options={options} data={this.buildChartData(priceUtilPerFamily, true)} />}
                    </div>

                    <div className="col-12 col-lg-3">

                        <div className="p-4 border border-light rounded bg-light">

                            <div className="form-group row p-0">
                                <input id="products-graphType-pie" className="form-control col-2 h-20 mt-30 nofocus" type="radio" name="products-budget-graphType"
                                    onChange={(e) => this.onChange("graphType", "pie")}
                                    value="number"
                                    checked={this.state.graphType === 'pie'} />
                                <CustomLabel
                                    htmlFor="products-graphType-pie"
                                    labelClassName="form-control col-10 no-border bg-light hoverable graph-icon"
                                    iconOptions="chart-pie" />
                            </div>

                            <div className="form-group row ">
                                <input id="products-graphType-bar" className="form-control col-2 h-20 mt-30 nofocus" type="radio" name="products-budget-graphType"
                                    onChange={(e) => this.onChange("graphType", "bar")}
                                    value="number"
                                    checked={this.state.graphType === 'bar'} />
                                <CustomLabel
                                    htmlFor="products-graphType-bar"
                                    labelClassName="form-control col-10 no-border bg-light hoverable graph-icon"
                                    iconOptions="chart-bar" />
                            </div>

                            <div className="form-group row ">
                                <input id="products-graphType-line" className="form-control col-2 h-20 mt-30 nofocus" type="radio" name="products-budget-graphType"
                                    onChange={(e) => this.onChange("graphType", "line")}
                                    value="number"
                                    checked={this.state.graphType === 'line'} />
                                <CustomLabel
                                    htmlFor="products-graphType-line"
                                    labelClassName="form-control col-10 no-border bg-light hoverable  graph-icon"
                                    iconOptions="chart-line" />
                            </div>

                            <hr className="mt-4" />

                            <div className="form-group row pb-0 mb-0">
                                <input id="products-graphData-quantity" className="form-control col-2 h-20 mt-10 nofocus" type="radio" name="products-budget-graphData"
                                    onChange={(e) => this.onChange("graphData", "quantity")}
                                    value="number"
                                    checked={this.state.graphData === 'quantity'} />
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Quantity" })}
                                    htmlFor="products-graphData-quantity"
                                    labelClassName="form-control col-10 no-border bg-light pb-0 mb-0 hoverable" />
                            </div>

                            <div className="form-group row pb-0 mb-0">
                                <input id="products-graphData-totalHT" className="form-control col-2 h-20 mt-10 nofocus" type="radio" name="products-budget-graphData"
                                    onChange={(e) => this.onChange("graphData", "totalHT")}
                                    value="number"
                                    checked={this.state.graphData === 'totalHT'} />
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Total.Excl.Tax" })}
                                    htmlFor="products-graphData-totalHT"
                                    labelClassName="form-control col-10 no-border bg-light pb-0 mb-0 hoverable" />
                            </div>

                            <div className="form-group row pb-0 mb-0">
                                <input id="products-graphData-totalTTC" className="form-control col-2 h-20 mt-10 nofocus" type="radio" name="products-budget-graphData"
                                    onChange={(e) => this.onChange("graphData", "totalTTC")}
                                    value="number"
                                    checked={this.state.graphData === 'totalTTC'} />
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Total.Incl.Tax" })}
                                    htmlFor="products-graphData-totalTTC"
                                    labelClassName="form-control col-10 no-border bg-light pb-0 mb-0 hoverable" />
                            </div>

                            <hr className="mt-4" />

                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Start.Date" })}
                                    required
                                    htmlFor="radio-graphData-startDate"
                                    labelClassName="form-control col-12 no-border bg-light" />
                                <input className="form-control col-12" type="date" name="startDate"
                                    onChange={(e) => this.onChange("startDate", e.target.value)}
                                    value={DateUtil.toyyyyMMdd(this.state.startDate)} />
                            </div>

                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "End.Date" })}
                                    required
                                    htmlFor="radio-graphData-endDate"
                                    labelClassName="form-control col-12 no-border bg-light" />
                                <input className="form-control col-12" type="date" name="endDate"
                                    onChange={(e) => this.onChange("endDate", e.target.value)}
                                    value={DateUtil.toyyyyMMdd(this.state.endDate)} />
                            </div>
                            <div className="form-group row">
                                <button className="btn btn-m btn-outline-secondary btn-block"
                                    onClick={(e) => this.currentMonth()}>
                                    <FormattedMessage id="Current.Month" />
                                </button>
                                <button className="btn btn-m btn-outline-secondary btn-block"
                                    onClick={(e) => this.currentYear()}>
                                    <FormattedMessage id="Current.Year" />
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </React.Fragment>
        )
    }
}


export default injectIntl(ProductsBudget);
