import React from 'react';
import Autosuggest from 'react-autosuggest';
import Util from '../../util/Util';

import { FormattedMessage, injectIntl } from 'react-intl';

class AutoSuggestInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value || "",
            suggestions: []
        };

        if(this.props.parentSuggestInputCbk) this.props.parentSuggestInputCbk((value) => this.setState({value}));
    }

    onChange(event, { newValue }) {
        // Change state value. Careful with i18n option
        this.setState({
            value: (!Util.emptyString(newValue) && this.props.i18n) ? this.props.intl.formatMessage({ id: newValue }) : newValue
        });

        if(this.props.onChange) this.props.onChange(newValue);
    };

    onSuggestionsFetchRequested({ value }) {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    };

    getSuggestionValue(suggestion) {
        return suggestion;
    }

    renderSuggestion(suggestion) {
        return (
            <div>
                {(this.props.i18n) ? <FormattedMessage id={suggestion} /> : suggestion}
            </div>
        );
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.props.suggestions.filter(s => {
            if (this.props.i18n) return this.props.intl.formatMessage({ id: s }).toLowerCase().slice(0, inputLength) === inputValue;
            else return s.toLowerCase().slice(0, inputLength) === inputValue
        }
        );
    };

    // clearValue(){
    //     if(this.props.input === "concernedProduct"){
    //         if(this.props.disabled) {
    //             this.setState({
    //                 value: ""
    //             });
    //         }
    //     }
    // }

    onSuggestionSelected(event, obj){
        this.props.onSuggestionSelected(obj.suggestion);
    }

    render() {
        const { value, suggestions } = this.state;

        var placeholder = (this.props.placeholder) ? this.props.intl.formatMessage({ id: this.props.placeholder }) : '';

        if(this.props.input === "concernedProduct"){

            const inputProps = {
                placeholder: placeholder,
                value,
                disabled: this.props.disabled,
                onBlur: () => this.props.onBlur(),
                onChange: (event, obj) => this.onChange(event, obj),
                onClick: (event, obj) => {if(this.props.onClick) this.props.onClick(event, obj)}
            }

            return (
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={(o) => this.onSuggestionsFetchRequested(o)}
                    onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
                    getSuggestionValue={(s) => this.getSuggestionValue(s)}
                    renderSuggestion={(s) => this.renderSuggestion(s)}
                    inputProps={inputProps}
                    onSuggestionSelected={(event, obj) => this.onSuggestionSelected(event, obj)}
                />
            );
        }else{
            const inputProps = {
                placeholder: placeholder,
                value,
                onChange: (event, obj) => this.onChange(event, obj),
                onClick: (event, obj) => {if(this.props.onClick) this.props.onClick(event, obj)}
            };
            return (
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={(o) => this.onSuggestionsFetchRequested(o)}
                    onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
                    getSuggestionValue={(s) => this.getSuggestionValue(s)}
                    renderSuggestion={(s) => this.renderSuggestion(s)}
                    inputProps={inputProps}
                    onSuggestionSelected={(event, obj) => this.onSuggestionSelected(event, obj)}
                />
            );
         }

    }
}

export default injectIntl(AutoSuggestInput);