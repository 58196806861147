import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextareaCounter from 'react-textarea-counter';
import FileDropZone from '../../../sub/FileDropZone';
import { updateAuditSettings, addLogo } from "../../../../actions/settings/auditSettings/auditSettings";
import APIUrl from '../../../../APIUrl';
import CustomLabel from "../../../sub/CustomLabel";

class AuditSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            subtitle: "",
            rationale: "",
            title_pdf: "",
            originaudit: "home",
            error_title_pdf: null,
            error_subtitle: null
        };


        if (this.props.auditSettings) {
            this.state = {
                title: this.props.auditSettings.title || "",
                subtitle: this.props.auditSettings.subtitle || "",
                rationale: this.props.auditSettings.rationale || "",
                title_pdf: this.props.auditSettings.title_pdf || "",
                originaudit: "home",
            };
        }
    }

    onChange(key, value) {
        this.setState({ [key]: value });
        if (key === "title_pdf") {
            if (value.length > 45) {
                this.setState({ error_title_pdf: <FormattedMessage id="Character.Limit" /> })
            } else {
                this.setState({ error_title_pdf: null });
            }
        }
        if (key === "subtitle") {
            if (value.length > 45) {
                this.setState({ error_subtitle: <FormattedMessage id="Character.Limit" /> })
            } else {
                this.setState({ error_subtitle: null });
            }
        }
    }

    update(field, value) {
        if (field === "title_pdf" && this.state.error_title_pdf !== null) return;
        if (field === "subtitle" && this.state.error_subtitle !== null) return;

        var data = {
            auditSettingsId: this.props.auditSettings._id,
            updatedField: field,
            updatedValue: value,
            originaudit: this.state.originaudit,
        };
        this.props.onUpdateAuditSettings(data);
    }

    onDropFile(file, clearCallback) {
        let formData = new FormData();
        formData.append(file.name, file);

        this.setState({ disabledDropZone: true });

        var successCallback = () => {
            clearCallback();
            this.setState({ disabledDropZone: false });
        };

        this.props.onAddLogo(this.props.auditSettings._id, formData, successCallback);
    }

    render() {
        if (this.props.auditSettings.length === 0) return null;

        var img = null;
        if (this.props.auditSettings.logo) {
            img = <div className="alert alert-dark col-12 text-center">
                <img src={APIUrl.getAuditSettingsLogo + this.props.auditSettings._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken} width="200" height="200" alt="logo"
                    onError={(e) => { e.target.onerror = null; e.target.src = '/images/placeholder_120x120.png' }} />
            </div>;
        }
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-12 col-xl-8 col-lg-12 col-sm-12">
                        {img}
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Logo" })}
                                htmlFor="logo"
                                labelClassName="col-12 col-md-5 col-form-label" />
                            <div className="col-12 col-md-7">
                                <FileDropZone
                                    disabled={this.state.disabledDropZone}
                                    onDropFile={(file, clearCallback) => this.onDropFile(file, clearCallback)}
                                    acceptedExtensions={["jpg", "png","jpeg"]}
                                    multiple={false} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Title" })}
                                htmlFor="title"
                                labelClassName="col-12 col-md-5 col-form-label" />
                            <div className="col-12 col-md-7">
                                <input type="text" className="form-control" id="title"
                                    value={this.state.title}
                                    onChange={(e) => this.onChange('title', e.target.value)}
                                    onBlur={(e) => this.update("title", this.state.title)} />
                                <input
                                    type="hidden"
                                    name="originaudit"
                                    value={this.state.originaudit}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Subtitle" })}
                                htmlFor="subtitle"
                                labelClassName="col-12 col-md-5 col-form-label" />
                            <div className="col-12 col-md-7">
                                <input type="text" className="form-control" id="subtitle"
                                    value={this.state.subtitle}
                                    onChange={(e) => this.onChange('subtitle', e.target.value)}
                                    onBlur={(e) => this.update("subtitle", this.state.subtitle)} />
                                <div className="text-danger"><small>{this.state.error_subtitle}</small></div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Rationale" })}
                                htmlFor="rationale"
                                labelClassName="col-12 col-md-5 col-form-label" />
                            <div className="col-12 col-md-7">
                                <TextareaCounter countLimit={1000} className="textareaCounter" id="rationale" name="rationale"
                                    initialValue={this.state.rationale}
                                    onChange={(e) => this.onChange('rationale', e.target.value)}
                                    onBlur={(e) => this.update("rationale", this.state.rationale)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Title.PDF" })}
                                htmlFor="title_pdf"
                                labelClassName="col-12 col-md-5 col-form-label" />
                            <div className="col-12 col-md-7">
                                <input type="text" className="form-control" id="title_pdf"
                                    value={this.state.title_pdf}
                                    onChange={(e) => this.onChange('title_pdf', e.target.value)}
                                    onBlur={(e) => this.update("title_pdf", this.state.title_pdf)} />
                                <div className="text-danger"><small>{this.state.error_title_pdf}</small></div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        auditSettings: state.auditSettings,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateAuditSettings: (data) => dispatch(updateAuditSettings(data)),
        onAddLogo: (auditSettingsId, data, successCallback) => dispatch(addLogo(auditSettingsId, data, successCallback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuditSettings));
