import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import Icon from '../sub/Icon.js';
class MercurialArchiveModal extends React.Component {

    close() {
        this.props.closeModal();
    }

    updateStatus() {
        this.props.updateStatus();
        this.close();
    }

    openModalDelete() {
        this.props.openModalDelete();
    }

    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="Warning" /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormattedMessage id="Mercurial.Remove.Confirmation" />
                    <br /><br />
                    <div className="card text-white bg-danger">
                        <div className="card-header">
                            <Icon icon="circle-info" className="mr-2 text-white" />
                            <FormattedMessage id="Archive" />
                        </div>
                        <div className="card-body">
                            <p className="card-text"><FormattedMessage id="Mercurials.Warning.Content" /></p>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="btn btn-secondary text-white" onClick={() => this.updateStatus()}><FormattedMessage id="Make.Inactive" /></Button>
                    <Button variant="btn btn-danger text-white" onClick={() => this.openModalDelete()}><FormattedMessage id="Delete" /></Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default MercurialArchiveModal;