import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Roles from '../../enums/Roles';
import MercurialStatus from "../../enums/MercurialStatus"
import DateUtil from '../../util/DateUtil';
import Icon from '../sub/Icon.js';
import { Button } from 'react-bootstrap';
import MenuButton from "../sub/bootstrap/MenuButton";
class PrescriberRow extends React.Component {

    countPatients(client, patients) {
        if (!client || !patients) return 0;

        let patientFieldName = "prescribers_ids";
        if (client.client_type === "helper") patientFieldName = "helpers_ids"

        let count = 0;
        outer: for (let p of patients) {
            for (let pId of p[patientFieldName]) {
                if (pId === client._id) {
                    count++;
                    continue outer;
                }
            }
        }

        return count;
    }

    getMercurialName(client) {

        for (let m of this.props.mercurials) {
            if (m._id === client.mercurial_Id && m.status === MercurialStatus.ACTIVE) {
                let version = "";
                if (m.version !== 1) version = "(v" + m.version + ")";
                return m.name + " " + version;
            }
        }

        return '';
    }

    getMercurialDate(client) {
        let date = new Date();
        for (let m of this.props.mercurials) {
            if (m._id === client.mercurial_Id && !this.getMercurialStatus(client)) {
                date = DateUtil.toDate(m.end_date);
            }
        }
        return date;
    }

    getMercurialStatus(client) {
        let isActive = true;
        for (let m of this.props.mercurials) {
            let isOutDated = (new Date(m.end_date) < new Date());
            if (m._id === client.mercurial_Id && isOutDated) {
                isActive = false;
            }
        }
        return isActive;
    }

    getType = (client) => {
        if (!client || !client.client_type) return "";
        if (client.client_type === 'helper') return this.props.intl.formatMessage({ id: "Client.Helper" });
        if (client.client_type === 'prescriber') return this.props.intl.formatMessage({ id: "Client.Prescriber" });
    }

    getClientLabel = (client) => {
        const typePrescriber = this.props.prescriberTypes.find(t => t._id === client.type)
        if (typePrescriber) {
            return typePrescriber.name
        }
    }

    render() {
        const { client, patients, user } = this.props;

        if (!client) return null;

        const patientsNbr = this.countPatients(client, patients);

        // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
        // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
        const { enabled: crmEnabled = false, software: crmSoftware = null } = this.props.company.crm ? this.props.company.crm : {};

        return (
            <React.Fragment>

                <tr key={client._id}>
                    <td className="align-middle"><span className="text-uppercase">{client.name && client.name.toUpperCase()}</span> <span className="text-capitalize">{client.first_name && client.first_name.toLowerCase()}</span></td>
                    <td className="align-middle">{this.getType(client)}</td>
                    <td className="align-middle">{this.getClientLabel(client)}</td>
                    <td className="align-middle">{client.address} {client.postal_code} {client.city && client.city.toUpperCase()}</td>
                    <td className="align-middle"><Button variant="info" className="btn-sm" onClick={(e) => this.props.edit(client, "clientPatients")}><Icon icon="eye" className="mr-1" />{patientsNbr}</Button></td>
                    {(this.getMercurialStatus(client) ?
                        <td className="align-middle">{this.getMercurialName(client)}</td>
                        :
                        <td className="align-middle">
                            <Icon
                                icon="triangle-exclamation"
                                className={"text-danger mr-2"}
                                hover={<><FormattedMessage id="Mercurial.Outdated" values={{ date: this.getMercurialDate(client) }} /></>} />
                            <b className="text-danger">{this.getMercurialName(client)}</b>
                        </td>
                    )}
                    {user.role === Roles.ADMIN && crmEnabled && <td className="align-middle text-center">
                        <Icon
                            icon={client.crm_id ? "circle-check" : "circle-xmark"}
                            variant={client.crm_id ? "fas" : "far"}
                            size="xl" id={"synced_with_crm_" + client._id}
                            className={client.crm_id ? "text-success" : "text-danger"}
                            onClick={(e) => this.props.displayAPIModal(client)}
                            hover={<><FormattedMessage id={client.crm_id ? "API.CRM.Sync.Ok" : "API.CRM.Sync.Nok"} values={{ crmSoftware: crmSoftware }} /></>}
                        />
                    </td>}
                    {(!this.props.limit && user.role === Roles.ADMIN) && <td className="text-center tdaction">
                        {user.role === Roles.ADMIN &&
                            <MenuButton
                                icon="pen-to-square"
                                variant="info"
                                onClick={(e) => this.props.edit(client)}
                                hover={<FormattedMessage id="Modify" />}
                            />
                        }
                    </td>}

                </tr>

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        patients: state.patients,
        prescriberTypes: state.prescriberTypes,
        mercurials: state.mercurials,
        company: state.company,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PrescriberRow));
