import APIUrl from "../../APIUrl";
import axios from 'axios';

export const GET_CLIENTS = "GET_CLIENTS";

function getClientsAction(clients) { return { type: GET_CLIENTS, clients: clients } }
  
export const getClients = function(){
    return function(dispatch){
        return axios.get(APIUrl.getClients)
            .then(function(response){
                dispatch(getClientsAction(response.data));
            })  
            .catch(function(err){
                throw(err);
            });
    };
};

export const getClientsAdmin = function(){
    return function(dispatch){
        return axios.get(APIUrl.getClientsAdmin)
            .then(function(response){
                dispatch(getClientsAction(response.data));
            })  
            .catch(function(err){
                throw(err);
            });
    };
};

export const getClient = function(clientId, successCallback){
    return function(){
        return axios.post(APIUrl.getClient, {clientId})
            .then(function(response){
                if (successCallback) successCallback(response.data);
            })  
            .catch(function(err){
                throw(err);
            });
    };
};
  
export const addClient = function(client, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addClient, client)
            .then(function(response){
                dispatch(getClientsAdmin());
                if(successCallback) successCallback(response.data)
            })  
            .catch(function(err){
                throw err;
            });
    };
};

export const updClient = function(client, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.updClient, client)
            .then(function(response){
                dispatch(getClientsAdmin());
                if(successCallback) successCallback();
            })  
            .catch(function(err){
                throw err;
            });
    };
};

// export const deleteClient = function(clientId, successCallback){
//     return function(dispatch){
//         return axios.post(APIUrl.deleteClient, { clientId })
//             .then(function(response){
//                 dispatch(getClientsAdmin());
//                 if(successCallback) successCallback();
//             })  
//             .catch(function(err){
//                 throw err;
//             });
//     };
// };