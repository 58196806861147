import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Modal, Tab, Tabs } from 'react-bootstrap';

import Collaborator from './Collaborator';
import Assignments from './Assignments';

class CollaboratorsModal extends React.Component {

    close() {
        this.props.close();
    }

    getCollaborator(collaboratorId) {
        if (!collaboratorId) return null;

        for (let collaborator of this.props.collaborators) {
            if (collaborator._id === collaboratorId) return collaborator;
        }
    }

    render() {
        const { collaboratorId, intl, company, onCollaboratorCreated } = this.props;

        let collaborator = this.getCollaborator(collaboratorId);
        let aClassName = (collaborator) ? "" : "disabled";

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Body>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <Tabs defaultActiveKey="clients-info" id="clientsTabs">
                        <Tab eventKey="clients-info" title={intl.formatMessage({ id: "Informations" })} tabClassName={"text-brown"}>
                            <Collaborator
                                company={company}
                                collaborator={collaborator}
                                onCollaboratorCreated={(collaborator) => onCollaboratorCreated(collaborator)}
                            />
                        </Tab>

                        <Tab eventKey="clients-settings" title={intl.formatMessage({ id: "Assignments" })} tabClassName={aClassName + " text-brown"}>
                            <Assignments collaborator={collaborator} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        collaborators: state.collaborators,
        company: state.company
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollaboratorsModal));