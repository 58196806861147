import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { addPrescriberType, updatePrescriberType } from "../../../../actions/settings/prescriberType/admin/prescriberType";
import Util from '../../../../util/Util';
import CustomLabel from "../../../sub/CustomLabel";

class PrescriberTypeModalAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            disabled: false,
            modal: null,
            nameError: null
        };

        if (this.props.prescriberType) {
            this.state = {
                name: this.props.prescriberType.name,
                disabled: false,
                modal: null,
                nameError: null
            };
        }
    }

    close() {
        this.props.closeModal();
    }

    onChange(field, value) {
        if (field === 'name') {
            if (this.props.prescriberType && value === this.props.prescriberType.name) {
                this.setState({ nameError: null });
            } else {
                const alreadyExisting = this.props.prescriberTypes.find(t => value === t.name);

                if (alreadyExisting) {
                    this.setState({ nameError: <FormattedMessage id='PrescriberType.Already.Existing' /> });
                } else {
                    this.setState({ nameError: null });
                }
            }
        }

        this.setState({ [field]: value });
    }

    onSubmit() {
        if (this.checkErrors()) return;

        this.setState({ disabled: true });

        var data = {
            name: this.state.name
        };

        var successCallback = () => {
            this.props.closeModal();
        };

        this.props.onAddPrescriberType(data, successCallback);
    }

    onUpdate(updatedField, updatedValue) {
        if (!this.props.prescriberType || this.checkErrors()) return;


        var data = {
            _id: this.props.prescriberType._id,
            name: updatedValue
        }

        this.props.onUpdatePrescriberType(data);
    }

    checkErrors() {
        return (
            this.state.nameError ||
            this.state.disabled ||
            Util.emptyString(this.state.name)
        )
    }

    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id={this.props.prescriberType ? "Modify.Client.Type" : "Add.Client.Type"} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Client.Type" })}
                                    htmlFor="name"
                                    labelClassName="col-12 col-md-4 col-form-label"
                                    required />
                                <div id="name" className="col-12 col-md-8">
                                    <input className="form-control" type="text" id="name" autoComplete="off"
                                        value={this.state.name}
                                        onChange={(e) => this.onChange("name", e.target.value)}
                                        onBlur={(e) => this.onUpdate("name", this.state.name)} />
                                    <small className="text-danger">{this.state.nameError}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                {(!this.props.prescriberType) &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.close()}><FormattedMessage id="Cancel" /></Button>
                        <Button variant="btn btn-info" onClick={() => this.onSubmit()} disabled={this.checkErrors()}><FormattedMessage id="Add" /></Button>
                    </Modal.Footer>
                }
                {this.state.modal}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        prescriberTypes: state.prescriberTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddPrescriberType: (data, successCallback) => dispatch(addPrescriberType(data, successCallback)),
        onUpdatePrescriberType: (data) => dispatch(updatePrescriberType(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PrescriberTypeModalAdmin));