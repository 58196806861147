import ProductsTotalUtil from './ProductsTotalUtil';
import Maths from './Maths';

export default class ClientTotalUtil {

    constructor(clients, orders, products, oldProducts, getOldProductsCallback) {
        // this.prescriberId = prescriberId;
        this.clients = clients;
        this.orders = orders;
        this.products = products;
        this.oldProducts = oldProducts;
        this.getOldProductsCallback = getOldProductsCallback;
    }

    getTotalHT(){
        // First, find out all the orders from the clients
        var orders = [];
        for(let client of this.clients){

            for(let order of this.orders){
                if(order.id_client === client._id) orders.push(order);
            }
        }

        var totalHT = 0;
        for(let order of orders){
            totalHT += this.getCmdTotalHT(order);
        }

        return Maths.round(totalHT);
    }

    getCmdTotalHT(order){
        var productsTotalUtil = new ProductsTotalUtil(order.products, this.products, this.oldProducts, (data) => this.getOldProductsCallback(data));
        
        return Maths.round(productsTotalUtil.totalHt);
    }

}
