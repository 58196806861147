import { GET_AUDIT_DMS } from "../../../actions/settings/auditDM/admin/auditDM";

export default function auditDMs(state = [], action) {

    switch (action.type) {
        case GET_AUDIT_DMS:
            return action.auditDMs;
        default:
            return state;
    }

};
