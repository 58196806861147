import React from 'react';
import { connect } from 'react-redux';
import ExcelUtil from '../../util/ExcelUtil';
import { FormattedMessage, injectIntl } from 'react-intl';
import { findArticlesMust } from '../../actions/apiMust/apiMust';
import { findArticlesLomaco } from '../../actions/apiLomaco/apiLomaco';
import { syncWithCrm } from '../../actions/mercurials/mercurials';
import FileUtil from '../../util/FileUtil';
import { Button, Modal } from 'react-bootstrap';
import { getProductsByMercurialIdAdmin } from "../../actions/products/products";
import Icon from '../sub/Icon.js';
import MercurialsUtil from "../../util/mercurialsUtil.js";
import { CrmProviders } from "../../enums/CrmProviders";

class MercurialsSyncArticlesModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            oncall: false,
            confirm: false,
            loading: "progress-bar",
            progress: 0,
            products: this.props.products,
        };

    }

    close() {
        this.props.closeModal();
    }

    getCurrentMercurialUtils() {
        return new MercurialsUtil(this.props.company);
    }

    getMercurialMapping() {
        let currentMercurial = this.getCurrentMercurialUtils();
        return currentMercurial.getMercurialMapping();
    }

    getExcludedExportFields() {
        let currentMercurial = this.getCurrentMercurialUtils();
        return currentMercurial.getExcludedExportFields();
    }

    errorToExcel(fileContent) {
        let excelData = ExcelUtil.toExcel(fileContent, this.getMercurialMapping(), this.getExcludedExportFields());
        // Sanitize the file name
        let fileName = FileUtil.toFileName('erreurs_articles');

        // Save the file
        ExcelUtil.save(excelData, fileName);
    }

    getProductsLengthByMercurialId(mercurialId) {
        let response = 0;
        for (let p of this.props.products) {
            if (p.mercurial_id === mercurialId) response = response + 1;
        }
        return response;
    }
    getProducts(mercurialId) {
        let response = [];
        for (let p of this.props.products) {
            if (p.mercurial_id === mercurialId) response.push(p);
        }
        return response;
    }
    componentDidUpdate(prevProps) {
        if (
            prevProps.products !== this.props.products ||
            this.getProductsLengthByMercurialId(this.props.mercurial._id) === this.props.mercurial.productsLength
        ) {
            if (this.state.oncall === false && this.state.confirm === true) {
                    this.checkArticles(this.props.crmSoftware, this);
            }
        } else {
            if (
                this.checkProductsExistByMercurialId(
                    this.props.mercurial._id,
                    this.props.products //obligation car sinon la méthode ne prend que
                )
            ) {
                this.props.onGetProductsByMercurialIdAdmin(this.props.mercurial._id);
            }
            //   this.setState({ products: this.state.products });
        }
    }

    componentDidMount() {
        if (
            this.getProductsLengthByMercurialId(this.props.mercurial._id) === this.props.mercurial.productsLength
        ) {
            if (this.state.oncall === false && this.state.confirm === true) {
                    this.checkArticles(this.props.crmSoftware, this);
            }
        } else {
            this.props.onGetProductsByMercurialIdAdmin(this.props.mercurial._id);
        }
    }

    checkProductsExistByMercurialId(mercurialId, products) {
        for (let i = 0; i < products.length; i++) {
            if (products[i].mercurial_id === mercurialId) {
                return false;
            }
        }
        return true;
    }



    async checkArticles(crmSoftware, that) {
        let productsMercu = this.getProducts(this.props.mercurial._id);
        that.setState({ oncall: true });
        let productsCount = productsMercu.length;
        let fileContent = [];
        let dataChunk = [];
        let lastLoop = false;
        let i = 0;
        let currentLine = 0;
        // Set dynamic chunk size upon product count to generate a smooth progress bar
        let chunkSize = (productsCount > 10) ? Math.floor(productsCount / 20) : 1;

        let successCallback = (i) => {

            let response = ((i * 100) / productsMercu.length);
            if (((i * 100) / productsMercu.length) === 100) {
                if (fileContent.length === 0) {
                    that.setState({
                        progress: response,
                        loading: "text-success"
                    });
                    let data = { mercurialId: productsMercu[0].mercurial_id, synced_with_crm: true };
                    that.props.onSyncWithCrm(data);
                    that.close();
                } else {
                    let errorModalTitle = <FormattedMessage id="Error" />;
                    let errorModalContent = (
                        <div>
                            <p className="text-justify">
                                <FormattedMessage id="API.CRM.Product.Not.Found" values={{ crmSoftware: crmSoftware }} />
                            </p>
                            <p className={"btn btn-danger d-flex justify-content-center"} onClick={() => { this.errorToExcel(fileContent) }}>Télécharger</p>
                        </div>
                    );
                    if(fileContent === "LOGIN ERROR"){
                        errorModalContent = (
                            <div>
                                <p className="text-justify">
                                    <FormattedMessage id="API.CRM.Product.Not.Found" values={{ crmSoftware: crmSoftware }} />
                                </p>
                                <p className={"btn btn-danger d-flex justify-content-center"} >Login problem</p>
                            </div>
                        );
                    }
                    that.setState({ loading: "text-danger" });
                    that.setState({ progress: 0 });
                    that.props.openModal(errorModalTitle, errorModalContent);
                }
            } else {
                that.setState({ progress: response });
            }
            if ((i % chunkSize) === 0) {
                currentLine = currentLine + chunkSize;
            } else {
                currentLine = currentLine + (i % chunkSize);
            }

        };

        let failureCallback = (i, result) => {
            let response = ((i * 100) / productsMercu.length);
            let errorModalTitle = <FormattedMessage id="Error" />;
            let errorModalContent = (
                <div>
                    <p className="text-justify">
                        <FormattedMessage id="Unexpected.Error.Expl" />
                    </p>
                    <p className="text-justify">
                        <FormattedMessage id="API.CRM.Product.Not.Found" values={{ crmSoftware: crmSoftware }} />
                    </p>
                    <p className="text-center">
                        <button className={"btn btn-danger"} onClick={() => { that.errorToExcel(fileContent) }}><Icon icon="file-excel" className="mr-2" /> <FormattedMessage id="API.CRM.Download.Product.Error.File" /></button>
                    </p>
                </div>
            );

            let getMercurialMappingKeys = Object.keys(this.getMercurialMapping());

            for (let data of result.data) {

                let lineLabelLocalized = this.props.intl.formatMessage({ id: "Line" });
                let errorLabelLocalized = this.props.intl.formatMessage({ id: "Error" });

                let lineData = {
                    [lineLabelLocalized.toUpperCase()]: (currentLine + data.line + 1)
                };

                getMercurialMappingKeys.forEach(key => {
                    lineData[key] = data[key];
                });

                lineData[errorLabelLocalized.toUpperCase()] = data.lineError;

                fileContent.push(lineData);
            };
            if ((i % chunkSize) === 0) {
                currentLine = currentLine + chunkSize;
            } else {
                currentLine = currentLine + (i % chunkSize);
            }
            that.setState({ progress: response });
            if (lastLoop) {
                that.setState({ loading: "text-danger" });
                that.setState({ progress: 0 });
                that.props.openModal(errorModalTitle, errorModalContent);
            }
            //i = 1000000000;
        }

        for (let o = 0; i < productsCount; o++) {

            dataChunk = [];
            if( 10 < productsCount && productsCount < 20){
                chunkSize = Math.floor((productsCount - i)/(10-o));
              }
        
            if ((productsCount - (i)) > chunkSize) {
                for (let u = i; u < i + chunkSize; u++) {
                    dataChunk.push(productsMercu[u]);
                }
                i = i + chunkSize;
            } else {
                for (let u = 0; u < (productsCount - (i)); u++) {
                    dataChunk.push(productsMercu[i + u]);
                    lastLoop = true;
                }
                i = i + (productsCount - (i));
            }

            let j = i;
            if (crmSoftware === CrmProviders.MUST.software) {
                await that.props.onFindArticlesMust(
                    dataChunk,
                    () => successCallback(j),
                    (result) => failureCallback(j, result)
                )
            } else if (crmSoftware === CrmProviders.LOMACO.software) {
                await that.props.onFindArticlesLomaco(
                    dataChunk,
                    () => successCallback(j),
                    (result) => failureCallback(j, result)
                )
            } else {
                failureCallback(i, "Error CRM UNKNOWN");
            }


        }

    }
    stopEvent(e) {
        e.stopPropagation();
    }
    annulation() {
        this.close();
    }
    confirm() {
        this.setState({
            confirm: true
        });
    }
    render() {

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton={!this.state.confirm}>
                    <Modal.Title>
                        {!this.state.confirm ? <FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: this.props.crmSoftware }} /> : <FormattedMessage id="Sync.In.Progress" />}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!this.state.confirm && <div className="text-justify">
                        <p>
                            <FormattedMessage id="API.CRM.Sync.Confirmation.Warning" values={{ crmSoftware: this.props.crmSoftware }} />
                        </p>
                        <p>
                            <FormattedMessage id="Please.Confirm" />
                        </p>
                    </div>}

                    {this.state.confirm && <div className="text-justify">
                        <p className="alert alert-warning">
                            <FormattedMessage id="API.CRM.Sync.Working.Warning" values={{ crmSoftware: this.props.crmSoftware }} />
                        </p>
                        {<div className="progress">
                            <div className={this.state.loading} style={{ width: this.state.progress + "%" }} role="progressbar" aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">
                                {Math.floor(this.state.progress) + "%"}
                            </div>
                        </div>}
                    </div>}
                </Modal.Body>

                {!this.state.confirm && <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.annulation()}><FormattedMessage id="Cancel" /></Button>
                    <Button variant="danger" onClick={() => this.confirm()}><FormattedMessage id="Confirm" /></Button>
                </Modal.Footer>}

            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
        products: state.products,
        company: state.company,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFindArticlesMust: (data, successCallback, failureCallback) => dispatch(findArticlesMust(data, successCallback, failureCallback)),
        onFindArticlesLomaco: (data, successCallback, failureCallback) => dispatch(findArticlesLomaco(data, successCallback, failureCallback)),
        onSyncWithCrm: (data) => dispatch(syncWithCrm(data)),
        onGetProductsByMercurialIdAdmin: (mercurialId) => dispatch(getProductsByMercurialIdAdmin(mercurialId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MercurialsSyncArticlesModal));