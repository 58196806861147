import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Util from '../../util/Util';
import { updateUser } from '../../actions/user/user';
import { updPatient } from '../../actions/patients/patients';
import CustomLabel from "../sub/CustomLabel";

class UpdateAccountForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: this.props.user.username,
            name: this.props.user.name,
            first_name: this.props.user.first_name,
            email: this.props.user.email,
            phone: this.props.user.phone,
            function: this.props.user.function,
            emailError: null,
        };
    }

    onChange(key, value) {
        this.setState({ [key]: value });
    }

    update(field, value) {
        if (Util.emptyString(value)) return;

        if (field === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
            return;
        } else {
            this.setState({ emailError: null });
        }

        const updatePatient = () => {
            if (field === "function" || !this.props.user.patient_id) return;

            const patient = {
                _id: this.props.user.patient_id,
                [field]: value
            };

            this.props.onUpdPatient(patient);
        }

        let data = {
            updatedField: field,
            updatedValue: value
        };
        this.props.onUpdateUser(data, updatePatient);
    }

    render() {
        return (
            <div className="col-12 col-sm-8">
                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Username" })}
                        htmlFor="username"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required
                    />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="username" autoComplete="off"
                            value={this.state.username || ""}
                            disabled={true} />
                    </div>
                </div>
                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "First.Name" })}
                        htmlFor="first_name"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required
                    />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="first_name" autoComplete="off"
                            value={this.state.first_name || ""}
                            onChange={(e) => this.onChange("first_name", e.target.value)}
                            onBlur={() => this.update("first_name", this.state.first_name)} />
                    </div>
                </div>
                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Name" })}
                        htmlFor="name"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required
                    />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="name" autoComplete="off"
                            value={this.state.name || ""}
                            onChange={(e) => this.onChange("name", e.target.value)}
                            onBlur={() => this.update("name", this.state.name)} />
                    </div>
                </div>
                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Email" })}
                        htmlFor="email"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required
                    />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="email" id="email" autoComplete="off"
                            value={this.state.email || ""}
                            onChange={(e) => this.onChange("email", e.target.value)}
                            onBlur={() => this.update("email", this.state.email)} />
                        <small className="text-danger">{this.state.emailError}</small>
                    </div>
                </div>
                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Function" })}
                        htmlFor="function"
                        labelClassName="col-12 col-sm-5 col-form-label"
                    />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="function" autoComplete="off"
                            value={this.state.function || ""}
                            onChange={(e) => this.onChange("function", e.target.value)}
                            onBlur={() => this.update("function", this.state.function)} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdPatient: (data, successCallback) => dispatch(updPatient(data, successCallback)),
        onUpdateUser: (data, successCallback) => dispatch(updateUser(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UpdateAccountForm));