import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import {
    getProductsByMercurialIdAdmin,
    getListProductsAdmin,
} from "../../../../actions/products/products";
import { createPromoOperation, addPromoOperationFile, updatePromoOperation } from "../../../../actions/promoOperations/promoOperations";
import APIUrl from '../../../../APIUrl';
import AutoSuggestProducts from '../../../sub/AutoSuggestProducts';
import FileDropZone from '../../../sub/FileDropZone';
import CustomLabel from "../../../sub/CustomLabel";
import Util from '../../../../util/Util';
import DateUtil from '../../../../util/DateUtil';
import MercurialStatus from "../../../../enums/MercurialStatus";
import Icon from '../../../sub/Icon.js';
import StringUtil from '../../../../util/StringUtil';
import MenuButton from "../../../sub/bootstrap/MenuButton";

class OperationPromotionalModal extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.promoOperation) {
            var { promoOperation, concernedProduct } = this.props;

            this.state = {
                mercurialId: promoOperation.mercurial_id,
                concernedProduct: concernedProduct ? concernedProduct : null,
                concernedProductId: promoOperation.product_id,
                startDate: DateUtil.toyyyyMMdd(promoOperation.startDate),
                endDate: DateUtil.toyyyyMMdd(promoOperation.endDate),
                operationName: promoOperation.operationName,
                applyForAll: promoOperation.applyForAll,
                disabled: false,
                popSugg: false,
                file: null,
                clientId: "",
                clientsIds: promoOperation.prescribers_ids,
                dateError: null,
                tabDisabled: false
            };
        } else {
            this.state = {
                mercurialId: null,
                concernedProduct: concernedProduct ? concernedProduct : null,
                concernedProductId: null,
                startDate: null,
                endDate: null,
                operationName: null,
                disabled: false,
                popSugg: false,
                file: null,
                applyForAll: true,
                clientId: "",
                clientsIds: [],
                dateError: null,
                tabDisabled: true
            }
        }
        if (this.props.mercurial_id) {
            this.props.onGetProductsByMercurialIdAdmin(this.props.mercurial_id);
        }

    }

    close() {
        this.props.closeModal();
    }

    checkDate(startDateValue, endDateValue) {
        let startDate;
        let endDate;

        startDate = new Date(startDateValue).getTime();
        endDate = new Date(endDateValue).getTime();
        if (startDate >= endDate) {
            this.setState({
                dateError: <FormattedMessage id="StartDate.Superior.EndDate" />
            });
            return;
        } else {
            this.setState({
                dateError: null
            });
        }
    }

    onChange(key, value) {
        switch (key) {
            case "mercurialId":
                this.props.onGetProductsByMercurialIdAdmin(value);
                this.setState({ clientId: "", clientsIds: [] });
                break;
            case "startDate":
                if (this.state.endDate) this.checkDate(value, this.state.endDate);
                break;
            case "endDate":
                if (this.state.startDate) this.checkDate(this.state.startDate, value);
                break;
            default:
                this.setState({
                    [key]: value
                });
        }

        this.setState({
            [key]: value
        });
    }

    onSearch(value, products, mercurialId) {
        for (let product of products) {
            if ((product.ref === value.ref) && (product.mercurial_id === mercurialId)) {
                this.setState({ concernedProductId: product._id });
                this.setState({ concernedProduct: product.designation });
            }
        }
    }

    isUpdate() {
        let isUpdate = false;
        if (this.props.promoOperation) isUpdate = true;
        return isUpdate;
    }

    disabled() {
        if (!this.state.mercurialId || !this.state.concernedProductId || !this.state.startDate || !this.state.endDate || !this.state.operationName || this.state.dateError) return true;
        else return false;
    }

    onDropFile(file) {
        if (!file || file.length === 0) return;

        if (this.isUpdate()) {
            let formData = new FormData();
            formData.append(file.name, file);

            this.props.onAddPromoOperationFile(this.props.promoOperationId, formData);
        } else {
            this.setState({ file: file });
        }
    }

    onSubmit() {
        if (this.disabled()) return;

        let sendFile = (operationPromotional) => {
            if (!this.state.file || this.state.file.length === 0) {
                this.close();
                this.props.editPromotionalOperationModal(operationPromotional);
                return;
            }

            let formData = new FormData();
            formData.append(this.state.file.name, this.state.file);

            this.props.onAddPromoOperationFile(operationPromotional._id, formData);

            this.setState({ tabDisabled: false });
            this.close();
            this.props.editPromotionalOperationModal(operationPromotional);
        };

        let data = {
            operationName: this.state.operationName,
            product_id: this.state.concernedProductId,
            mercurial_id: this.state.mercurialId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            applyForAll: this.state.applyForAll,
        }

        this.props.onCreatePromoOperation(data, sendFile)
    }

    onUpdate(updatedField, updatedValue) {
        if (this.isUpdate() && !Util.emptyString(updatedValue) && updatedValue !== this.props.promoOperation[updatedField]) {

            let data = {};

            switch (updatedField) {
                case "startDate":
                    if (this.state.endDate) this.checkDate(updatedValue, this.state.endDate)
                    break;
                case "endDate":
                    if (this.state.startDate) this.checkDate(this.state.startDate, updatedValue)
                    break;
                case "product_id":
                    if (this.state.mercurialId !== this.props.promoOperation.mercurial_id) {
                        data = {
                            promoOperationId: this.props.promoOperation._id,
                            mercurial_id: this.state.mercurialId,
                            product_id: this.state.concernedProductId,
                        }
                    }
                    break;
                default:
                    data = {
                        promoOperationId: this.props.promoOperation._id,
                        updatedValue: updatedValue,
                        updatedField: updatedField
                    }
            }

            this.props.onUpdatePromoOperation(data)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.products !== this.state.products) {
            this.props.onGetProductsByMercurialIdAdmin(this.state.mercurialId);
        }
    }
    addClient() {
        if (Util.emptyString(this.state.clientId) || this.state.disabled) return;

        this.setState({ disabled: true, clientId: "" });

        if (this.isUpdate()) {
            let successCallback = () => {
                this.setState({ disabled: false });
            }

            let data = {
                promoOperationId: this.props.promoOperation._id,
                clientIdToAdd: this.state.clientId
            }

            this.addClientFromState()
            this.props.onUpdatePromoOperation(data, successCallback);
        } else {
            this.addClientFromState()
            this.setState({ disabled: false });
        }
    }

    removeClient(clientId) {
        if (this.state.disabled) return;

        this.setState({ disabled: true });

        if (this.isUpdate()) {
            let successCallback = () => {
                this.setState({ disabled: false });
                this.removeClientFromState(clientId)
            }

            let data = {
                promoOperationId: this.props.promoOperation._id,
                clientIdToDelete: clientId
            }

            this.props.onUpdatePromoOperation(data, successCallback);
        } else {
            this.removeClientFromState(clientId)
        }
    }

    addClientFromState() {
        this.state.clientsIds.push(this.state.clientId);
    }

    removeClientFromState(clientId) {
        for (let i = 0; i < this.state.clientsIds.length; i++) {
            if (this.state.clientsIds[i] === clientId) {
                this.state.clientsIds.splice(i, 1);
                this.setState({ disabled: false });
            }
        }
    }

    getClientLabel(clientTypeId) {
        let clientType = this.props.prescriberTypes.find((p) => p._id === clientTypeId);
        if (clientType && clientType.name) return clientType.name;
        return null;
    }

    getTypeName = (client) => {
        if (client.client_type && client.client_type === 'helper') {
            return this.props.intl.formatMessage({ id: "Client.Helper" });
        }

        if (client.client_type && client.client_type === 'prescriber') {
            return this.props.intl.formatMessage({ id: "Client.Prescriber" });
        }
    }

    getMercurialClient() {
        let mercurialId = this.state.mercurialId
        let clients = [];
        for (let client of this.props.clients) {
            if (client.mercurial_Id === mercurialId) clients.push(client._id);
        }
        return clients;
    }

    getClients() {
        let clientsId = this.getMercurialClient();
        let clients = [];
        for (let client of this.props.clients) {
            for (let clientId of clientsId) {
                if (client._id === clientId) clients.push(client);
            }
        }
        return clients;
    }

    getProductsByMercurial() {
        let filteredProducts = [];

        if (this.state.mercurialId) {
            filteredProducts = this.props.products.filter(
                (p) => p.mercurial_id === this.state.mercurialId
            );
        }
        return filteredProducts;
    }

    displaySearchSuggestions() {
        let filteredProducts = this.getProductsByMercurial();
        let suggestions = [];

        filteredProducts.forEach(element => {
            let obj = {};
            obj.designation = element.designation;
            obj.caracteristiques = element.caracteristiques;
            obj.ref = element.ref;
            obj.mercurial_id = element.mercurial_id;
            obj.ref_frn = element.ref_frn;
            obj.famille = element.famille;
            obj.sous_famille = element.sous_famille;
            suggestions.push(obj);
        });
        return suggestions;
    }

    selectClientsByMercurialState() {
        let clients = this.getClients();

        return clients.map((client) => {

            let disabled = false;

            if (this.state.mercurialId && this.state.clientsIds) {
                disabled = this.state.clientsIds.find(clientId => client._id === clientId);
            }

            return <option value={client._id} key={client._id} disabled={disabled}> {client.first_name} {client.name}</option>;
        });
    }

    displayTargetClients() {
        let clientsAssignmentsNodes = null;

        if (this.isUpdate()) {
            clientsAssignmentsNodes = this.state.clientsIds.map((id) => {
                let client = this.props.clients.find(client => client._id === id);
                if (!client) return null;

                return (
                    <tr key={"client-" + client._id}>
                        <td className="d-none d-md-table-cell">{client.name.toUpperCase()} {StringUtil.ucFirst(client.first_name)}</td>
                        <td className="d-none d-md-table-cell">{this.getTypeName(client)}</td>
                        <td className="d-none d-md-table-cell">{this.getClientLabel(client.type)}</td>
                        <td className="tdaction text-center">
                            <MenuButton
                                icon="circle-minus"
                                onClick={(e) => this.removeClient(client._id)}
                                hover={<FormattedMessage id="Delete" />
                                } />
                        </td>
                    </tr>
                );
            });
        }
        return clientsAssignmentsNodes;
    }

    checkProductsExistByProductId(productId) {
        for (let i = 0; i < this.props.products.length; i++) {
            if (this.props.products[i]._id === productId) {
                return false;
            }
        }
        return true;
    }

    getProduct(productId) {
        //TODO éviter qu'il y ait une boucle infini en cas de produit introuvable (dû à une suppression)
        let response = null;
        if (this.checkProductsExistByProductId(productId)) {
            this.props.onGetListProductsAdmin([productId], () =>
                this.getProduct(productId)
            );
        } else {
            for (var product of this.props.products) {
                if (product._id === productId) {
                    response = product.designation;
                }
            }
        }
        return response;
    }

    render() {
        let filteredProducts = this.getProductsByMercurial();
        let suggestions = this.displaySearchSuggestions();
        let clientsNode = this.selectClientsByMercurialState();
        let clientsAssignmentsNodes = this.displayTargetClients();
        let concernedProduct = "";
        if (this.props.promoOperation)
            concernedProduct = !this.state.tabDisabled
                ? this.getProduct(this.props.promoOperation.product_id)
                : "";

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Body>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <Tabs defaultActiveKey="promo" id="promoTabs">
                        <Tab eventKey="promo" title={this.props.intl.formatMessage({ id: "Informations" })}>
                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Operation.Name" })}
                                    htmlFor="operationName"
                                    required={this.props.required}
                                />
                                <div className="col-12 col-sm-7">
                                    <input className="form-control" type="text" id="operationName"
                                        onBlur={() => this.onUpdate("operationName", this.state.operationName)}
                                        value={this.state.operationName || ""} onChange={(e) => this.onChange("operationName", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Mercurial" })}
                                    // htmlFor="select"
                                    required={this.props.required}
                                />
                                <div className="col-12 col-sm-7">
                                    <select className="form-control"
                                        /*id="select"*/
                                        value={this.state.mercurialId || ""}
                                        onChange={(e) => this.onChange("mercurialId", e.target.value)}
                                        disabled={!this.state.tabDisabled}
                                    >
                                        <option value="">{this.props.intl.formatMessage({ id: "Mercurial.Select" })}</option>
                                        {
                                            this.props.mercurials.map(t => {
                                                var hasExpired = (new Date(t.start_date) > Date.now() || new Date(t.end_date) < Date.now());
                                                var isInactive = (t.status === MercurialStatus.INACTIVE);
                                                if (hasExpired || isInactive) return null
                                                return (
                                                    <option key={t._id} value={t._id} disabled={hasExpired || isInactive}>{t.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            {this.state.mercurialId &&
                                <div className="form-group row">
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Concerned.Product" })}
                                        htmlFor="search"
                                        required={this.props.required}
                                    />
                                    <div className="col-12 col-sm-7">
                                        {!this.state.tabDisabled ?
                                            <input className="form-control" type="text" id="search"
                                                value={concernedProduct || ""}
                                                disabled={true}
                                            />
                                            :
                                            <AutoSuggestProducts
                                                id="search"
                                                name="search"
                                                onClick={(e) => this.setState({ "popSugg": true })}
                                                onBlur={(e) => this.update("name", e.target.value)}
                                                onSuggestionSelected={(value) => this.onSearch(value, filteredProducts, this.state.mercurialId)}
                                                suggestions={suggestions}
                                                placeholder={"Search.Product.Placeholder"}
                                                value={this.state.concernedProduct}
                                            />
                                        }
                                    </div>
                                </div>
                            }

                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Start.Date" })}
                                    htmlFor="startDate"
                                    required={this.props.required}
                                />
                                <div className="col-12 col-sm-7">
                                    <input className="form-control" type="date" id="startDate"
                                        onBlur={() => this.onUpdate("startDate", this.state.startDate)}
                                        value={this.state.startDate || ""}
                                        onChange={(e) => this.onChange("startDate", e.target.value)}
                                        disabled={!this.state.tabDisabled}
                                    />
                                    <div className="text-danger"><small>{this.state.dateError}</small></div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "End.Date" })}
                                    htmlFor="endDate"
                                    required={this.props.required}
                                />
                                <div className="col-12 col-sm-7">
                                    <input className="form-control" type="date" id="endDate"
                                        onBlur={() => this.onUpdate("endDate", this.state.endDate)}
                                        value={this.state.endDate || ""}
                                        onChange={(e) => this.onChange("endDate", e.target.value)}
                                        disabled={!this.state.tabDisabled}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Associated.Documentation" })}
                                    required={false}
                                />
                                <div className="col-12 col-sm-7">
                                    <FileDropZone
                                        disabled={this.state.disabledFileUpload}
                                        onDropFile={(file) => this.onDropFile(file)}
                                        acceptedExtensions={["pdf"]}
                                        multiple={false}
                                    />
                                </div>
                            </div>

                            {this.props.promoOperation &&
                                <div className="form-group row">
                                    <a role="button" href={APIUrl.getPromoOperationFile + this.props.promoOperation._id + "/" + this.props.promoOperation.fileName + "?token=" + APIUrl.jwtToken}
                                        rel="noopener noreferrer" download={this.props.promoOperation.fileName} target="_blank">{this.props.promoOperation.fileName}</a>
                                </div>
                            }
                        </Tab>

                        <Tab eventKey="affectations" title={this.props.intl.formatMessage({ id: "Assignments" })} disabled={this.state.tabDisabled}>
                            {this.state.mercurialId &&
                                <div className="form-group row">
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Apply.Operation.For.All.Clients" })}
                                        htmlFor="applyForAll"
                                        required={false}
                                    />
                                    <div className="col-12 col-sm-7">
                                        <div className="custom-control custom-switch mx-auto switch-success text-left">
                                            <input
                                                type="checkbox"
                                                id="applyForAll-switch"
                                                className="custom-control-input switch-bg-blue"
                                                checked={this.state.applyForAll}
                                                value={this.state.applyForAll}
                                                onChange={(e) => this.onChange("applyForAll", !this.state.applyForAll)}
                                                onBlur={(e) => this.onUpdate("applyForAll", this.state.applyForAll)}
                                            />
                                            <CustomLabel
                                                htmlFor="applyForAll-switch"
                                                labelClassName="custom-control-label"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {(this.state.mercurialId) &&
                                <div className={this.state.applyForAll ? "form-group row disabled d-flex align-items-center" : "form-group row d-flex align-items-center"}>
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Assign.Client" })}
                                        htmlFor="clientId"
                                        required={false}
                                    />
                                    <div className="col-11 col-md-6">
                                        <select className="form-control d-inline" id="clientId" value={this.state.clientId}
                                            disabled={this.state.applyForAll}
                                            onChange={(e) => this.onChange("clientId", e.target.value)}
                                        >
                                            <option value="">{this.props.intl.formatMessage({ id: "Add.Client" })}</option>
                                            {clientsNode}
                                        </select>
                                    </div>

                                    <div className="col-1 text-left align-left float-left p-0">
                                        <Icon icon="square-plus" clickable size="xl" className="text-success"
                                            onClick={() => this.addClient()}
                                            disabled={Util.emptyString(this.state.clientId) || this.state.disabled} />
                                    </div>
                                </div>
                            }

                            {(this.state.mercurialId && this.state.clientsIds && this.state.clientsIds.length > 0 && !this.state.applyForAll) &&
                                <table className="table col-12 tablee4mad mt-5">
                                    <thead>
                                        <tr>
                                            <th className="col-3"><FormattedMessage id="Name" /> <FormattedMessage id="And" /> <FormattedMessage id="First.Name" /></th>
                                            <th className="col-3"><FormattedMessage id="Type" /></th>
                                            <th className="col-3"><FormattedMessage id="Client.Label" /></th>
                                            <th className="text-center tdaction col-3"><FormattedMessage id="Actions" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientsAssignmentsNodes}
                                    </tbody>
                                </table>
                            }
                        </Tab>
                    </Tabs>
                </Modal.Body>

                {!this.isUpdate() &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.close()}><FormattedMessage id="Cancel" /></Button>
                        <Button variant="btn btn-info" onClick={() => this.onSubmit()} disabled={this.disabled()}><FormattedMessage id="Add" /></Button>
                    </Modal.Footer>
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        products: state.products,
        mercurials: state.mercurials,
        clients: state.clients,
        prescriberTypes: state.prescriberTypes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreatePromoOperation: (data, sendFile) => dispatch(createPromoOperation(data, sendFile)),
        onAddPromoOperationFile: (operationPromotionalId, data, successCallback) => dispatch(addPromoOperationFile(operationPromotionalId, data, successCallback)),
        onUpdatePromoOperation: (data, successCallback) => dispatch(updatePromoOperation(data, successCallback)),
        onGetProductsByMercurialIdAdmin: (mercuriaId) => dispatch(getProductsByMercurialIdAdmin(mercuriaId)),
        onGetListProductsAdmin: (data, successCallback) => dispatch(getListProductsAdmin(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OperationPromotionalModal));
