import {GET_USER} from "../../actions/user/user";

export default function user(state={}, action) {

  switch (action.type) {
    case GET_USER:
      return Object.assign({}, state, action.user);
    default:
      return state;
  }

};