import APIUrl from "../../../../APIUrl";
import axios from "axios";
//import { getAuditDMs } from '../../auditDM/admin/auditDM';

export const GET_DESK_PRODUCTS = "GET_DESK_PRODUCTS";

function getDeskProductsAction(deskProducts) {
  return { type: GET_DESK_PRODUCTS, deskProducts: deskProducts };
}

export const getDeskProducts = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskProducts)
      .then(function (response) {
        dispatch(getDeskProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getDeskProduct = function (name, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskProduct + name)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskProduct = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskProduct, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskProductFile = function (productId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskProductFile + productId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function () {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateDeskProduct = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateDeskProduct, data)
      .then(function () {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDeskProduct = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDeskProduct, data)
      .then(function () {
        dispatch(getDeskProducts());
        //dispatch(getAuditDMs());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addTypeofControl = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskTypeofControl, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteTypeofControl = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDeskTypeofControl, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateTypeofControl = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateDeskTypeofControl, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskProductRelated = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskProductRelated, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskCategoryRelated = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskCategoryRelated, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDeskCategoryRelated = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDeskCategoryRelated, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDeskProductRelated = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDeskProductRelated, data)
      .then(function (response) {
        dispatch(getDeskProducts());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
