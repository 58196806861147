import { GET_AUDIT_SETTINGS } from "../../../actions/settings/auditSettings/auditSettings";

export default function auditSettings(state = {}, action) {
    switch (action.type) {
        case GET_AUDIT_SETTINGS:
            return action.auditSettings;
        default:
            return state;
    }

};
