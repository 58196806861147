import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import OrderStatus from '../../../enums/OrderStatus';
import Accessibilities from '../../../enums/MenuAccessibilities';
import Roles from '../../../enums/Roles';
import { getProducts } from "../../../actions/products/products";
import { passOrder } from '../../../actions/orders/orders';

import Menu from './menu/Menu';

const propTypes = {
    userRole: PropTypes.number.isRequired,
    orders: PropTypes.array.isRequired
};

const defaultProps = {
    userRole: Roles.CLIENT,
};

/**
 * Menu E4MAD (navbar)
 * @param {Number} userRole get user role to get the appropriate menu
 */
class MenuMAD extends React.Component {

    countNewOrders() {
        let nbr = 0;
        for (let order of this.props.orders) {
            if (order.status === OrderStatus.NEW) nbr++;
        }
        return nbr;
    }

    render() {
        const { userRole, user } = this.props

        let newOrdersNbr = this.countNewOrders();

        let menuItems =
            [
                { link: "/home", formattedMessageId: "Home", accessibility: Accessibilities.COMMON },
                { link: "/home/mercuriales", formattedMessageId: "Mercurials", accessibility: Accessibilities.ADMIN_AND_SALES_REP },
                { link: "/home/families", formattedMessageId: "Mercurial", accessibility: Accessibilities.CLIENT_ONLY },
                { link: "/home/orders", formattedMessageId: "Orders", isCountBadge: true, countValue: newOrdersNbr, accessibility: Accessibilities.COMMON },
                { link: "/home/clients", formattedMessageId: "Client.Monitoring", accessibility: Accessibilities.ADMIN_AND_SALES_REP },
                { link: "/home/patients", formattedMessageId: user.client_type === "patient" ? "Patient.Folder": "Patients.Monitoring", accessibility: Accessibilities.COMMON },
                { link: "/home/documents", formattedMessageId: "Documents", accessibility: Accessibilities.ADMIN_AND_SALES_REP },
                { link: "/home/estimates", formattedMessageId: "Estimates", accessibility: Accessibilities.COMMON },
                { link: "/home/budget", formattedMessageId: "Budget", accessibility: Accessibilities.COMMON },
                { link: "/home/settings", formattedMessageId: "Settings", accessibility: Accessibilities.ADMIN_ONLY },
            ];

        const menuItemLogo = { imgSrc: "./images/svg/logo-header-e4mad.svg", imgAlt: "logo e4mad" };

        return (
            <React.Fragment>
                <Menu
                    userRole={userRole}
                    menuItems={menuItems}
                    menuItemLogo={menuItemLogo}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.orders,
        user: state.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPassOrder: (data, successCallback) => dispatch(passOrder(data, successCallback)),
        onGetProducts: () => dispatch(getProducts()),
    };
};

MenuMAD.propTypes = propTypes;
MenuMAD.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuMAD));
