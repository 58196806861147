import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Util from '../../../../util/Util';
import { addCollaboratorClient } from '../../../../actions/collaborators/collaborators';
import CollaboratorAssignments from './CollaboratorAssignments';
import { Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import Icon from '../../../sub/Icon';

class Assignments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            disabled: false,
            selectedClient: null,
        }
    }

    closeModal() {
        this.setState({modal: null});
    }

    getSelectOptions() {
        const options = [];
        const clients = this.props.clients.filter((c) => this.props.collaborator.prescribers.find((p) => p.prescriber_id === c._id) === undefined);
        for (const c of clients) {
            options.push({value: c._id, label: `${c.name} ${c.first_name}`});
        }
        return options;
    }

    onChange(field, value) {
        this.setState({ [field]: value });
    }

    addClient(clientId) {
        if (!clientId || Util.emptyString(clientId)) return;

        const data = {
            collaboratorId: this.props.collaborator._id,
            clientId: clientId
        };

        this.setState({ disabled: true });

        const successCallback = () => {
            this.setState({ disabled: false, selectedClient: null });
            this.closeModal();
        };

        this.props.onAddCollaboratorClient(data, successCallback);
    }

    render() {

        const { collaborator } = this.props;

        if (!collaborator) return null;

        if (!collaborator.prescribers) collaborator.prescribers = [];

        return (
            <>
                <Row className='justify-content-center mb-3'>
                    <Col md={5}>
                        <Select
                            placeholder={<FormattedMessage id={"Select.Client"}/>}
                            options={this.getSelectOptions()}
                            isClearable
                            noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                            onChange={(option) => this.setState({selectedClient: option})}
                            value={this.state.selectedClient}
                        />
                    </Col>
                    <Button variant='info' onClick={() => this.addClient(this.state.selectedClient.value)} disabled={!this.state.selectedClient}><Icon icon="plus-circle"/> <FormattedMessage id="Assign"/></Button>
                </Row>
                <CollaboratorAssignments collaborator={this.props.collaborator} />
                {this.state.modal}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        collaborators: state.collaborators,
        clients: state.clients,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddCollaboratorClient: (data, successCallback) => dispatch(addCollaboratorClient(data, successCallback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);