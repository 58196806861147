import React from 'react';
import { connect } from 'react-redux';
import PDFUtil from "../../../util/PDFUtil";
import DateUtil from "../../../util/DateUtil";
import { FormattedMessage, injectIntl } from 'react-intl';
import OrderProducts from "../OrderProducts";
import { getListProductsAdmin } from "../../../actions/products/products";
import OrderPDF from "../OrderPDF";
import PDFViewer from "../../pdf/PDFViewer";
import OrderStatus from '../../../enums/OrderStatus';
import { deleteOrderAdmin, updateAdmin, duplicateAdmin, syncOrderMustAdmin, syncOrderLomacoAdmin } from '../../../actions/orders/orders';
import ActionMenu from "../../sub/ActionMenu";
import Maths from "../../../util/Maths";
import Icon from "../../sub/Icon.js";
import Roles from "../../../enums/Roles";
import { Row, Col, Badge } from "react-bootstrap";
import { CrmProviders } from "../../../enums/CrmProviders";

class OrderRowAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            displayPDF: false,
            disableExport: false,
            disabledDuplicat: false,
            crmSyncIcon: false, // Store FontAwesome class to make icons spin on sync (if CRM enabled)
            modal: null
        };
    }

    collapse() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.order !== this.props.order) {
            this.forceUpdate();
        }
    }

    deleteOrder(onDelete) {
        onDelete();
    }

    hasUrgent(products) {
        for (let product of products) {
            if (product.urgent) return true;
        }

        return false;
    }

    canPDF(order) {
        return (
            order.status === OrderStatus.PENDING ||
            order.status === OrderStatus.SENT ||
            order.status === OrderStatus.NEW ||
            order.status === OrderStatus.BLOCKED
        );//|| order.status === Role.RECEIVED
    }

    isProductsExistById(productsId) {
        for (let i = 0; i < this.props.productsForCrm.length; i++) {
            if (this.props.productsForCrm[i]._id === productsId) {
                return true;
            }
        }
        return false;
    }

    togglePDF(pdfElemId, pdfFileName) {
        if (this.state.disableExport || (this.props.user.role === Roles.CLIENT && !this.canPDF(this.props.order))) return;
        if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
            // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
            this.setState({ displayPDF: !this.state.displayPDF });
        } else {
            // Possibility n°2 (prod env) : directly save to PDF
            this.setState({ disableExport: true });
            const successCallback = () => this.setState({ disableExport: false });
            PDFUtil.toPDF(this.props.generalSettings, this.props.company, pdfElemId, pdfFileName, "/css/orderPdf.css", successCallback);
        }
    }

    updateOrderStatus(e, order) {
        let data = {
            updatedValue: e.target.value,
            updatedField: "status",
            orderId: order._id
        };

        this.props.onUpdateAdmin(data);
    }

    syncOrderWithCRM(crmSoftware, orderId) {
        const failureCallback = (data) => {
            this.setState({ crmSyncIcon: false });

                    let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
                    let modalContent =
                        <div>
                            <p className="alert alert-danger text-justify"><FormattedMessage id="API.CRM.Sync.Error.Message" values={{ crmSoftware: crmSoftware }} /></p>
                            <div className="alert alert-light text-break">
                                <p><FormattedMessage id="Error.Detail" />:</p>
                                <code>{JSON.stringify(data)}</code>
                            </div>
                        </div>

                    const successCallback = (e) => { }

                    this.props.openErrorModal(modalTitle, modalContent, successCallback)
        }
        const successCallback = (crmResponse) => {
            this.setState({ crmSyncIcon: false });

            // Check if an error occured while syncing with CRM
            if (crmSoftware === CrmProviders.MUST.software) {
                const crmError = crmResponse.data.SaveDossier3CEResult.Erreur;

                if (crmError.HasError) {

                    let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
                    let modalContent =
                        <div>
                            <p className="alert alert-danger text-justify"><FormattedMessage id="API.CRM.Sync.Error.Message" values={{ crmSoftware: crmSoftware }} /></p>
                            <div className="alert alert-light text-break">
                                <p><FormattedMessage id="Error.Detail" />:</p>
                                <code>{JSON.stringify(crmError)}</code>
                            </div>
                        </div>

                    const successCallback = (e) => { }

                    this.props.openErrorModal(modalTitle, modalContent, successCallback)
                }
            }
            else {
                if (crmResponse.status === 200) {
                    let modalTitle = <FormattedMessage id="API.CRM.Already.Synchronized.With" />;
                    let modalContent =
                        <div>
                            <p className="alert alert-danger text-justify"><FormattedMessage id="API.CRM.Sync.Order.Success.Content" values={{ crmSoftware: crmSoftware }} /></p>
                        </div>

                    const successCallback = (e) => { }

                    this.props.openSuccessModal(modalTitle, modalContent, successCallback);
                } else {
                    let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
                    let modalContent =
                        <div>
                            <p className="alert alert-danger text-justify"><FormattedMessage id="API.CRM.Sync.Error.Message" values={{ crmSoftware: crmSoftware }} /></p>
                            <div className="alert alert-light text-break">
                                <p><FormattedMessage id="API.CRM.Sync.Error.Message" values={{ crmSoftware: crmSoftware }} /></p>
                            </div>
                        </div>

                    const successCallback = (e) => { }

                    this.props.openErrorModal(modalTitle, modalContent, successCallback);
                }
            }
        }
        if(this.props.company.crm.software === CrmProviders.LOMACO.software){
            this.props.onSyncOrderLomacoAdmin({ orderId: orderId }, successCallback);
        }else if(this.props.company.crm.software === CrmProviders.MUST.software){
            this.props.onSyncOrderMustAdmin({ orderId: orderId }, successCallback,failureCallback);
        }
        
    }

    // Check if the mercurial that relates to the current order is synced with a crm or not
    isMercurialSynced(orderProducts) {
        let synced = false;
        if (orderProducts.length === 0) return false;
        let orderProductSampleId = orderProducts[0]['id_product'];
        let iterableProducts = this.props.productsForCrm.length === 0
            ? this.props.oldProducts
            : this.props.productsForCrm;

        for (let currentProduct of iterableProducts) {

            if (currentProduct._id === orderProductSampleId) {

                for (let currentMercurial of this.props.mercurials) {
                    
                    if (currentProduct.mercurial_id === currentMercurial._id) {
                        synced = currentMercurial.synced_with_crm;
                    }

                }

            }

        }

        return synced;

    }

    // Display a sync button with crm (if linked)
    syncButton(crmSoftware) {
        let resquestedProducts = [];
        for (let y = 0; y < this.props.order.products.length; y++) {
            if (
                !this.isProductsExistById(this.props.order.products[y].id_product) &&
                !resquestedProducts.includes(this.props.order.products[y].id_product)
            ) {
                resquestedProducts.push(this.props.order.products[y].id_product);
            }
        }
        if (resquestedProducts.length !== 0) {
            this.props.onGetProductsAdmin(resquestedProducts);
        }
        let syncMessage = "API.CRM.Synchronize.With"
        let disabled = true;
        let btnOnClick = () => { return false; };

        let modalTitle = <FormattedMessage id="Confirmation.Required" />;
        let modalContent = <div><p><FormattedMessage id="API.CRM.Synchronize.Message" values={{ crmSoftware: crmSoftware }} /></p><p><FormattedMessage id="Please.Confirm" /></p></div>
        let successCallback = (e) => {
            // Start sync icon animation
            this.setState({ crmSyncIcon: "arrows-rotate" })
            this.syncOrderWithCRM(crmSoftware, this.props.order._id);
        }

        if (this.props.client.crm_id && this.isMercurialSynced(this.props.order.products) && this.props.order.synced_with_crm === false) {
            btnOnClick = (e) => {
                this.props.openConfModal(modalTitle, modalContent, successCallback);
            };
            disabled = false;
        } else if (!this.props.client.crm_id && this.isMercurialSynced(this.props.order.products) && this.props.order.synced_with_crm === true) {
            syncMessage = "API.CRM.Already.Synchronized.With"
            disabled = true;
        }
        else if (!this.props.client.crm_id) {
            syncMessage = "API.CRM.Sync.Unavailable"
            disabled = true;
        }
        else {
            if (this.isMercurialSynced(this.props.order.products)) {
                syncMessage = "API.CRM.Already.Synchronized.With"
                disabled = true;
            }
            else {
                syncMessage = "API.CRM.Sync.Unavailable"
                disabled = true;
            }
        }

        // Sync orders
        return {
            id: "sync" + this.props.order._id,
            icon: "arrows-rotate",
            disabled: disabled,
            action: btnOnClick,
            text: <FormattedMessage id={syncMessage} values={{ crmSoftware: crmSoftware }} />
        }
    }

    syncIcon(crmSoftware) {
        if (this.isMercurialSynced(this.props.order.products) && (!this.props.order.synced_with_crm || this.props.order.synced_with_crm === false)) {
            return <Icon
                icon={this.state.crmSyncIcon ? "arrows-rotate" : "circle-xmark"}
                size="xl" variant={this.state.crmSyncIcon ? "fas" : "far"}
                className={this.state.crmSyncIcon ? "fa-spin text-info" : "text-danger"}
                hover={<FormattedMessage id="API.CRM.Sync.Nok" values={{ crmSoftware: crmSoftware }} />}
            />
        }
        else {
            if (this.isMercurialSynced(this.props.order.products)) {
                return <Icon
                    icon={this.state.crmSyncIcon ? "arrows-rotate" : "circle-check"}
                    size="xl"
                    className={this.state.crmSyncIcon ? "fa-spin text-info" : "text-success"}
                    hover={<FormattedMessage id="API.CRM.Sync.Ok" values={{ crmSoftware: crmSoftware }} />}
                />
            }
            else {
                return <Icon
                    icon={this.state.crmSyncIcon ? "arrows-rotate" : "circle-xmark"}
                    size="xl"
                    variant={this.state.crmSyncIcon ? "fas" : "far"}
                    className={this.state.crmSyncIcon ? "fa-spin text-info" : "text-muted"}
                    hover={<FormattedMessage id="API.CRM.Sync.Unavailable" values={{ crmSoftware: crmSoftware }} />}
                />
            }
        }
    }

    defineOrderStatusOption(orderHasProducts) {

        let orderStatusClassName = "light";

        switch (parseInt(this.props.order.status)) {
            case OrderStatus.NEW: orderStatusClassName = "danger";
                break;
            case OrderStatus.PENDING: orderStatusClassName = "info";
                break;
            case OrderStatus.SENT: orderStatusClassName = "success";
                break;
            case OrderStatus.BLOCKED: orderStatusClassName = "warning";
                break;
            case OrderStatus.CANCELED: orderStatusClassName = "secondary";
                break;
            default:
                break;
        }

        const statusToBeDisplayed = Object.assign({}, OrderStatus);

        /**
         * Do not display the "to be validated" option in search fiter select (useless)
         */
        delete statusToBeDisplayed.TBV;
        const statusNode = Object.values(statusToBeDisplayed).map((option) => {
            return (
                <option key={option} value={option}>
                    {this.props.intl.formatMessage({ id: "Order.Status." + option })}
                </option>
            );
        });

        const selectStatus = <select className={!orderHasProducts ? "form-control" : "form-control alert-" + orderStatusClassName} onChange={(e) => this.updateOrderStatus(e, this.props.order)} defaultValue={this.props.order.status} disabled={!orderHasProducts}>{statusNode}</select>
        const syncStatus = <div className={"form-control alert-" + orderStatusClassName} defaultValue={this.props.order.status} disabled={!orderHasProducts}>
            <Row className="d-flex items-align-center">
                <Col sm={9} className="pr-0">
                    {this.props.intl.formatMessage({ id: "Order.Status." + this.props.order.status })}
                </Col>
                <Col sm={3} className="text-right pr-1 pl-0">
                    <small>
                        <Badge>Auto</Badge>
                    </small>
                </Col>
            </Row>
        </div>

        let output;

        switch (true) {
            case this.isMercurialSynced(this.props.order.products) && (this.props.company.crm?.options?.must_allow_sync_order_status || this.props.company.crm?.options?.lomaco?.allow_sync_order_status):
                output = syncStatus;
                break;
            case !(this.props.company.crm?.options?.must_allow_sync_order_status || this.props.company.crm?.options?.lomaco?.allow_sync_order_status):
            default:
                output = selectStatus;
                break;
        }

        return output;

    }

    render() {
        if (!this.props.client) return null;

        const orderHasProducts = this.props.order.products.length > 0;

        // 'Delete order' modal setup
        const modalTitle = <FormattedMessage id="Confirm" />;
        const modalContent = <FormattedMessage id="Order.Remove.Confirmation" />;
        const successCallback = () => this.deleteOrder(() => this.props.onDeleteOrderAdmin({ orderId: this.props.order._id }));

        const pdfElemId = "order-pdf-" + this.props.order._id;
        const pdfFileName = "order-" + this.props.order._id;
        let pdfOrder = null;
        // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
        // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
        const { enabled: crmEnabled = false, software: crmSoftware = null } = this.props.company.crm ? this.props.company.crm : {};

        if (orderHasProducts) {
            pdfOrder = (
                <OrderPDF
                    id={pdfElemId}
                    order={this.props.order}
                    client={this.props.client}
                />
            );
        }

        let menuItems = [];

        menuItems.push(
            // Show/hide products
            orderHasProducts && {
                id: "collapse" + this.props.order._id,
                icon: (this.state.collapsed ? "eye" : "eye-slash"),
                disabled: !orderHasProducts,
                action: () => (orderHasProducts) && this.collapse(),
                text: this.state.collapsed ? <FormattedMessage id="Display.Product.Order" /> : <FormattedMessage id="Hide.Product.Order" />
            },
            // Split orders
            (orderHasProducts) && {
                id: "split" + this.props.order._id,
                icon: "scissors",
                disabled: this.props.order.status === OrderStatus.SENT || this.props.order.status === OrderStatus.CANCELED || !orderHasProducts,
                action: () => (orderHasProducts) && this.props.openSplitModal(this.props.order),
                text: <FormattedMessage id="Split.Order" />
            },
            // Download PDF
            orderHasProducts && {
                id: "downloadPdf" + this.props.order._id,
                icon: "file-pdf",
                disabled: !orderHasProducts,
                action: () => (orderHasProducts) && this.togglePDF(pdfElemId, pdfFileName),
                text: <FormattedMessage id="Download.PDF" />
            },
            // Delete order
            this.props.order.status !== OrderStatus.NEW && {
                id: "delete" + this.props.order._id,
                icon: "trash",
                disabled: this.props.order.status === OrderStatus.NEW,
                action: () => this.props.openConfModal(modalTitle, modalContent, successCallback),
                text: <FormattedMessage id="Delete" />
            },
            crmEnabled && this.syncButton(crmSoftware)
        );

        const creationDate = DateUtil.toDateWithHour(this.props.order.creation_date);
        const deliveryDate = DateUtil.toDate(new Date(this.props.order.creation_date).getTime() + this.props.order.shipping_delay * 24 * 3600 * 1000)

        return (
            <>
                <tr key={this.props.order._id} className={(!orderHasProducts) ? "d-flex font-italic text-muted align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>
                    <td className="col col-2">
                        {this.props.client.name.toUpperCase()} {this.props.client.first_name}
                    </td>
                    <td className="col">
                        {this.props.order.ref}
                        {(this.props.order.comment && this.props.comment !== "") &&
                            <Icon
                                icon="comments"
                                size="xl"
                                className="float-right text-info"
                                hover={<><FormattedMessage id="Available.Comment" /> : {this.props.order.comment}</>}
                            />
                        }
                    </td>
                    <td className="col">{creationDate}</td>
                    <td className="col">{deliveryDate}</td>
                    <td className="col">{Maths.round(this.props.order.total_ht)}€</td>
                    <td className="col">
                        {this.defineOrderStatusOption(orderHasProducts)}
                    </td>
                    {crmEnabled && <td className="d-flex align-items-center text-center">
                        {this.syncIcon(crmSoftware)}
                    </td>}
                    {!this.props.limit && <td className="col-1 text-center">
                        {<ActionMenu items={menuItems} variant={(orderHasProducts && this.hasUrgent(this.props.order.products)) ? "danger" : "info"} />}
                        {<PDFViewer open={this.state.displayPDF} toggle={(pdfElemId, pdfFileName) => { if (orderHasProducts) this.togglePDF(pdfElemId, pdfFileName) }} template={pdfOrder} fileName={pdfFileName} />}
                    </td>}
                </tr>

                {!this.state.collapsed && <tr className="d-flex">
                    <td colSpan="8" className="col p-1">
                        {orderHasProducts && (
                            <OrderProducts order={this.props.order} paginate={true} />
                        )}
                    </td>
                </tr>}

                {this.state.modal}
            </>
        );
    }

}

const mapStateToProps = state => {
    return {
        user: state.user,
        oldProducts: state.oldProducts,
        generalSettings: state.generalSettings,
        company: state.company,
        ordersSettingsAdmin: state.ordersSettingsAdmin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteOrderAdmin: (data) => dispatch(deleteOrderAdmin(data)),
        onUpdateAdmin: (data) => dispatch(updateAdmin(data)),
        onDuplicateAdmin: (data, successCallback) => dispatch(duplicateAdmin(data, successCallback)),
        onSyncOrderLomacoAdmin: (data, successCallback)=> dispatch(syncOrderLomacoAdmin(data, successCallback)),
        onSyncOrderMustAdmin: (data, successCallback,failureCallback) => dispatch(syncOrderMustAdmin(data, successCallback,failureCallback)),
        onGetProductsAdmin: (data) => dispatch(getListProductsAdmin(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderRowAdmin));
