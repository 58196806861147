import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Util from "../../../../util/Util";

import { logout } from '../../../../actions/authentication/authentication';
import { getListProducts } from "../../../../actions/products/products";

import Roles from '../../../../enums/Roles';
import Accessibilities from '../../../../enums/MenuAccessibilities';

import ProductsTotalUtil from '../../../../util/ProductsTotalUtil';

import MenuItemClientLogo from '../MenuItems/MenuItemClientLogo';
import DropdownMenuProfile from '../DropdownMenuProfile';
import MenuItem from '../MenuItems/MenuItem';
import MenuItemLogo from '../MenuItems/MenuItemLogo';
import APIUrl from '../../../../APIUrl';
import MenuItemCart from '../MenuItems/MenuItemCart';

import './menu.css';

const propTypes = {
    cart: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    ordersSettings: PropTypes.any.isRequired,
    generalSettings: PropTypes.object.isRequired
};

/**
 * Menu (navbar)
 * @param {Number} userRole get user role to get the appropriate menu
 * @param {Array} menuItems list of all informations for items menu
 * @param {Array} menuItemLogo list of informations for logo item menu
 */
class Menu extends React.Component {

    checkProductsExistById(productsId) {
        for (let i = 0; i < this.props.products.length; i++) {
            if (this.props.products[i]._id === productsId) {
                return false;
            }
        }
        return true;
    }

    getCartProducts() {
        let checkProducts = [];

        for (let cart of this.props.cart) {
            if (this.checkProductsExistById(cart.id_product)) {
                checkProducts.push(cart.id_product);
            }
        }

        if (checkProducts.length) {
            this.props.onGetListProducts(checkProducts);
        }
    }

    countCartProduct() {
        let nbr = 0;
        for (let c of this.props.cart) {
            nbr += c.quantity;
        }
        return nbr;
    }

    countTotalCart() {
        // Helper that will do all calculations for us
        let productsTotalUtil = 0;
        const { cart, products, ordersSettings } = this.props;
        if (cart.length !== 0 && products.length !== 0 && ordersSettings) {
            productsTotalUtil = new ProductsTotalUtil(cart, products, null, null, ordersSettings);
            return productsTotalUtil.totalTtc;
        }
        return productsTotalUtil;
    }

    checkIsDisabled() {
        let disabled = true;
        const { cart, products, ordersSettings } = this.props;
        if (cart.length !== 0 && products.length !== 0) {
            let productsTotalUtil = new ProductsTotalUtil(cart, products, null, null, ordersSettings);
            disabled = !productsTotalUtil.aboveMinToOrder();
        }
        return disabled
    }

    logout(e) {
        e.preventDefault();
        e.stopPropagation();

        let token = APIUrl.jwtToken;

        function onLogoutSuccess() {
            return window.location.replace(Util.checkHttps(process.env.REACT_APP_LOGIN_FE_URL) + token);
        };

        this.props.onLogout(onLogoutSuccess);
    }

    getAccessibilityByRole(itemAccessibility) {
        let returnValue = false;
        switch (this.props.userRole) {
            case Roles.CLIENT:
                if (itemAccessibility === Accessibilities.CLIENT_ONLY || itemAccessibility === Accessibilities.COMMON) returnValue = true;
                break;
            case Roles.ADMIN:
                if (itemAccessibility === Accessibilities.ADMIN_ONLY || itemAccessibility === Accessibilities.ADMIN_AND_SALES_REP ||
                    itemAccessibility === Accessibilities.COMMON) returnValue = true;
                break;
            case Roles.SALES_REP:
                if (itemAccessibility === Accessibilities.ADMIN_AND_SALES_REP || itemAccessibility === Accessibilities.COMMON) returnValue = true;
                break;
            default:
            //console.log(this.props.userRole + " is an user role with no case");
        }
        return returnValue;
    }

    render() {
        this.getCartProducts();

        const { generalSettings, userRole, menuItems, menuItemLogo } = this.props;

        let totalCart = this.countTotalCart();
        let CartProductsNbr = this.countCartProduct();
        let disabled = this.checkIsDisabled();

        let clientLogoSrc = APIUrl.getGeneralSettingsLogo + generalSettings._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken;
        let clientName = generalSettings.commercial_sign;
        let hasLogo = generalSettings.logo;

        let isAdmin = userRole === Roles.ADMIN;
        let isCollaborator = userRole === Roles.SALES_REP;

        const menuItemNode = (items) => {
            return items.map((item, index) => {
                return this.getAccessibilityByRole(item.accessibility) && (
                    <div key={index} className="m-1">
                        <MenuItem
                            link={item.link}
                            event={item.event}
                            formattedMessageId={item.formattedMessageId}
                            isCountBadge={item.isCountBadge}
                            countValue={item.countValue}
                        />
                    </div>
                )
            });
        }

        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-lg menu">
                    <MenuItemLogo
                        link={(isAdmin || isCollaborator) ? "/#" : "/home"}
                        imgSrc={menuItemLogo.imgSrc}
                        imgAlt={menuItemLogo.imgAlt}
                        onClick={(isAdmin || isCollaborator) ? (e) => this.logout(e) : null}
                    />

                    <div id="menu" className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2">

                        <ul className="navbar-nav list-inline text-center align-items-center">

                            {menuItemNode(menuItems)}

                            {!isAdmin && !isCollaborator &&
                                <MenuItemCart
                                    link={"/home/cart"}
                                    countValue={CartProductsNbr}
                                    totalCart={totalCart}
                                    disabled={disabled}
                                />
                            }

                            <MenuItemClientLogo
                                hasLogo={hasLogo}
                                imgSrc={clientLogoSrc}
                                imgAlt={clientName}
                            />

                            <DropdownMenuProfile />
                        </ul>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu"
                        aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="fa fa-bars"></span>
                    </button>
                </nav>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        cart: state.cart,
        products: state.products,
        ordersSettings: state.ordersSettings,
        generalSettings: state.generalSettings,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (successCallback) => dispatch(logout(successCallback)),
        onGetListProducts: (data) => dispatch(getListProducts(data)),
    };
};

Menu.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
