import { GET_CHECKPOINTS } from "../../actions/checkpoints/checkpoints";

export default function checkpoints(state = [], action) {

    switch (action.type) {
        case GET_CHECKPOINTS:
            return action.checkpoints;
        default:
            return state;
    }

};
