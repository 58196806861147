import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DateUtil from '../../../util/DateUtil';
import ConfirmationModal from '../modals/ConfirmationModal';
import APIUrl from '../../../APIUrl';
import Roles from '../../../enums/Roles';
import AddDocumentModal from './AddDocumentModal';
import PropTypes from "prop-types";
import Icon from '../Icon.js';
import TableToolbar from "../bootstrap/TableToolbar";
import { Alert } from "react-bootstrap";

const propTypes = {
    data: PropTypes.object.isRequired,
    documentType: PropTypes.string.isRequired,
    addDataDocument: PropTypes.any.isRequired,
    updateData: PropTypes.any.isRequired,
    deleteDocument: PropTypes.any.isRequired,
    messageId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired
}

/**
 * Modal to add a document with title
 * @param {Array} data Object where document will be store
 * @param {String} documentType string to define what type of document it will be
 * @param {any} addDataDocument fonction to store file
 * @param {any} updateData fonction to update data
 * @param {any} deleteDocument fonction to delete the document
 * @param {string} messageId id for formatted message to display modal title
 * @param {string} adminEmptyMessageId id for formatted message to display empty document found (admin)
 * @param {string} clientEmptyMessageId id for formatted message to display empty document found (client)
 * @param {string} user actual user to check its role
 * @param {string} url url to get document from the backend
 */
class Document extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            disabled: false
        };
    }

    openModal(data) {
        this.setState({
            modal: <AddDocumentModal
                data={data}
                documentType={this.props.documentType}
                addDataDocument={(patientId, documentType, file, successCallback) => this.props.addDataDocument(patientId, documentType, file, successCallback)}
                updateData={(patient, successCallback) => this.props.updateData(patient, successCallback)}
                close={() => this.closeModal()}
                messageId={this.props.messageId}
            />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    delete(sheet) {
        const { data } = this.props;

        if (!sheet || !data) return;

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={<FormattedMessage id="Warning" />}
                content={
                    <div>
                        {sheet.is_shared_file ? <Alert variant="info"><FormattedMessage id="Delete.Shared.Document.Message" /></Alert> : <Alert variant="danger"><FormattedMessage id="Delete.Not.Shared.Document.Message" /></Alert>}
                        <div>
                            <FormattedMessage id="Confirm.Document.Removal" />
                        </div>
                    </div>}
                context="danger"
                successCallback={() => this.props.deleteDocument(data._id, this.props.documentType, sheet._id)}
                closeModal={() => this.closeModal()} />
        });
    }

    fileUrl(file) {
        const { data, documentType, url } = this.props;
        let fileUrl = file.is_shared_file ? file.url : `${url}${data._id}/${file._id}/${documentType}`;
        return `${fileUrl}?token=${APIUrl.jwtToken}`;
    }

    render() {
        const { user, data, documentType } = this.props;
        const { role } = user;

        if (role === undefined) return null;
        if (!data) return null;

        return (
            <React.Fragment>

                {(role === Roles.ADMIN || role === Roles.SALES_REP) &&
                    <TableToolbar><button className="btn btn-info ml-auto" onClick={(e) => this.openModal(data)}>
                        <FormattedMessage id={this.props.messageId} />
                    </button></TableToolbar>}
                {
                    (role !== Roles.CLIENT && (!data || !data[documentType] || data[documentType].length <= 0)) &&
                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id={this.props.adminEmptyMessageId} />
                    </div>
                }

                {
                    (role === Roles.CLIENT && (!data || !data[documentType] || data[documentType].length <= 0)) &&
                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id={this.props.clientEmptyMessageId} />
                    </div>
                }

                {(data && data[documentType] && data[documentType].length > 0) &&
                    <table className="table tablee4mad mt-3">
                        <thead>
                            <tr className="tablerowhead">
                                <th className="d-md-table-cell"><FormattedMessage id="Add.Date" /></th>
                                <th className="d-md-table-cell"><FormattedMessage id="Title" /></th>
                                <th className="d-md-table-cell"><FormattedMessage id="File" /></th>
                                <th className="d-md-table-cell text-center"><FormattedMessage id="Shared" /></th>
                                {(role === Roles.ADMIN || role === Roles.SALES_REP) && <th className="d-md-table-cell tdaction text-center"><FormattedMessage id="Actions" /></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {data[documentType].map((sheet, index) => (
                                <tr key={sheet._id + index} className={"order-tr order-tr"}>
                                    <td className="d-md-table-cell mw-200 align-middle">{DateUtil.toDate(sheet.date)}</td>
                                    <td className="d-md-table-cell mw-200 align-middle">
                                        {sheet.title}
                                    </td>
                                    <td className="d-md-table-cell mw-200 align-middle">
                                        <a id={"display" + sheet._id} role="button" href={this.fileUrl(sheet)}
                                            rel="noopener noreferrer" download={sheet.file_name} target="_blank">
                                            {sheet.file_name}
                                        </a>
                                    </td>
                                    <td className="d-md-table-cell mw-200 align-middle text-center">
                                        {sheet.is_shared_file ? <Icon icon="check-circle" hover={<FormattedMessage id="Document.From.Library" />} className="text-success" /> : <Icon icon="times-circle" hover={<FormattedMessage id="Document.Not.From.Library" />} className="text-danger" />}
                                    </td>
                                    {(role === Roles.ADMIN || role === Roles.SALES_REP) && <td className="col-1 text-center tdaction">
                                        <Icon
                                            icon="trash"
                                            clickable
                                            size="xl"
                                            disabled={this.state.disabled}
                                            onClick={(e) => this.delete(sheet)}
                                            hover={<FormattedMessage id="Delete" />}
                                        />
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }

                {this.state.modal}

            </React.Fragment>
        );
    }
}

Document.propTypes = propTypes;

export default injectIntl(Document);