import {GET_COLLABORATORS} from "../../actions/collaborators/collaborators";

export default function collaborators(state=[], action) {

  switch (action.type) {
    case GET_COLLABORATORS:
      return action.collaborators;
    default:
      return state;
  }

};