import Icon from './Icon.js';
import React from 'react';
import Util from "../../util/Util";
import PopoverHelper from './bootstrap/PopoverHelper';

export default class CustomLabel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            label: this.checkProp(this.props.label) ? this.props.label : "",
            htmlFor: this.checkProp(this.props.htmlFor) ? this.props.htmlFor : "",
            required: this.props.required ? this.props.required : null,
            help: this.props.help || false
        };
    }

    // Check if required props are set and are of type "string" and not empty
    checkProp = (myProp) => {
        return (Util.typeOf(myProp) === "String") && (myProp.length > 0);
    }

    render() {
        let labelClassName = this.checkProp(this.props.labelClassName) ? this.props.labelClassName : "col-12 col-md-5";
        labelClassName += " col-form-label";

        let requiredIcon;

        // Specific options for labels made of icons where there is no need for a "String" label
        let iconOption = this.props.iconOptions ? this.props.iconOptions : null;
        if (iconOption && !this.state.label) iconOption = (<Icon icon={iconOption} className={"mb-2 fa-sm"} />);

        if (this.state.required) {
            requiredIcon = <Icon icon="asterisk"/>;
            labelClassName += " required";
        }

        return (
            <label
                htmlFor={this.state.htmlFor}
                className={labelClassName}
            >
                <span className="mr-1">
                    {this.state.label}
                    {requiredIcon} {iconOption}
                </span>
                {this.state.help && <PopoverHelper trigger="click">{this.state.help}</PopoverHelper>}
            </label>)
    }
}