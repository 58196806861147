import { GET_PATIENTS } from "../../actions/patients/patients";

export default function patients(state = [], action) {

    switch (action.type) {
        case GET_PATIENTS:
            return action.patients;
        default:
            return state;
    }

};
