import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';

class SuccessModal extends React.Component {

    close() {
        this.props.closeModal();
    }

    render() {
        const { title, content } = this.props;

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {content}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="text-white btn btn-info" onClick={() => this.close()}><FormattedMessage id="Ok" /></Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SuccessModal;