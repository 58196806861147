import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import RoomAssociationsRow from "./RoomAssociationsRow";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { Alert } from "react-bootstrap";
import { nanoid } from "nanoid";

function RoomAssociations({ auditRooms, onDeleteRoom }) {
  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  const openConfModal = (title, content, successCallback) => {
    setModal(
      <ConfirmationModal
        isOpen={true}
        context="danger"
        title={title}
        content={content}
        successCallback={successCallback}
        closeModal={() => closeModal()}
      />
    );
  };

  const auditRoomsNode =
    auditRooms &&
    auditRooms.map((room) => {
      return (
        <RoomAssociationsRow
          key={nanoid()}
          room={room}
          roomsCount={auditRooms.length}
          onDeleteRoom={(room) => onDeleteRoom(room)}
          openConfModal={(title, content, successCallback) =>
            openConfModal(title, content, successCallback)
          }
        />
      );
    });

  return (
    <>
      {auditRooms && auditRooms.length > 0 ? (
        <table className="table table-striped table-bordered col-12 tablee4mad">
          <thead>
            <tr>
              <th className="col-10">
                <FormattedMessage id="Environment" />
              </th>
              <th className="col-2 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>

          <tbody>{auditRoomsNode}</tbody>
        </table>
      ) : (
        <Alert variant="info" className="col-12">
          <FormattedMessage id="Empty.Rooms.Associated" />
        </Alert>
      )}

      {modal}
    </>
  );
}

export default RoomAssociations;
