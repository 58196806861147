import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Alert } from 'react-bootstrap';
import Util from "../../util/Util";
import CustomLabel from '../sub/CustomLabel';
import axios from "axios";
import APIUrl from "../../APIUrl";

class DocumentFolderModal extends React.Component {
    constructor(props) {
        super(props);

        const defaultAppScope = {
            e4bizz: false,
            e4coll: false,
            e4mad: true,
            e4vhp: false
        };

        const defaultUserScope = {
            prescriber: false,
            patient: true,
        };

        this.state = {
            name: (this.props.folder && this.props.folder.name) || "",
            parent: (this.props.folder && this.props.folder.parent) || this.props.parentId || "",
            parentName: (this.props.folder && this.props.folder.parent) || this.props.parentName || "",
            appScope: (this.props.folder && this.props.folder.app_scope) || defaultAppScope,
            userScope: (this.props.folder && this.props.folder.user_scope) || defaultUserScope,
            disabled: false,
        };
    }

    disabled() {
        return (
            Util.emptyString(this.state.name) ||
            Util.emptyString(this.state.parent) ||
            (!this.state.appScope.e4bizz && !this.state.appScope.e4coll && !this.state.appScope.e4mad && !this.state.appScope.e4vhp) ||
            this.state.disabled
        );
    }

    onSubmit() {
        if (this.disabled()) return;

        this.setState({ disabled: true });

        if (!this.props.folder) {
            const newFolder = {
                name: this.state.name,
                parent: this.state.parent,
                app_scope: this.state.appScope,
                user_scope: this.state.userScope
            }

            axios.post(APIUrl.addDocumentsDbCategory, newFolder).then((response) => {
                this.props.syncData()
                this.props.close();
            })
        } else {
            const updatedDocument = {
                _id: this.props.folder._id,
                name: this.state.name,
                app_scope: this.state.appScope,
                user_scope: this.state.userScope,
            }

            axios.post(APIUrl.updateDocumentsDbCategory, updatedDocument).then((response) => {
                this.props.syncData()
                this.props.close();
            })
        }
    }

    render() {
        const { folder, intl } = this.props;

        return (
            <Modal
                show={true}
                onHide={() => this.props.close()}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{folder ? <><FormattedMessage id={"Rename"} /> "{folder.name}"</> : <FormattedMessage id={"Create.Folder"} />}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {(this.state.parentName && !folder) && <Alert variant="info"><FormattedMessage id={"Creation.Folder.Message"} /> <strong>"{this.state.parentName}"</strong>.</Alert>}
                    <div className="form-group row">
                        <CustomLabel
                            label={intl.formatMessage({ id: "Name" })}
                            htmlFor="name"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required />
                        <div className="col-12 col-sm-7">
                            <input className="form-control" maxLength={32} type="text" id="name" autoComplete="off"
                                value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <button className="btn btn-info btn-block" disabled={this.disabled()} onClick={() => this.onSubmit()}>
                                <FormattedMessage id={folder ? "Rename" : "Create"} />
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentFolderModal));