import APIUrl from "../../APIUrl";
import axios from 'axios';

export const GET_INTERVENTIONS = "GET_INTERVENTIONS";

function getInterventionsAction(interventions) {
    return { type: GET_INTERVENTIONS, interventions: interventions };
}

//********************************** INTERVENTIONS (ADMIN & SALEREP) ************************************/

export const getInterventionsAdmin = function () {
    return function (dispatch) {
        return axios
            .get(APIUrl.getInterventionsAdmin)
            .then(function (response) {
                if (response) dispatch(getInterventionsAction(response.data));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const deleteIntervention = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.deleteIntervention, data)
            .then(function (response) {
                dispatch(getInterventionsAdmin());
                if (successCallback) successCallback(response.data);
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

//********************************** INTERVENTIONS (USER) ************************************/

export const getInterventions = function () {
    return function (dispatch) {
        return axios
            .get(APIUrl.getInterventions)
            .then(function (response) {
                if (response) dispatch(getInterventionsAction(response.data));
            })
            .catch(function (err) {
                throw err;
            });
    };
};