import React from 'react';
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const propTypes = {
    link: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

/**
 * Logo item for user navbar
 * @param {String} link navlink to redirect
 * @param {String} imgSrc source link to display image
 * @param {String} imgAlt alternative text if image not displayed
 * @param {Function} onClick action to do on image click
 */
class MenuItemLogo extends React.Component {
    render() {
        const { link, imgSrc, imgAlt, onClick } = this.props;
        return (
            <React.Fragment>
                <Link to={link} className="logo-item navbar-brand" onClick={onClick}>
                    <img className="logo-header" alt={imgAlt} src={imgSrc} />
                </Link>
            </React.Fragment>
        );
    }
}

MenuItemLogo.propTypes = propTypes;

export default withRouter((MenuItemLogo));
