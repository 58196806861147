import {GET_DOCUMENTS} from "../../actions/documents/documents";

export default function documents(state=[], action) {

  switch (action.type) {
    case GET_DOCUMENTS:
      return action.documents;
    default:
      return state;
  }

};
