import { GET_AUDIT_ROOMS } from "../../../actions/settings/auditRooms/admin/auditRooms";

export default function auditRooms(state = [], action) {

    switch (action.type) {
        case GET_AUDIT_ROOMS:
            return action.auditRooms;
        default:
            return state;
    }

};
