import APIUrl from "../../APIUrl";
import axios from 'axios';
import { getOrdersAdmin } from '../orders/orders';
export const GET_CLIENT_CODE = "GET_CLIENT_CODE";

//function getClientCodeAction(clientCode) { return { type: GET_CLIENT_CODE, getClientCode: clientCode } };

export const findClientLomaco = function (data, successCallback, failureCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.lomacoFindCli, { data: data })
            .then(function (response) {
                // dispatch(getClientCodeAction(response.data));
                return successCallback(response.data[0]);
            })
            .catch(function (err) {
                if (err.response) {
                    if (err.response.status === 512) {
                        return failureCallback(err.response);
                    }
                }
                //failureCallback(err.response);
                throw err;
            });
    };
};

export const findArticlesLomaco = function (data, successCallback, failureCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.lomacoFindArticles, { data: data })
            .then(function (response) {
                return successCallback();
            })
            .catch(function (err) {
                // console.log(err.response.status);
                if (err.response) {
                    if (err.response.status === 512) {
                        return failureCallback(err.response);
                    }
                }
                throw err;
            });
    };
};

export const lomacoGetEtatDocument = function (data, successCallback, failureCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.lomacoGetEtatDossier, { data: data })
            .then(function (response) {
                dispatch(getOrdersAdmin());
                successCallback(response.data);
            })
            .catch(function (err) {
                failureCallback(err.response);
                throw err;
            });
    };
}
