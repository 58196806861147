import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AuditDMModalAdmin from "./AuditDMModalAdmin";
import AuditDMTableAdmin from "./AuditDMTableAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { Alert, Button } from "react-bootstrap";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";

function AuditDM({ auditRoomId, auditDMs }) {
  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  const openEditModal = (auditDM) => {
    setModal(
      <AuditDMModalAdmin
        closeModal={() => closeModal()}
        auditDMId={auditDM._id}
        roomId={auditDM.room_id}
      />
    );
  };

  const openAuditDMModal = () => {
    setModal(
      <AuditDMModalAdmin
        auditRoomId={auditRoomId}
        openEditModal={(auditDM) => openEditModal(auditDM)}
        closeModal={() => closeModal()}
      />
    );
  };

  const openConfModal = (title, content, successCallback) => {
    setModal(
      <ConfirmationModal
        title={title}
        content={content}
        context="danger"
        successCallback={successCallback}
        closeModal={() => closeModal()}
      />
    );
  };

  return (
    <>
      <TableToolbar>
        <Button
          variant="info"
          className="ml-auto"
          onClick={(e) => openAuditDMModal()}
        >
          <FormattedMessage id="Add.DM" />
        </Button>
      </TableToolbar>

      {(!auditDMs || auditDMs.length <= 0) && (
        <Alert variant="secondary" className="mt-2">
          <FormattedMessage id="Empty.Audit.DM" />
        </Alert>
      )}

      {auditDMs && auditDMs.length !== 0 && (
        <AuditDMTableAdmin
          auditRoomId={auditRoomId}
          auditDMs={auditDMs}
          openEditModal={(auditDM) => openEditModal(auditDM)}
          openConfModal={(title, content, successCallback) =>
            openConfModal(title, content, successCallback)
          }
        />
      )}

      {modal}
    </>
  );
}

const mapStateToProps = state => {
  return {
    auditDMs: state.auditDMs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditDM);
