import {GET_ADMIN} from "../../actions/admin/admin";

export default function admin(state={}, action) {

  switch (action.type) {
    case GET_ADMIN:
      return action.admin;
    default:
      return state;
  }

};
