import React from "react";
import { connect } from "react-redux";
import { updateDeskCategory } from "../../../../actions/settings/deskCategories/admin/deskCategories";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../../util/Util";
import Icon from '../../../sub/Icon.js';

class DeskCategoryAssociationsModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      category_id: "",
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  addAssociationCategory() {
    if (Util.emptyString(this.state.category_id) || this.state.disabled) return;

    this.setState({ disabled: true, category_id: "" });

    var successCallback = () => this.setState({ disabled: false });

    let deskCategory = this.props.deskCategories.find((cat) => {
      return this.props.deskCategoryId === cat._id;
    });

    var clonedCategory = Util.shallowClone(deskCategory);
    if (!clonedCategory.relatedCategories)
      clonedCategory.relatedCategories = [];
    clonedCategory.relatedCategories.push(this.state.category_id);

    var data = {
      deskCategoryId: this.props.deskCategoryId,
      updatedField: "relatedCategories",
      updatedValue: clonedCategory.relatedCategories,
    };

    this.props.onupdDeskCategoryAdmin(data, successCallback);
  }

  removeAssoCategory(deskCategory, assoCategoryId) {
    if (this.state.disabled) return;

    var clonedCategory = Util.shallowClone(deskCategory);
    var relatedCategories = clonedCategory.relatedCategories.filter(
      (id) => id !== assoCategoryId
    );

    var successCallback = (patient) => {
      this.setState({ disabled: false });
    };

    var data = {
      deskCategoryId: this.props.deskCategoryId,
      updatedField: "relatedCategories",
      updatedValue: relatedCategories,
    };

    this.props.onupdDeskCategoryAdmin(data, successCallback);
  }

  render() {
    const { deskCategories } = this.props;
    if (!deskCategories) return null;

    let deskCategory = this.props.deskCategories.find((cat) => {
      return this.props.deskCategoryId === cat._id;
    });

    if (!deskCategory) return null;

    var deskCategoriesNode = deskCategories.map((p) => {
      if (p._id !== this.props.deskCategoryId) {
        var disabled = false;
        if (deskCategory.relatedCategories)
          disabled = deskCategory.relatedCategories.find(
            (pId) => p._id === pId
          );
        return (
          <option value={p._id} key={p._id} disabled={disabled}>
            {p.name}
          </option>
        );
      } else {
        return null;
      }
    });

    var deskCategoriesAssociationNodes = deskCategory.relatedCategories.map(
      (id) => {
        var assoCategory = deskCategories.find((p) => p._id === id);
        if (!assoCategory) return null;
        return (
          <tr
            key={"assoCategory-" + assoCategory._id}
            className="order-tr order-tr tablelist row-striped"
          >
            <td className="align-middle">{assoCategory.name}</td>
            <td className="tdaction text-center align-middle">
              <Icon
                icon="trash"
                clickable
                size="xl"
                onClick={(e) =>
                  this.removeAssoCategory(deskCategory, assoCategory._id)
                }
              />
            </td>
          </tr>
        );
      }
    );

    return (
      <React.Fragment>
        <div className="col-12 col-lg-9 col-xl-8">
          <div className="form-group row d-flex align-items-center">
            <label
              htmlFor="category_id"
              className="col-12 col-md-5 col-form-label"
            >
              <FormattedMessage id="Choose.Caterory" />
            </label>
            <div className="col-11 col-md-6">
              <select
                className="form-control d-inline"
                id="addAssociationCategory"
                value={this.state.category_id}
                onChange={(e) => this.onChange("category_id", e.target.value)}
              >
                <option value=""></option>
                {deskCategoriesNode}
              </select>
            </div>

            <div className="col-1 text-left align-left float-left p-0">
              <Icon
                icon="square-plus"
                clickable
                size="xl"
                className="text-success"
                onClick={() => this.addAssociationCategory()}
                disabled={
                  Util.emptyString(this.state.category_id) ||
                  this.state.disabled
                }
              />
            </div>
          </div>
        </div>
        {/* {(patient && patient.helpers_ids && patient.helpers_ids.length > 0) && */}
        <table className="table tablee4mad mt-3">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Category.Associated" />
              </th>
              <th className="col-2 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{deskCategoriesAssociationNodes}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onupdDeskCategoryAdmin: (deskCategory, successCallback) =>
      dispatch(updateDeskCategory(deskCategory, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeskCategoryAssociationsModalAdmin));
