import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ShippingCostSettingAdminRow from "./ShippingCostSettingAdminRow";
import ShippingModalAdmin from './ShippingModalAdmin';
import ConfirmationModal from '../../../sub/modals/ConfirmationModal';

class ShippingCostSettingAdmin extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            modal: null,
        };
    }

    openShippingModal(index, successCallback) {
        this.setState({
            modal: <ShippingModalAdmin
                isOpen={true}
                index={index}
                shippingCosts={this.props.ordersSettingsAdmin.shipping_costs}
                successCallback={successCallback}
                closeModal={() => this.closeModal()} />
        });
    }

    openConfModal(title, content, successCallback){
        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={title}
                content={content}
                successCallback={successCallback}
                closeModal={() => this.closeModal()} />
        });
    }

    closeModal(){
        this.setState({modal: null});
    }

    render() {
        var index=-1;
        let shippingCostNode = this.props.ordersSettingsAdmin.shipping_costs.map((shippingCost) => {
            return <ShippingCostSettingAdminRow
                        index={++index}
                        key={index}
                        shippingCost={shippingCost}
                        shippingCosts={this.props.ordersSettingsAdmin.shipping_costs}
                        openConfModal={(title, content, successCallback) => this.openConfModal(title, content, successCallback)}
                        openShippingModal={(index, successCallback) => this.openShippingModal(index, successCallback)}/>;

        });

        return (
          <React.Fragment>
            {this.state.modal}
            <table className="table table-striped tablee4mad col-12">
                <thead>
                    <tr>
                        <th>
                            <span className="d-sm-none"><FormattedHTMLMessage id="Min.Max" /></span>
                            <span className="d-none d-sm-table-cell"><FormattedMessage id="Minimum" /></span>
                        </th>
                        <th className="d-none d-sm-table-cell"><FormattedMessage id="Maximum" /></th>
                        <th><FormattedMessage id="Shipping.Cost.Excl.Tax" /></th>
                        <th><FormattedMessage id="Actions" /></th>
                    </tr>
                </thead>
                <tbody>
                  {shippingCostNode}
                </tbody>
            </table>
          </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingCostSettingAdmin);
