import {GET_COMPANY} from "../../actions/company/company";

export default function company(state={}, action) {

  switch (action.type) {
    case GET_COMPANY:
      return action.company;
    default:
      return state;
  }

};