import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    hasLogo: PropTypes.bool.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
};

const defaultProps = {
    hasLogo: false,
    imgSrc: "",
    imgAlt: ""
};

/**
 * Client logo item for user navbar
 * @param {Boolean} hasLogo detect if customer has company logo into general settings
 * @param {String} imgSrc source link to display image
 * @param {String} imgAlt alternative text if image not displayed
 */
class MenuItemClientLogo extends React.Component {
    render() {
        const {hasLogo, imgSrc, imgAlt} = this.props
        return (
            <React.Fragment>
                <li className="nav-item mx-auto d-none d-lg-block">
                    {hasLogo && 
                        <img src={imgSrc} alt={imgAlt} className="clientLogo" />
                    }
                </li>
            </React.Fragment>
        );
    }
}

MenuItemClientLogo.propTypes = propTypes;
MenuItemClientLogo.defaultProps = defaultProps;

export default MenuItemClientLogo;
