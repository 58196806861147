import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import AddPatientEquipmentModal from './AddPatientEquipmentModal';
import { updPatientAdmin } from "../../actions/patients/patients";
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import DateUtil from '../../util/DateUtil';
import EqSellModes from '../../enums/EqSellModes';
import Roles from '../../enums/Roles';
import ActionMenu from "../sub/ActionMenu";
import MaintenancesModal from "../maintenance/MaintenancesModal";
import Interventions from '../intervention/Interventions';
import { Alert } from 'react-bootstrap';
import TableToolbar from "../sub/bootstrap/TableToolbar";

class PatientEquipments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };
    }

    openModal(patient, equipment) {
        this.setState({
            modal: <AddPatientEquipmentModal
                patient={patient}
                equipment={equipment}
                close={() => this.closeModal()} />
        });
    }

    openInterventions(patient, equipment) {
        this.setState({
            modal: (
                <Interventions
                    equipment={equipment}
                    patient={patient}
                    closeModal={() => this.closeModal()}
                />
            )
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    delete(patient, equipment) {
        if (!patient || !equipment) return;

        const onConfirm = () => {
            const updatedPatient = {
                _id: patient._id,
                equipments: patient.equipments,
            };

            updatedPatient.equipments = updatedPatient.equipments.filter(e => e._id !== equipment._id);

            // Send to BE
            this.props.onUpdPatientAdmin(updatedPatient);
        }

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={<FormattedMessage id="Confirm" />}
                content={<FormattedMessage id="Confirm.Equipment.Removal" />}
                successCallback={onConfirm}
                closeModal={() => this.closeModal()} />
        });
    }

    getAuditEquipment(equipmentId) {
        const { auditEquipments } = this.props;

        const equipment = auditEquipments.find(e => e._id === equipmentId);

        return equipment;
    }

    renewalDateAlert(renewalDate) {

        if (renewalDate === null) {
            return "info";
        }

        const expirationWarningInMonths = 2;
        const todayIsoDate = DateUtil.DateTime().now().toISO();
        const warningDate = DateUtil.removeMonth(renewalDate, expirationWarningInMonths);

        // Renewal date expired
        if (DateUtil.DateTime().fromISO(renewalDate) < DateUtil.DateTime().fromISO(todayIsoDate)) {
            return "danger";
        }

        // Renewal date will expire within two months
        if (DateUtil.DateTime().fromISO(warningDate) < DateUtil.DateTime().fromISO(todayIsoDate)) {
            return "warning";
        }

        // Not expired, expiration will be in more than two months
        return "success";
    }

    // Calculate renew date
    renewalDate(equipment) {

        // Get stored date
        let date = equipment.last_prescription_date;

        // Get concerned equipment to know duration
        const auditEquipment = this.getAuditEquipment(equipment.audit_equipment_id);

        if (!auditEquipment) return null;

        // Calculate duration in SELL mode
        if (equipment.mode === EqSellModes.SALE) {
            if (parseInt(auditEquipment.purchase_renewal) !== 0) {
                date = DateUtil.addYear(date, auditEquipment.purchase_renewal);
            }
            else {
                date = null;
            }
        }

        // Calculate duration in LEASING mode
        else if (equipment.mode === EqSellModes.LEASING) {
            // date = new Date(date.setMonth(date.getMonth() + parseInt(equipment.rental_renewal)));
            date = DateUtil.addMonth(date, equipment.rental_renewal);
        }

        return date;
    }

    openMaintenancesModal(equipment, patient) {
        // Quick patch to fix body while waiting to completely redo the interface
        // (Try to fix bug of difficulty to add a signature when multiple modals are opened)
        document.body.classList.add("modal-fixed");
        this.setState({
            modal: (
                <MaintenancesModal
                    equipment={equipment}
                    patient={patient}
                    closeModal={() => this.closeModal()}
                />
            )
        });
    }

    render() {
        const { patient } = this.props;

        const { user } = this.props;
        const { role } = user;

        if (role === undefined) return null;
        if (!patient) return null;

        var patientEquipmentsNode = null;
        if (patient.equipments) {
            patientEquipmentsNode = patient.equipments.map((h) => {
                var auditEquipment = this.getAuditEquipment(h.audit_equipment_id);
                var renewalDate = this.renewalDate(h);

                let menuItems = [];

                if (role === Roles.ADMIN || role === Roles.SALES_REP)
                    menuItems.push({
                        id: null,
                        icon: "pen-to-square",
                        disabled: false,
                        action: () => this.openModal(patient, h),
                        text: <FormattedMessage id="Modify" />
                    });

                menuItems.push({
                    id: null,
                    icon: "wrench",
                    // Allow maintenance only if DM has checkpoints
                    disabled: !(auditEquipment && auditEquipment.checkpoints && auditEquipment.checkpoints.length),
                    action: () => this.openMaintenancesModal(h, patient),
                    text: < FormattedMessage id="Maintenance" />
                });

                menuItems.push({
                    id: null,
                    icon: "list",
                    // Allow maintenance only if DM has checkpoints
                    disabled: !(auditEquipment),
                    action: () => this.openInterventions(patient, h),
                    text: < FormattedMessage id="Intervention" />
                });

                if (role === Roles.ADMIN || role === Roles.SALES_REP)
                    menuItems.push({
                        id: null,
                        icon: "trash",
                        disabled: false,
                        action: () => this.delete(patient, h),
                        text: <FormattedMessage id="Delete" />
                    });

                let menuAction = <ActionMenu variant={this.renewalDateAlert(renewalDate)} items={menuItems} />;

                return (
                    <tr key={"equipment-" + h._id} className={"order-tr order-tr"} >
                        <td className="d-md-table-cell mw-200 align-middle">{(auditEquipment) && auditEquipment.name}</td>
                        <td className="d-md-table-cell mw-200 align-middle">{this.props.intl.formatMessage({ id: "EqSellMode." + h.mode })}</td>
                        <td className="d-none d-md-table-cell mw-200 align-middle">{DateUtil.DateTime().fromISO(h.last_prescription_date).isValid ? DateUtil.DateTime().fromISO(h.last_prescription_date).toLocaleString() : <FormattedMessage id={"None.Feminine"} />}</td>
                        <td className="d-none d-md-table-cell mw-200 align-middle">{DateUtil.DateTime().fromISO(h.first_install_date).isValid ? DateUtil.DateTime().fromISO(h.first_install_date).toLocaleString() : <FormattedMessage id={"None.Feminine"} />}</td>
                        <td className="d-none d-md-table-cell mw-200 align-middle">
                            <Alert variant={this.renewalDateAlert(renewalDate)}>{DateUtil.DateTime().fromISO(renewalDate).isValid ? DateUtil.DateTime().fromISO(renewalDate).toLocaleString() : < FormattedMessage id="Non.Applicable" />}</Alert>
                        </td>
                        <td className="col-1">
                            {menuAction}
                        </td>
                    </tr>
                );
            });
        }

        return (
            <React.Fragment>

                {(role === Roles.ADMIN || role === Roles.SALES_REP) && <TableToolbar>
                    <button className="btn btn-info ml-auto" onClick={(e) => this.openModal(patient)} >
                        <FormattedMessage id="Patient.Equipment.Add" />
                    </button>
                </TableToolbar>}

                {
                    (role !== Roles.CLIENT && (!patient || !patient.equipments || patient.equipments.length <= 0)) &&
                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id="Empty.Patient.Equipments" />
                    </div>
                }

                {
                    (role === Roles.CLIENT && (!patient || !patient.equipments || patient.equipments.length <= 0)) &&
                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id="Client.Empty.Patient.Equipments" />
                    </div>
                }

                {
                    (patient && patient.equipments && patient.equipments.length > 0) &&
                    <table className="table tablee4mad mt-3">
                        <thead>
                            <tr className="tablerowhead">
                                <th className="d-md-table-cell"><FormattedMessage id="Equipment" /></th>
                                <th className="d-md-table-cell"><FormattedMessage id="Mode" /></th>
                                <th className="d-none d-md-table-cell"><FormattedMessage id="Last.Prescription.Date" /></th>
                                <th className="d-none d-md-table-cell"><FormattedMessage id="First.Install.Date" /></th>
                                <th className="d-none d-md-table-cell"><FormattedMessage id="Next.Renewal.Date" /></th>
                                {(role === Roles.ADMIN || role === Roles.SALES_REP) &&
                                    <th className="d-none d-md-table-cell"><FormattedMessage id="Actions" /></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {patientEquipmentsNode}
                        </tbody>
                    </table>
                }

                {this.state.modal}

            </React.Fragment >
        );
    }

}

const mapStateToProps = state => {
    return {
        auditEquipments: state.auditDMs,
        user: state.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdPatientAdmin: (patient, successCallback) => dispatch(updPatientAdmin(patient, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientEquipments));
