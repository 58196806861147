import Util from './Util';

export default class Maths {

    static round(nbr, decimals = 2) {
        return Math.round(nbr * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }

    // https://codesips.io/check-for-integer-and-float-in-javascript/
    static isInt(num) {
        return (Util.typeOf(num) === 'Number') && (isFinite(num)) && (Math.floor(num) === num);
    }

    // https://codesips.io/check-for-integer-and-float-in-javascript/
    static isFloat(num) {
        return (Util.typeOf(num) === 'Number') && (isFinite(num)) && (num % 1 !== 0);
    }

}
