import {GET_PANIER} from "../../actions/cart/cart";

export default function cart(state=[], action) {

  switch (action.type) {
    case GET_PANIER:
      return action.cart;
    default:
      return state;
  }

};
