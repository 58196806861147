import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PatientModal from './PatientModal';
import Paginator from '../sub/Paginator';
import PatientRow from './PatientRow';
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import { deletePatient, importPatients, getPatientEquipmentsReminded } from '../../actions/patients/patients';
import Roles from '../../enums/Roles'
import Util from '../../util/Util';
import CustomLabel from "../sub/CustomLabel";
import { Button, Dropdown } from "react-bootstrap";
import ImportPatientColumnsModal from "./admin/ImportPatientColumnsModal";
import ImportPatientFileModal from "./admin/ImportPatientFileModal";
import ExcelUtil from "../../util/ExcelUtil";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Icon from '../sub/Icon.js';
import MenuButton from "../sub/bootstrap/MenuButton";
import PatientsEquipmentsRemindedModal from './PatientsEquipmentsRemindedModal.js';
class Patients extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,

            // Search filters
            nameFilter: "",
            addressFilter: "",
            patientIsActiveFilter: "active",
            auditRequestedFilter: "",
            patientAccessFilter: "",
            remindedEquipments: [],
            isReminderModal: false
        };

        this.paginator = new Paginator(this);
    }

    componentDidMount() {
        if (this.props.user.role !== Roles.ADMIN || this.props.prescriberId || this.props.clientType === "patient") return;

        this.props.onGetPatientEquipmentsReminded((data) => {
            this.setState({ remindedEquipments: data });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user.role !== Roles.ADMIN || this.props.prescriberId || this.props.clientType === "patient") return;

        if (prevProps.patients !== this.props.patients) {
            const callback = () => {
                if (this.state.modal !== null && this.state.isReminderModal) this.openRemindedEquipmentsModal(this.state.remindedEquipments);
            }
            this.props.onGetPatientEquipmentsReminded((data) => {
                this.setState({ remindedEquipments: data }, callback);
            });
        }
    }

    openModal(patient, target) {
        this.setState({ modal: null },
            () => this.setState({
                modal: <PatientModal
                    isOpen={true}
                    patientId={(patient) ? patient._id : null}
                    openModal={(patient, target) => this.openModal(patient, target)}
                    close={() => this.closeModal()}
                    target={target}
                />
            })
        );
    }

    openConfModal(title, content, successCallback) {
        this.setState({
            modal: <ConfirmationModal
                title={title}
                content={content}
                context="danger"
                successCallback={successCallback}
                closeModal={() => this.closeModal()} />
        });
    }

    delete(patient) {
        if (!patient) return;

        const title = <FormattedMessage id="Confirm" />
        const content = <FormattedMessage id="Confirm.Patient.Removal" />

        this.openConfModal(title, content, () => this.props.onDeletePatient(patient._id));
    }

    closeModal() {
        this.setState({ modal: null });
    }

    areResultsFiltered = () => {
        if (
            !Util.emptyString(this.state.nameFilter) ||
            !Util.emptyString(this.state.addressFilter) ||
            this.state.patientIsActiveFilter !== "all" ||
            this.state.auditRequestedFilter !== "" ||
            this.state.patientAccessFilter !== ""
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    resetSearchFields() {
        this.setState({
            nameFilter: "",
            addressFilter: "",
            patientIsActiveFilter: "all",
            auditRequestedFilter: "",
            patientAccessFilter: "",
        });
    }

    // Reset all previous import params in state when aborting import process
    abortAndCloseModal() {
        this.setState({
            fileName: null,
            fileData: null,
        });

        this.closeModal();
    }

    // Step 1 : Import excel file
    initStep1(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            mode: "create",
            modal: (
                <ImportPatientFileModal
                    closeModal={() => this.abortAndCloseModal()}
                    onComplete={(fileData) =>
                        this.initStep2(fileData)}
                />
            )
        });
    }

    // Step 2 : File column mapping and data validation
    initStep2(file) {
        // Parse the excel file and then move on to next modal
        ExcelUtil.parse(file, fileData => {
            // Save the passed params
            this.setState({
                fileName: file.name,
                fileData: fileData,
            });

            // Close current modal
            this.closeModal();

            // Open next step modal
            this.setState({
                modal: (
                    <ImportPatientColumnsModal
                        closeModal={() => this.abortAndCloseModal()}
                        fileData={fileData}
                        onComplete={(columns) => this.initStep3(columns)}
                    />
                )
            });
        });
    }

    // Step 3 : Send data to the backend
    initStep3(columns) {

        this.setState({
            columns: columns
        });

        let successCallback;

        successCallback = () => {

            // Close current modal
            this.closeModal();

            // Notification
            let errorMessage = this.props.intl.formatMessage({
                id: "Import.Successful"
            });

            NotificationManager.success(errorMessage);

        };

        // Send the patients to the BE

        // First, fix data with correct columns
        const patients = this.fixPatientsCols(this.state.fileData, columns);

        this.props.onImportPatients({
            fileName: this.state.fileName,
            patients: patients
        }, successCallback);
    }

    // Perform checks on columns <-> data associations for patients
    fixPatientsCols(patients, columns) {
        let newPatients = [];
        for (let e of patients) {
            var newPatient = {};
            for (let col of Object.keys(columns)) newPatient[col] = e[columns[col]];
            newPatients.push(newPatient);
        }

        return newPatients;
    }

    getClientPatients(patient) {
        if (this.props.clientType === "prescriber") return patient.prescribers_ids.includes(this.props.clientId);
        if (this.props.clientType === "helper") return patient.helpers_ids.includes(this.props.clientId);
        if (this.props.clientType === "patient") return patient.user_id.includes(this.props.clientId);
    }

    openRemindedEquipmentsModal(remindedEquipments) {
        const closeModal = () => {
            this.closeModal();
            this.setState({isReminderModal: false});
        }

        this.setState({isReminderModal: true, modal: <PatientsEquipmentsRemindedModal equipments={remindedEquipments} close={closeModal} />})
    }

    render() {
        let patients = this.props.clientId ? this.props.patients.filter((patient) => this.getClientPatients(patient)) : this.props.patients;

        if (this.props.isAssignModal) patients = this.props.patients.filter((patient) => !patient.helpers_ids.includes(this.props.clientId) && !patient.prescribers_ids.includes(this.props.clientId) && !patient.user_id.includes(this.props.clientId));

        var dropDownButtons = (
            <Dropdown>
                <Dropdown.Toggle
                    variant="info"
                    id="dropdownMenuLink"
                >
                    <FormattedMessage id="Actions" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {!this.props.clientId &&
                        <>
                            <Dropdown.Item
                                onClick={(e) => this.openModal()}
                            >
                                <Icon icon="circle-plus" className={"mr-2"} />
                                <FormattedMessage id="Patient.Add" />
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={(e) => this.initStep1(e)}>
                                <Icon icon="upload" className={"mr-2"} />
                                <FormattedMessage id="Import.Patients" />
                            </Dropdown.Item>
                        </>}

                    {this.props.clientId &&
                        <Dropdown.Item
                            onClick={(e) => this.props.openAssignModal()}
                        >
                            <Icon icon="circle-plus" className={"mr-2"} />
                            <FormattedMessage id="Assign.Patient" />
                        </Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        );

        // No patients
        if (!patients || patients.length === 0) {
            return (
                <React.Fragment>
                    <div className="col-12">

                        <div className="row search-filters">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        {/* <h5 className="card-title">Recherche</h5> */}
                                        <div className="form-inline">
                                            {this.props.user.role === Roles.CLIENT && <div className="alert alert-info w-100" role="alert">
                                                <FormattedMessage id="Empty.Patient.Prescriber" />
                                            </div>}
                                            {this.props.user.role === Roles.ADMIN && <div className="alert alert-info w-100" role="alert">
                                                <FormattedMessage id="Empty.Patient" />
                                            </div>}
                                            {this.props.user.role === Roles.SALES_REP && <div className="alert alert-info w-100" role="alert">
                                                <FormattedMessage id="Empty.Patient.Collaborator" />
                                            </div>}

                                            {this.props.user.role === Roles.ADMIN && !this.props.isAssignModal && dropDownButtons}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.modal}

                </React.Fragment>
            );
        }

        this.paginator.init();

        let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

        var i = 0;
        let patientsNode = patients.map((patient) => {

            if (this.props.isAssignModal && patient && patient.helpers_ids && patient.helpers_ids.length > 0) {
                if (patient.helpers_ids.includes(this.props.clientId)) return null;
            }

            if (this.props.isAssignModal && patient && patient.prescribers_ids && patient.prescribers_ids.length > 0) {
                if (patient.prescribers_ids.includes(this.props.clientId)) return null;
            }

            if (this.state.nameFilter && this.state.nameFilter !== "") {
                if ((patient.name.toUpperCase().indexOf(this.state.nameFilter.toUpperCase()) === -1) &&
                    (patient.first_name.toUpperCase().indexOf(this.state.nameFilter.toUpperCase()) === -1)) return null;
            }

            if (this.state.addressFilter && this.state.addressFilter !== "") {
                if (
                    patient.address.toUpperCase().indexOf(this.state.addressFilter.toUpperCase()) === -1 &&
                    patient.postal_code.toString().toUpperCase().indexOf(this.state.addressFilter.toUpperCase()) === -1 &&
                    patient.city.toUpperCase().indexOf(this.state.addressFilter.toUpperCase()) === -1) return null;
            }

            let displayPatientIsActiveFilter;
            switch (true) {
                case (this.state.patientIsActiveFilter === "active" && !patient.active):
                case (this.state.patientIsActiveFilter === "inactive" && patient.active):
                    displayPatientIsActiveFilter = true;
                    break;
                default:
                    displayPatientIsActiveFilter = false;
                    break;
            }

            const auditRequested = (Util.typeOf(patient.new_audit_request) !== "Undefined" && patient.new_audit_request.hasOwnProperty('status')) ? patient.new_audit_request.status : false;
            const auditRequestedFilter = (this.state.auditRequestedFilter !== "" && (this.state.auditRequestedFilter !== auditRequested));

            if (this.state.patientAccessFilter !== "") {
                if (this.state.patientAccessFilter && patient.user_id.length === 0) return null;
                if (!this.state.patientAccessFilter && patient.user_id.length > 0) return null;
            }

            if (displayPatientIsActiveFilter || auditRequestedFilter) {
                return null;
            }

            if (this.props.limit && ++i > this.props.limit) return null;

            if (!this.paginator.keep()) return null;

            return (
                <PatientRow
                    key={patient._id}
                    patient={patient}
                    edit={(patient) => this.openModal(patient, "general")}
                    delete={(patient) => this.delete(patient)}
                    displaysEquipmentModal={(patient) => this.openModal(patient, "equipments")}
                    displaysPrescribersModal={(patient) => this.openModal(patient, "prescribers")}
                    displaysHelpersModal={(patient) => this.openModal(patient, "helpers")}
                    openConfModal={(title, content, successCallback) => this.openConfModal(title, content, successCallback)}
                    audits={(this.props.audits)}
                    clients={this.props.clients}
                    limit={this.props.limit && true}
                    isAssignModal={this.props.isAssignModal}
                    addClient={(clientId) => this.props.addClient(clientId)}
                    limitInfoAssociatedPatients={this.props.limitInfoAssociatedPatients}
                />
            );
        });

        return (
            <React.Fragment>
                {/** BEGIN SEARCH FILTERS */}
                {!this.props.limit && this.props.user.client_type !== "patient" && <div className="row search-filters">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-inline">
                                    <input id="search_name" type="text" className="form-control form-control-sm mr-sm-3" placeholder={this.props.intl.formatMessage({ id: "Name" }) + ' ' + this.props.intl.formatMessage({ id: "Or" }) + ' ' + this.props.intl.formatMessage({ id: "First.Name" })} onChange={(e) => { this.setState({ nameFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.nameFilter} />
                                    {!this.props.limitInfoAssociatedPatients && <input id="search_address" type="text" className="form-control form-control-sm mr-sm-3" placeholder={this.props.intl.formatMessage({ id: "Address" })} onChange={(e) => { this.setState({ addressFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.addressFilter} />}
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Status" })}
                                        htmlFor="patient-status-filter"
                                        labelClassName="my-1 mr-2" />
                                    <select id="patient-status-filter" className="form-control form-control-sm mr-sm-3"
                                        value={this.state.patientIsActiveFilter}
                                        onChange={(e) => this.setState({ patientIsActiveFilter: e.target.value })} disabled={disableFormInput}>
                                        <option value={"all"}>{this.props.intl.formatMessage({ id: "All" })}</option>
                                        <option value={"active"}>{this.props.intl.formatMessage({ id: "Active" })}</option>
                                        <option value={"inactive"}>{this.props.intl.formatMessage({ id: "Inactive" })}</option>
                                    </select>
                                    {!this.props.limitInfoAssociatedPatients && <><CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Audit.Requested" })}
                                        htmlFor="patient-new-audit-request-filter"
                                        labelClassName="my-1 mr-2" />
                                    <select id="patient-new-audit-request-filter" className="form-control form-control-sm mr-sm-3"
                                        value={this.state.auditRequestedFilter}
                                        onChange={(e) => this.setState({ auditRequestedFilter: e.target.value === "" ? e.target.value : JSON.parse(e.target.value) })} disabled={disableFormInput}>
                                        <option value={""}>-</option>
                                        <option value={true}>{this.props.intl.formatMessage({ id: "Yes" })}</option>
                                        <option value={false}>{this.props.intl.formatMessage({ id: "No" })}</option>
                                    </select></>}
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Access.E4MAD" })}
                                        htmlFor="patient-access-filter"
                                        labelClassName="my-1 mr-2" />
                                    <select id="patient-access-filter" className="form-control form-control-sm mr-sm-3"
                                        value={this.state.patientAccessFilter}
                                        onChange={(e) => this.setState({ patientAccessFilter: e.target.value === "" ? e.target.value : JSON.parse(e.target.value) })} disabled={disableFormInput}>
                                        <option value={""}>-</option>
                                        <option value={true}>{this.props.intl.formatMessage({ id: "Yes" })}</option>
                                        <option value={false}>{this.props.intl.formatMessage({ id: "No" })}</option>
                                    </select>
                                    <MenuButton
                                        onClick={() => this.resetSearchFields()}
                                        hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                                        variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                                        icon="filter"
                                        disabled={!this.areResultsFiltered() || disableFormInput}
                                    />
                                    <div className={"form-inline ml-auto"}>
                                        {this.props.user.role === Roles.ADMIN && !this.props.prescriberId && !this.props.isAssignModal  && this.props.clientType !== "patient" && <>
                                            {!this.props.limitInfoAssociatedPatients && this.state.remindedEquipments && this.state.remindedEquipments.length > 0 && <Button className={"mr-3"} variant="warning" onClick={() => this.openRemindedEquipmentsModal(this.state.remindedEquipments)}><Icon icon="bell" /><span className="badge badge-danger">{this.state.remindedEquipments.length}</span></Button>}
                                            {dropDownButtons}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {/** END SEARCH FILTERS */}

                <table className="table tablee4mad">
                    <thead>
                        <tr className="d-flex">
                            {(this.props.user.role === Roles.ADMIN || this.props.user.role === Roles.SALES_REP) &&
                                <React.Fragment>
                                    <th scope="col" className="col"><FormattedMessage id="Name" /> <FormattedMessage id="And" /> <FormattedMessage id="First.Name" /></th>
                                    {!this.props.limitInfoAssociatedPatients && <th scope="col" className="col"><FormattedMessage id="Address" /></th>}
                                    <th scope="col" className="col"><FormattedMessage id="Last.Audit.Date" values={{ er: <sup>er</sup> }} /></th>
                                    {!this.props.limit && !this.props.limitInfoAssociatedPatients && <th scope="col" className="col col-1 text-center"><FormattedMessage id="Prescribers" /></th>}
                                    {!this.props.limit && !this.props.limitInfoAssociatedPatients && <th scope="col" className="col col-1 text-center"><FormattedMessage id="Helpers" /></th>}
                                    <th scope="col" className="col text-center"><FormattedMessage id="Helper.Lead" /></th>
                                    <th scope="col" className="col text-center"><FormattedMessage id="Prescriber.Lead" /></th>
                                    <th scope="col" className="col text-center"><FormattedMessage id="Access.E4MAD" /></th>
                                    {!this.props.limit && <th scope="col" className="col col-1 text-center"><FormattedMessage id="Actions" /></th>}
                                </React.Fragment>
                            }
                            {(this.props.user.role === Roles.CLIENT) &&
                                <React.Fragment>
                                    <th scope="col" className="col"><FormattedMessage id="Name" /> <FormattedMessage id="And" /> <FormattedMessage id="First.Name" /></th>
                                    <th scope="col" className="col"><FormattedMessage id="Address" /></th>
                                    <th scope="col" className="col"><FormattedMessage id="Last.Audit.Date" values={{ er: <sup>er</sup> }} /></th>
                                    <th scope="col" className="col col-2 text-center"><FormattedMessage id="Equipments" /></th>
                                    <th scope="col" className="col col-2 text-center"><FormattedMessage id="Alert.Renewal" /></th>
                                    {!this.props.limit && <th scope="col" className="col col-1 text-center"><FormattedMessage id="Actions" /></th>}
                                </React.Fragment>
                            }
                        </tr>
                    </thead>
                    <tbody>

                        {patientsNode}

                    </tbody>
                </table>

                {!this.props.limit && this.paginator.render()}

                {this.state.modal}

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        patients: state.patients,
        user: state.user,
        audits: state.audits,
        clients: state.clients
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDeletePatient: (patientId) => dispatch(deletePatient(patientId)),
        onImportPatients: (data, successCallback, failureCallback) => dispatch(importPatients(data, successCallback, failureCallback)),
        onGetPatientEquipmentsReminded: (successCallback) => dispatch(getPatientEquipmentsReminded(successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Patients));
