import React from "react";
import { connect } from "react-redux";
// import StringUtil from '../../../../util/StringUtil';
import { FormattedMessage, injectIntl } from "react-intl";
import {
  updateDeskCategory,
  deleteDeskCategory,
} from "../../../../actions/settings/deskCategories/admin/deskCategories";
import Util from "../../../../util/Util";
import Icon from '../../../sub/Icon.js';
import MenuButton from "../../../sub/bootstrap/MenuButton";
import ActionMenu from "../../../sub/ActionMenu";

class DeskCategoriesRowAdmin extends React.Component {
  updateDeskCategory(deskCategoryId, updatedField, updatedValue) {
    //	console.log('updateDeskCategory',deskCategoryId, updatedField, updatedValue);

    if (!deskCategoryId) return;
    if (!this.props.deskCategory) return null;

    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      deskCategoryId: deskCategoryId,
      updatedField: updatedField,
      updatedValue: updatedValue + 1,
    };
    var successCallback = () => {
      var deskCategories = this.props.deskCategories;
      var deskCategoriesAscend = deskCategories.filter(
        (obj) => obj.sortIndex === updatedValue + 1
      );
      data = {
        deskCategoryId: deskCategoriesAscend[0]._id,
        updatedField: updatedField,
        updatedValue: updatedValue,
      };
      this.props.onUpdateDeskCategory(data, () => { });
    };

    this.props.onUpdateDeskCategory(data, successCallback);
  }

  deleteDeskCategory(deskCategoryId, updatedField, updatedValue) {
    if (!deskCategoryId) return;
    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      deskCategoryId: deskCategoryId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    var successCallback = () => {
      var deskCategories = this.props.deskCategories;
      var deskCategoriesAscend = deskCategories.filter(
        (obj) => obj.sortIndex > updatedValue
      );
      deskCategoriesAscend.map((deskCategory) => {
        data = {
          deskCategoryId: deskCategory._id,
          updatedField: updatedField,
          updatedValue: deskCategory.sortIndex - 1,
        };
        this.props.onUpdateDeskCategory(data, () => {
          return null;
        });
        return null;
      });
    };

    //console.log('deleteDeskCategory data',data);

    this.props.onDeleteDeskCategory(data, successCallback);
  }

  render() {
    //var refundable = <FormattedMessage id="No" />;

    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.Category.Remove.Confirmation" />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <Icon icon="triangle-exclamation" className="mr-2 text-white" />
              <FormattedMessage id="Warning" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Category.Delete.Warning.Content" />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    var successCallback = () =>
      this.deleteDeskCategory(
        this.props.deskCategory._id,
        "sortIndex",
        this.props.deskCategory.sortIndex
      );

    var disabled =
      this.props.deskCategory._id.length <= this.props.deskCategory.sortIndex;

    let menuItems = [];

    menuItems.push(
      {
        icon: "arrow-down",
        action: (e) => {
          if (!disabled) {
            this.updateDeskCategory(
              this.props.deskCategory._id,
              "sortIndex",
              this.props.deskCategory.sortIndex
            );
          }
        },
        text: <FormattedMessage id="Retrograde" />,
        disabled: disabled
      },
      {
        icon: "pen-to-square",
        action: () => this.props.openEditModal(this.props.deskCategory),
        text: <FormattedMessage id="Modify" />,
      },
      {
        icon: "trash",
        text: <FormattedMessage id="Delete" />,
        action: () =>
          this.props.openConfModal(
            modalTitle,
            modalContent,
            successCallback
          ),
      },
    );

    const menuAction = (
      <ActionMenu
        items={menuItems}
      />
    );

    return (
      <React.Fragment>
        <tr
          key={this.props.key}
          className="order-tr order-tr tablelist row-striped"
        >
          <td className="align-middle">{this.props.deskCategory.name}</td>
          <td className="text-center">
            <MenuButton
              icon="eye"
              onClick={(e) =>
                this.props.displaysAssociationModal(this.props.deskCategory)
              }
              hover={<FormattedMessage id="Display.Associations" />}
            />
          </td>
          <td className="text-center">
            <MenuButton
              icon="eye"
              onClick={(e) =>
                this.props.displaysProductModal(this.props.deskCategory)
              }
              hover={<FormattedMessage id="Display.Typeproducts" />}
            />
          </td>

          <td className="text-center tdaction align-middle">
            {menuAction}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateDeskCategory: (data, successCallback) => dispatch(updateDeskCategory(data, successCallback)),
    onDeleteDeskCategory: (data, successCallback) => dispatch(deleteDeskCategory(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeskCategoriesRowAdmin));
