import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { deleteMaintenance } from '../../actions/maintenances/maintenances';
import MaintenanceRow from "./MaintenanceRow";
import Paginator from "../sub/Paginator";
import { Button, Modal } from 'react-bootstrap';
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class MaintenancesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null,

            disabled: null,

            collapsed: false,
            displayPDF: false,
            disableExport: false
        };

        this.paginator = new Paginator(this);
    }

    close() {
        this.props.closeModal();
    }

    stopEvent(e) {
        e.stopPropagation();
    }

    closeModal() {
        this.setState({ modal: null });
    }

    updateChildState(data) {
        this.setState(data);
    }

    // DELETE A MAINTENANCE
    deleteMaintenance(maintenance) {
        const onConfirm = () => {
            let data = {
                maintenance: maintenance,
                patientId: this.props.patient._id,
            };
            this.props.onDeleteMaintenance(data);
        }

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                context="danger"
                title={<FormattedMessage id="Warning" />}
                content={<FormattedMessage id="Confirm.Maintenance.Removal" />}
                successCallback={onConfirm}
                closeModal={() => this.closeModal()} />
        });
    }

    render() {

        this.paginator.init();

        var content = (
            <div className="alert alert-secondary" role="alert">
                <FormattedMessage id="Empty.Maintenances" />
            </div>
        );

        var maintenance = [];
        for (var maintenances of this.props.maintenances) {
            if (maintenances.equipment_id === this.props.equipment._id) {
                maintenance.push(maintenances);
            }
        }
        // If there are some maintenances
        if (maintenance.length > 0) {
            var maintenancesNode = maintenance.map(maintenance => {
                if (!this.paginator.keep()) return null;
                return (
                    <MaintenanceRow
                        key={maintenance._id}
                        maintenance={maintenance}
                        patient={this.props.patient}
                        updateParentState={this.updateChildState.bind(this)}
                        //admin={this.props.admin}
                        setModal={(mod) => this.setState({ modal: mod })}
                        equipment={this.props.equipment}
                        closeModal={() => this.closeModal()}
                    />
                );
            });

            content = (
                <React.Fragment>
                    <table className="table tablee4mad">
                        <thead>
                            <tr>
                                <th className="col-7">
                                    <FormattedMessage id="Date" />
                                </th>
                                <th className="text-center col-3">
                                    <FormattedMessage id="State" />
                                </th>
                                <th className="col-2 text-center">
                                    <FormattedMessage id="Actions" />
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {maintenancesNode}
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                onClick={(e) => this.stopEvent(e)}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title><FormattedMessage id="Maintenances.History" /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {content}
                    {this.paginator.render()}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="btn btn-info" onClick={() => this.close()}><FormattedMessage id="Close" /></Button>
                </Modal.Footer>
                {this.state.modal}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        maintenances: state.maintenances
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteMaintenance: maintenance => dispatch(deleteMaintenance(maintenance))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancesModal);
