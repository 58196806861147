import React from "react";
import { connect } from "react-redux";
import PriceUtil from "../../util/PriceUtil";
import {
    updateUrgent,
    increaseQuantity,
    decreaseQuantity,
    deleteCartById,
} from "../../actions/cart/cart";
import { FormattedMessage, injectIntl } from "react-intl";
import MenuButton from "../sub/bootstrap/MenuButton";

class CartRow extends React.Component {
    updateUrgent(e, cartId, urgent) {
        var data = { cartId: cartId, urgent: !urgent };
        this.props.onUpdateUrgent(data);
    }

    increaseQuantity(e, cartId) {
        var data = { cartId: cartId };
        this.props.onIncreaseQuantity(data);
    }

    decreaseQuantity(e, cartId) {
        var data = { cartId: cartId };
        this.props.onDecreaseQuantity(data);
    }

    deleteCartById(e, cartId) {
        var data = { cartId: cartId };
        this.props.onDeleteCartById(data);
    }

    render() {
        if (
            this.props.product === undefined ||
            this.props.product === null ||
            !this.props.product
        )
            return null;

        // 'Delete article' modal setup
        var modalTitle = <FormattedMessage id="Confirm" />;
        var modalContent = (
            <FormattedMessage
                id="Product.Remove.Confirmation"
                values={{ designation: this.props.product.designation }}
            />
        );
        var successCallback = (e) => this.deleteCartById(e, this.props.cart._id);

        return (
            <tr key={this.props.cart._id}>
                <td className="col col-md-auto">{this.props.product.famille}</td>
                <td className="col col-md-auto">{this.props.product.designation}</td>
                <td className="col col-md-auto">
                    {this.props.product.unite_de_vente}
                </td>
                <td className="col col-md-auto">{this.props.product.ref}</td>
                <td className="col col-md-auto">{this.props.cart.quantity}</td>
                <td className="col col-md-auto">
                    {PriceUtil.priceHt(this.props.product, 1, 2)}€
                </td>
                <td className="col col-md-auto">
                    {this.props.product.tva * 100}%
                </td>
                <td className="col col-md-auto">
                    {PriceUtil.priceTtc(this.props.product, 1, 2)}€
                </td>
                <td className="col col-md-auto">
                    {PriceUtil.priceHt(this.props.product, this.props.cart.quantity, 2)}€
                </td>
                <td className="col col-md-auto">
                    {PriceUtil.priceTtc(this.props.product, this.props.cart.quantity, 2)}€
                </td>
                <td className="col col-1 text-center">
                    <div className="mb-1">
                        <MenuButton
                            icon="plus"
                            size="sm"
                            className="mr-1"
                            onClick={
                                this.props.cart.quantity >= 1000
                                    ? ""
                                    : (e) => this.increaseQuantity(e, this.props.cart._id)
                            }
                            hover={<FormattedMessage id="Increment.Product" />}
                        />
                        <MenuButton
                            icon="bell"
                            size="sm"
                            onClick={(e) =>
                                this.updateUrgent(
                                    e,
                                    this.props.cart._id,
                                    this.props.cart.urgent
                                )
                            }
                            variant={this.props.cart.urgent && "warning"}
                            hover={<FormattedMessage id="Order.Urgent" />}
                        />
                    </div>
                    <div>
                        <MenuButton
                            icon="minus"
                            size="sm"
                            className="mr-1"
                            onClick={(e) => this.decreaseQuantity(e, this.props.cart._id)}
                            hover={<FormattedMessage id="Decrement.Product" />}
                            placement="bottom"
                        />
                        <MenuButton
                            icon="trash"
                            size="sm"
                            placement="bottom"
                            onClick={(e) =>
                                this.props.openConfModal(
                                    modalTitle,
                                    modalContent,
                                    successCallback
                                )
                            }
                            hover={<FormattedMessage id="Delete" />}
                        />
                    </div>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUrgent: (data) => dispatch(updateUrgent(data)),
        onIncreaseQuantity: (data) => dispatch(increaseQuantity(data)),
        onDecreaseQuantity: (data) => dispatch(decreaseQuantity(data)),
        onDeleteCartById: (data) => dispatch(deleteCartById(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CartRow));
