import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ConfirmationModal from '../../../../sub/modals/ConfirmationModal.js';
import ErrorModal from '../../../../sub/modals/ErrorModal.js';
import Paginator from '../../../../sub/Paginator';
import Util from '../../../../../util/Util.js';
import CustomLabel from '../../../../sub/CustomLabel.js';
import CollaboratorsTypesRow from './CollaboratorTypesRow.js';
import CollaboratorTypesModal from './CollaboratorTypesModal.js';
import MenuButton from "../../../../sub/bootstrap/MenuButton.js";

class CollaboratorTypesTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            collaboratorTypeFilter: "",
        };
        this.paginator = new Paginator(this);
    }

    openConfModal(title, content, successCallback) {
        this.setState({
            modal: <ConfirmationModal
                isOpen={true}
                title={title}
                content={content}
                successCallback={successCallback}
                closeModal={() => this.closeModal()}
            />
        });
    }

    openErrorModal(title, content) {
        this.setState({
            modal: <ErrorModal
                size="modal-lg"
                isOpen={true}
                title={title}
                content={content}
                closeModal={() => this.closeModal()}
                buttonLabel={<FormattedMessage id="Cancel" />}
            />
        });
    }

    openCollaboratorTypesModal() {
        this.setState({
            modal: <CollaboratorTypesModal
                collaboratorTypes={this.props.collaboratorTypes}
                isOpen={true}
                closeModal={() => this.closeModal()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    areResultsFiltered = () => {
        if (!Util.emptyString(this.state.collaboratorTypeFilter)) {
            return true;
        }
        else {
            return false;
        }
    }

    resetSearchFields() {
        this.setState({
            collaboratorTypeFilter: "",
        });
    }

    render() {
        let { collaboratorTypes, collaborators, intl } = this.props;

        this.paginator.init();
        let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

        let collaboratorTypesRowNode = collaboratorTypes.map((collaboratorType) => {

            if (this.state.collaboratorTypeFilter && this.state.collaboratorTypeFilter !== "") {
                let collaboratorTypeName = collaboratorType.name.toLowerCase();
                if (collaboratorTypeName.indexOf(this.state.collaboratorTypeFilter.toLowerCase()) === -1) return null;
            }

            if (!this.paginator.keep()) return null;

            return <CollaboratorsTypesRow
                key={collaboratorType._id}
                collaboratorType={collaboratorType}
                collaboratorTypes={collaboratorTypes}
                collaborators={collaborators}
                openConfModal={(title, content, successCallback) => this.openConfModal(title, content, successCallback)}
                openErrorModal={(title, content) => this.openErrorModal(title, content)}
                openEditModal={(CollaboratorType) => this.openEditModal(CollaboratorType)} />;
        });

        return (
            <React.Fragment>
                {this.state.modal}
                <div className="row search-filters">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-inline">
                                    <CustomLabel
                                        label={intl.formatMessage({ id: "Collaborators.Types" })}
                                        htmlFor="search_collaboratorType"
                                        labelClassName="my-1 mr-2"
                                    />
                                    <input id="search_collaboratorType" className="form-control col-3 mr-sm-3"
                                        onChange={(e) => { this.setState({ collaboratorTypeFilter: e.target.value }) }}
                                        disabled={disableFormInput}
                                        value={this.state.collaboratorTypeFilter}
                                    />
                                    <MenuButton
                                        onClick={() => this.resetSearchFields()}
                                        hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                                        variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                                        icon="filter"
                                        disabled={!this.areResultsFiltered() || disableFormInput}
                                    />
                                    <button className="btn btn-info ml-auto" onClick={(e) => this.openCollaboratorTypesModal()}>
                                        <FormattedMessage id="Add.Collaborator.Type" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-striped table-bordered col-12 col-md-10 col-xl-6 tablee4mad mt-3">
                    <thead>
                        <tr>
                            <th className=""><FormattedMessage id="Collaborators.Types" /></th>
                            <th className="text-center"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {collaboratorTypesRowNode}
                    </tbody>
                </table>

                <div className="col-12 col-md-10 col-xl-6 mt-3">
                    {this.paginator.render()}
                </div>

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        //
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollaboratorTypesTable));
