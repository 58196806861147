import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import CollaboratorAssignmentsRow from './CollaboratorAssignmentsRow';
import Paginator from '../../../sub/Paginator';
import { Alert } from 'react-bootstrap';

class CollaboratorAssignments extends React.Component {

    constructor(props) {
        super(props);

        this.paginator = new Paginator(this);
    }

    render() {
        if(!this.props.collaborator) return null;

        this.paginator.init();

        const clientsNode = this.props.clients.map((client) => {
            if (this.props.collaborator.prescribers.find((p) => p.prescriber_id === client._id)) {
                if(!this.paginator.keep() || !client) return null;
                return <CollaboratorAssignmentsRow key={client._id} collaborator={this.props.collaborator} clientTypes={this.props.prescriberTypes} client={client} />
            }
            return null;
        });

        if(!this.props.collaborator.prescribers || this.props.collaborator.prescribers.length === 0) return (
            <Alert variant="secondary"><FormattedMessage id="Empty.Client" /></Alert>
        );

        return (
            <React.Fragment>
                <table className="table table-striped col-12 tablee4mad">
                    <thead>
                        <tr>
                            <th className="col-3"><FormattedMessage id="Name" /> <FormattedMessage id="And" /> <FormattedMessage id="First.Name" /></th>
                            <th className="col-3"><FormattedMessage id="Type" /></th>
                            <th className="col-3"><FormattedMessage id="Client.Label" /></th>
                            <th className="text-center tdaction col-3"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientsNode}
                    </tbody>
                </table>

                {this.paginator.render()}

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        clients: state.clients,
        prescriberTypes: state.prescriberTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorAssignments);

