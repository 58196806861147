import APIUrl from "../../../../APIUrl";
import axios from 'axios';
import { getCheckpoints } from '../../../checkpoints/checkpoints';

export const GET_AUDIT_DMS = "GET_AUDIT_DMS";

function getAuditDMsAction(auditDMs) { return { type: GET_AUDIT_DMS, auditDMs: auditDMs } };

export const getAuditDMs = function (successCallback) {
    return function (dispatch) {
        return axios.get(APIUrl.getAuditDMs)
            .then(function (response) {
                if (response.data) dispatch(getAuditDMsAction(response.data));
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const getAuditDMByName = function (name, successCallback) {
    return function (dispatch) {
        return axios.get(APIUrl.getAuditDMByName + name)
            .then(function (response) {
                if (successCallback) return successCallback(response.data);
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const addAuditDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.addAuditDM, data)
            .then(function (response) {
                dispatch(getAuditDMs());
                if (successCallback) return successCallback(response.data);
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const addAuditDMFile = function (auditDMId, roomId, data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.addAuditDMFile + auditDMId + "/" + roomId, data, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(function () {
                dispatch(getAuditDMs());
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const updateAuditDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.updateAuditDM, data)
            .then(function () {
                dispatch(getAuditDMs());
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const deleteAuditDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.deleteAuditDM, data)
            .then(function () {
                dispatch(getAuditDMs());
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const sortCheckpoint = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.sortCheckpoint, data)
            .then(function (response) {
                dispatch(getCheckpoints(
                    () => dispatch(getAuditDMs(
                        () => successCallback()
                    ))
                ));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const addCheckpointToDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.addCheckpointToDM, data)
            .then(function (response) {
                dispatch(getCheckpoints(
                    () => dispatch(getAuditDMs(
                        () => successCallback()
                    ))
                ));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const updateCheckpoint = function (data, successCallback) {
    return function (dispatch) {
        return axios
            .post(APIUrl.updateCheckpoint, data)
            .then(function (response) {
                dispatch(getCheckpoints(
                    () => dispatch(getAuditDMs(
                        () => successCallback()
                    ))
                ));
            })
            .catch(function (err) {
            throw err;
            });
    };
};

export const deleteCheckpointFromDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.deleteCheckpointFromDM, data)
            .then(function (response) {
                dispatch(getCheckpoints(
                    () => dispatch(getAuditDMs(
                        () => successCallback()
                    ))
                ));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const addRoomToDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.addRoomToDM, data)
            .then(function (response) {
                dispatch(getAuditDMs(
                    () => successCallback()
                ));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const deleteRoomFromDM = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.deleteRoomFromDM, data)
            .then(function (response) {
                dispatch(getAuditDMs(
                    () => successCallback()
                ));
            })
            .catch(function (err) {
                throw err;
            });
    };
};