import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { findClient } from "../../../actions/apiMust/apiMust";
import Util from "../../../util/Util";
import CustomLabel from '../../sub/CustomLabel';
import FindCliModal from './FindCliModal';
import ErrorModal from '../../sub/modals/ErrorModal';
import Icon from '../../sub/Icon.js';
import MenuButton from "../../sub/bootstrap/MenuButton";
import { findClientLomaco } from "../../../actions/apiLomaco/apiLomaco";
import { updClient } from "../../../actions/clients/clients";
import { CrmProviders } from "../../../enums/CrmProviders";
class ApiConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crmId: (this.props.client.crm_id && Util.typeOf(this.props.client.crm_id !== "Null")) ? this.props.client.crm_id : "",
            linkedAccount: (this.props.client.crm_id && Util.typeOf(this.props.client.crm_id !== "Null")) ? true : false,
            loading: false,
            modal: null
        };

    }

    onFieldChange(field, value) {
        this.setState({ [field]: value });
    }

    findClient(crmSoftware) {

        this.setState({ loading: true });
        let data = {
            crmId: this.state.crmId
        };

        if (this.props.company.crm.enabled && this.props.company.crm.software === CrmProviders.MUST.software) {
            this.props.onFindClient(data, (result) =>
                this.openModal(crmSoftware, result)
                , (result) =>
                    this.openModal(crmSoftware, result, data));
        } else if (this.props.company.crm.enabled && this.props.company.crm.software === CrmProviders.LOMACO.software) {
            this.props.onLomacoClient(data, (result) =>
                this.openModal(crmSoftware, result), (result) =>
                this.openModal(crmSoftware, result.data, data));
        }
        // Send to BE

    }

    openModal(crmSoftware, data, dataCodeSearch) {
        if (data.code !== 512) {
            this.setState({
                loading: false,
                modal: (
                    <FindCliModal
                        apiData={data}
                        closeModal={() => this.closeModal()}
                        client={this.props.client}
                        crmId={this.state.crmId}
                        linkedAccount={this.state.linkedAccount}
                        onComplete={(crmId) => this.onComplete(crmId)}
                    />
                )
            });
        } else {
            if(data.data){
                if(data.errorMessage === "No client found"){
                    this.props.onUpdatePrescriber({
                        _id: this.props.client._id,
                        updatedField: 'crm_id',
                        updatedValue: ""
                    }, ()=>{});
                }
                this.setState({
                    loading: false,
                    modal: (
                        <ErrorModal
                            closeModal={() => this.closeModal()}
                            content={<><FormattedMessage id={data.data} values={{ crmSoftware: crmSoftware }} /><br></br><code>{JSON.stringify(data.errorMessage)}</code></>}
                            title={<FormattedMessage id="API.CRM.Client.Find.Error.Title" />}
                        />
                    )
                });
            }else{
                this.setState({
                    loading: false,
                    modal: (
                        <ErrorModal
                            closeModal={() => this.closeModal()}
                            content={<FormattedMessage id="API.CRM.Client.Find.Error" values={{ dataCodeSearch: dataCodeSearch.crmId, crmSoftware: crmSoftware }} />}
                            title={<FormattedMessage id="API.CRM.Client.Find.Error.Title" />}
                        />
                    )
                });
            }
            
        }
    }

    closeModal() {
        this.setState({
            modal: null
        });
    }

    onComplete(crmId) {
        this.setState({
            crmId: crmId,
            linkedAccount: crmId !== ""
        });

        return this.closeModal();
    }

    definedClientCode() {
        return (this.state.crmId && Util.typeOf(this.state.crmId === "Number"));
    }

    render() {

        // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
        // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
        const { software: crmSoftware = null } = this.props.company.crm ? this.props.company.crm : {};

        return (
            <React.Fragment>
                <div className="mb-3">

                    {(this.state.linkedAccount) ?
                        <div className="alert alert-success"><Icon icon="circle-check" className="mr-2" /><FormattedMessage id="API.CRM.Client.Linked" values={{ crmSoftware: crmSoftware }} /></div>
                        :
                        <div className="alert alert-info"><Icon icon="info-circle" className="mr-2" /><FormattedMessage id="API.CRM.Client.Link.Info" values={{ crmSoftware: crmSoftware }} /></div>
                    }

                    <div className="col-12 col-lg-12 col-xl-9">

                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Client.Code" })}
                                htmlFor="crmId"
                                labelClassName="col-3"
                            />
                            <div className="form-inline col-9">
                                <input
                                    disabled={this.state.linkedAccount}
                                    type="text"
                                    className="form-control w-50 mr-2"
                                    id="crmId"
                                    value={this.state.crmId}
                                    maxLength={(crmSoftware === CrmProviders.MUST.software) ? 10 : 32}
                                    onChange={e => this.onFieldChange("crmId", e.target.value)}
                                />
                                <MenuButton
                                    disabled={!this.definedClientCode() || this.state.loading}
                                    variant="info"
                                    onClick={() => this.findClient(crmSoftware)}
                                >
                                    {this.state.loading ? <Icon icon="spinner" className={"fa-spin mr-2"} /> : <Icon icon="magnifying-glass" className={"mr-2"} />}
                                    {this.state.loading ? <FormattedMessage id="Plz.Wait" /> : <FormattedMessage id="Verify" />}
                                </MenuButton>
                            </div>
                        </div>

                    </div>

                </div>

                {this.state.modal}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.company,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFindClient: (data, successCallback, failureCallback) =>
            dispatch(findClient(data, successCallback, failureCallback)),
        onLomacoClient: (data, successCallback,failureCallback) =>
            dispatch(findClientLomaco(data, successCallback,failureCallback)),
        onUpdatePrescriber: (data, successCallback) => dispatch(updClient(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ApiConfiguration)
);
