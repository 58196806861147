import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_MAINTENANCES = "GET_MAINTENANCES";

function getMaintenancesAction(maintenances) {
  return { type: GET_MAINTENANCES, maintenances: maintenances };
}

export const getMaintenances = function() {
  return function(dispatch) {
    return axios
      .get(APIUrl.getMaintenances)
      .then(function(response) {
        if (response) dispatch(getMaintenancesAction(response.data));
      })
      .catch(function(err) {
        throw err;
      });
  };
};

// ==================================================================
// =========================== ADMIN ================================
// ==================================================================

export const addMaintenance = function(data, successCallback) {
  return function(dispatch) {
    return axios
      .post(APIUrl.addMaintenance, data)
      .then(function(response) {
        dispatch(getMaintenances());
        if (response) successCallback(response.data);
      })
      .catch(function(err) {
        throw err;
      });
  };
};

export const deleteMaintenance = function(data,successCallback){
  return function(dispatch){
    return axios
      .post(APIUrl.deleteMaintenance,data)
      .then(function(response){
          if(response) dispatch(getMaintenancesAction(response.data))
        if(successCallback) successCallback();
      })
      .catch(function(err) {
        throw err;
      });
  }
}
