import React from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../actions/user/user';
import axios from 'axios';
import APIUrl from '../../APIUrl';
import Util from "../../util/Util";

class Login extends React.Component {

    componentDidMount() {
        // User might have been redirected from another URL (e4coll, e4mad or e4vhp). Get jwt and authenticate
        if (this.props.match.params.jwtToken) {

            var token = this.props.match.params.jwtToken;

            // Set token in headers
            axios.defaults.headers['jwtToken'] = token;
            APIUrl.jwtToken = token;

            // Try to get user to see if token is legit. If so, redirect to homepage
            let that = this;
            this.props.onGetUser(() => {

                // Store JWT token before redirecting in order to allow F5 refresh in the application without having to login again
                sessionStorage.setItem('jwt', token);
                that.props.history.push('/home');

            });
        }
        // If not, redirect to the login app
        else {
            window.location.replace(Util.checkHttps(process.env.REACT_APP_LOGIN_FE_URL));
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetUser: (callback) => dispatch(getUser(callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
