import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '../Icon';
import { connect } from 'react-redux';

class EmailPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            documentsSelected: [],
            documentsNotSelected: this.props.documents,
            prescribersSelected: [],
            helpersSelected: [],
            prescribersNotSelected: this.props.patient.prescribers_ids,
            helpersNotSelected: this.props.patient.helpers_ids,
            send_mail_patient: false,
            spinner: "fa-spin d-none",
            btnMessage: <FormattedMessage id="Send" />
        };
    }

    scanForm() {
        if (this.state.prescribersSelected.length === 0 && this.state.helpersSelected.length === 0 && !this.state.send_mail_patient) return true;
    }

    sendEmails() {
        this.setState({ spinner: "fa-spin" });
        this.setState({ btnMessage: <FormattedMessage id="Plz.Wait" /> });

        let emailsToSend = [];
        for (let prescriberSelected of this.state.prescribersSelected) {
            if (prescriberSelected && prescriberSelected.email) {
                emailsToSend.push(prescriberSelected.email)
            }
        }
        for (let helperSelected of this.state.helpersSelected) {
            if (helperSelected && helperSelected.email) {
                emailsToSend.push(helperSelected.email)
            }
        }
        if (this.state.send_mail_patient) emailsToSend.push(this.props.patient.email)

        this.props.sendEmails(emailsToSend, this.state.documentsSelected, this.props.audit)
    }

    removeEmail(selected) {
        if (Object.keys(selected)[0] === "prescriber") {
            let prescriber = this.props.prescribers.find(prescriber => prescriber._id === Object.values(selected)[0]);

            if (prescriber && prescriber.email) {
                this.setState(state => ({ prescribersSelected: [...this.state.prescribersSelected, prescriber] }))
            }

            let prescribersSelected = this.state.prescribersSelected
            let indexPres = prescribersSelected.findIndex(prescriberSelected => prescriberSelected._id === Object.values(selected)[0]);
            delete prescribersSelected[indexPres];
            let prescribersSelectedFiltered = prescribersSelected.filter(function (prescriberSelected) {
                return prescriberSelected != null;
            });

            this.setState(state => ({ prescribersSelected: prescribersSelectedFiltered }))
        }

        if (Object.keys(selected)[0] === "helper") {
            let helper = this.props.helpers.find(helper => helper._id === Object.values(selected)[0]);
            
            if(helper && helper.email) {
                this.setState(state => ({ helpersSelected: [...this.state.helpersSelected, helper] }))
            }

            let helpersSelected = this.state.helpersSelected
            let indexHelp = helpersSelected.findIndex(helperSelected => helperSelected._id === Object.values(selected)[0]);
            delete helpersSelected[indexHelp];
            let helpersSelectedFiltered = helpersSelected.filter(function (helperSelected) {
                return helperSelected != null;
            });

            this.setState(state => ({ helpersSelected: helpersSelectedFiltered }))
        }
    }

    pushEmail(selected) {
        if (!Object.values(selected)[0]) return;

        if (Object.keys(selected)[0] === "prescriber") {
            let prescriber = this.props.prescribers.find(prescriber => prescriber._id === Object.values(selected)[0]);

            if (prescriber && prescriber.email) {
                this.setState(state => ({ prescribersSelected: [...this.state.prescribersSelected, prescriber] }))
            }
        }
        if (Object.keys(selected)[0] === "helper") {
            let helper = this.props.helpers.find(helper => helper._id === Object.values(selected)[0]);

            if (helper && helper.email) {
                this.setState(state => ({ helpersSelected: [...this.state.helpersSelected, helper] }))
            }
        }
        if (Object.keys(selected)[0] === "patient") {
            if (this.state.send_mail_patient) {
                this.setState({ send_mail_patient: false });
            } else {
                this.setState({ send_mail_patient: true });
            }
        }
    }

    pushDocument(selected) {
        let documentIsSelected = this.state.documentsSelected.find((d) => {
            return d === selected;
        });
        if (documentIsSelected) return null;

        this.setState((state) => ({
            documentsSelected: [...this.state.documentsSelected, selected],
        }));
    }

    removeDocument(selected) {
        let documentsSelectedFiltered = this.state.documentsSelected.filter((d) => {
            return d !== selected;
        });

        this.setState((state) => ({
            documentsSelected: documentsSelectedFiltered,
        }));
    }

    notSelectOption(item) {
        if (item === undefined) return null;
        return (
            <option key={item._id + "-notselected"} value={item._id} disabled={item.email ? false : true}>
                {item.email ? item.first_name + " " + item.name : item.first_name + " " + item.name + " (Il n'a pas email)"}
            </option>
        );
    }

    selectedOption(item, userType) {
        if (item === undefined) return null;
        return (
            <div key={item._id + "-selected"} className="d-flex justify-content-between divSelected">
                <span>{item.first_name + " " + item.name}</span>
                <Icon icon="circle-xmark" variant="far"
                    onClick={() => { this.removeEmail({ [userType]: item._id }) }}
                />
            </div>
        );
    }

    initNotSelectedNode(itemsNotSelected, itemsSelected) {
        if (itemsSelected === undefined || itemsNotSelected === undefined || itemsNotSelected.length === 0) return null;
        return itemsNotSelected.map(itemNotSelected => {
            if (itemsSelected && itemsSelected !== 0 && itemsSelected.find(itemSelected => itemSelected._id === itemNotSelected._id)) return null;
            let itemInfo = this.props.clients.find(item => item._id === itemNotSelected);
            return this.notSelectOption(itemInfo);
        });
    }

    initSelectedNode(items, userType) {
        if (items === undefined || items.length === 0) return null;
        return items.map(item => {
            if (!item || item.length === 0) return null;
            return this.selectedOption(item, userType);
        });
    }

    displayNodes(itemsSelectedNode, itemsNotSelectedNode, userType, messageId) {
        return (
            <>
                <div className="overflow-auto">
                    {itemsSelectedNode}
                </div>

                <div className="text-center" role="alert">
                    <select className="form-control col-12 mb-2 mt-2 mx-auto"
                        onChange={(e) => { this.pushEmail({ [userType]: e.target.value }) }}>
                        <option value="">{this.props.intl.formatMessage({ id: messageId })}...</option>
                        {itemsNotSelectedNode}
                    </select>
                </div>
            </>
        );
    }

    render() {
        const { patient } = this.props;

        let prescribersNotSelectedNode = this.initNotSelectedNode(this.state.prescribersNotSelected, this.state.prescribersSelected);
        let prescribersSelectedNode = this.initSelectedNode(this.state.prescribersSelected, "prescriber");

        let helpersNotSelectedNode = this.initNotSelectedNode(this.state.helpersNotSelected, this.state.helpersSelected);
        let helpersSelectedNode = this.initSelectedNode(this.state.helpersSelected, "helper");

        if (this.state.documentsNotSelected !== undefined && this.state.documentsNotSelected.length > 0) {
            var documentsNotSelectedNode = this.state.documentsNotSelected.map(
                (documentNotSelected) => {
                    if (
                        this.state.documentsSelected &&
                        this.state.documentsSelected.find(
                            (documentSelected) =>
                                documentSelected._id === documentNotSelected._id
                        )
                    )
                        return null;

                    return (
                        <option
                            key={documentNotSelected._id + "-notselected"}
                            value={documentNotSelected._id}
                        >
                            {documentNotSelected.name}
                        </option>
                    );
                }
            );
        }

        if (this.state.documentsSelected !== undefined && this.state.documentsSelected.length > 0) {
            var documentsSelectedNode = this.state.documentsSelected.map(
                (documentSelected) => {
                    if (!documentSelected || documentSelected.length === 0) return null;
    
                    var documentinfo = this.props.documents.find(
                        (h) => h._id === documentSelected
                    );
    
                    return (
                        <div
                            key={documentinfo._id + "-selected"}
                            className="d-flex justify-content-between divSelected"
                        >
                            <span>{documentinfo.name}</span>
                            <Icon
                                icon="times-circle"
                                onClick={() => {
                                    this.removeDocument(documentinfo._id);
                                }}
                            />
                        </div>
                    );
                }
            );
        }

        return (
            <div className="mx-auto audit-emails text-center">

                <div className="title"><FormattedMessage id="Send.Report.To" /></div>

                <div className={"items text-left"}
                    onClick={() => {this.pushEmail({patient: patient})}}>
                    {(this.state.send_mail_patient) ? <div className="audit-checkbox checked text-center"><div style={{float: "left"}}><Icon icon="check" /></div></div> : <div className="audit-checkbox"></div>}
                    <div className="audit-dm-name"><FormattedMessage id="Patient" /></div>
                </div>

                {this.displayNodes(prescribersSelectedNode, prescribersNotSelectedNode, "prescriber", "Select.Prescribers")}
                {this.displayNodes(helpersSelectedNode, helpersNotSelectedNode, "helper", "Select.Helpers")}

                {this.props.documents && this.props.documents.length ? (
                    <>
                        <div className="overflow-auto">{documentsSelectedNode}</div>

                        <div className="text-center" role="alert">
                            <select
                                className="form-control col-12 mb-2 mt-2 mx-auto"
                                onChange={(e) => {
                                    this.pushDocument(e.target.value);
                                }}
                            >
                                <option value="">
                                    {this.props.intl.formatMessage({ id: "Select.Documents" })}
                                    ...
                                </option>
                                {documentsNotSelectedNode}
                            </select>
                        </div>
                    </>
                ) : null}

                <button
                    type="button"
                    className="btn audit-conf-btn mx-auto mt-5"
                    onClick={() => { this.sendEmails() }}
                    disabled={this.scanForm()}>
                    <Icon icon="spinner" className={this.state.spinner} /> {this.state.btnMessage}
                </button>

                {!this.props.origin &&
                    <p className=" mt-2 text-center text-decoration-none text-primary">
                        <span onClick={() => { this.props.close() }} ><FormattedMessage id="End.Without.Send.Mail" /></span>
                    </p>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        clients: state.clients,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmailPage));