import React from 'react';
import {FormattedMessage} from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import Icon from '../Icon.js';
class ErrorModal extends React.Component {
    close(){
        this.props.closeModal();
    }
    confirm(){
        this.props.successCallback();
        this.close();
    }
    render() {
        const {size, title, content, buttonLabel} = this.props;
        
        return (
            <div className="modal-bg show">
                <Modal
                    show={true}
                    onHide={() => this.close()}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName={"border border-danger " + size}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Icon icon="triangle-exclamation"size="2xl" className="mr-3"/>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {content}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-danger" onClick={() => this.close()}>{buttonLabel ? buttonLabel : <FormattedMessage id="Ok" />}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default ErrorModal;