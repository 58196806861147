import APIUrl from "../../../../APIUrl";
import axios from 'axios';

export const GET_ADMIN_ORDERS_SETTINGS = "GET_ADMIN_ORDERS_SETTINGS";

function getOrdersSettingsAdminAction(ordersSettingsAdmin){return {type: GET_ADMIN_ORDERS_SETTINGS, ordersSettingsAdmin: ordersSettingsAdmin}};

export const getOrdersSettingsAdmin = function(){
    return function(dispatch){
        return axios.get(APIUrl.getOrdersSettingsAdmin)
            .then(function(response){
                dispatch(getOrdersSettingsAdminAction(response.data));
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const updateOrderSettingAdmin = function(data){
    return function(dispatch){
        return axios.post(APIUrl.updateOrderSettingAdmin, data)
            .then(function(response){
                dispatch(getOrdersSettingsAdmin());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const updateShippingCostsSetting = function(data){
    return function(dispatch){
        return axios.post(APIUrl.updateShippingCostsSetting, data)
            .then(function(response){
                dispatch(getOrdersSettingsAdmin());
            })
            .catch(function(err){
                throw(err);
            });
    };
};
