import APIUrl from "../../../APIUrl";
import axios from 'axios';

export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";

function getGeneralSettingsAction(generalSettings){return {type: GET_GENERAL_SETTINGS, generalSettings: generalSettings}};

export const getGeneralSettings = function(){
    return function(dispatch){
        return axios.get(APIUrl.getGeneralSettings)
            .then(function(response){
                if(response) dispatch(getGeneralSettingsAction(response.data));
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const updateGeneralSettings = function(data){
    return function(dispatch){
        return axios.post(APIUrl.updateGeneralSettings, data)
            .then(function(response){
                dispatch(getGeneralSettings());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const addLogo = function(generalSettingsId, data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addLogo + generalSettingsId , data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(function(response){
                dispatch(getGeneralSettings());
                successCallback()
            })
            .catch(function(err){
                throw(err);
            });
    };
};
