
import axios from 'axios';
import ApiURL from "../APIUrl";

export default class PDFUtil{

    static toPDF(generalSettings, company, pdfComponentId, fileName, pathCssFile, successCallback){
        var footerTxt = "";

        if (generalSettings.commercial_sign) {
            footerTxt += generalSettings.commercial_sign;
        } else footerTxt += company.name;
        if (generalSettings.contact_information) footerTxt += " - " + generalSettings.contact_information;
        if (generalSettings.Siret) footerTxt += " - SIRET : " + generalSettings.Siret;
        footerTxt += '</p><p style="color: black; font-style: italic; font-size: 12px; line-height: 0px; margin-top: 20px; width: 100%;">';
        if (generalSettings.phone) footerTxt += " Tel : " + generalSettings.phone;
        if (generalSettings.email) footerTxt += " - " + generalSettings.email;
        if (generalSettings.website) footerTxt += " - Site : " + generalSettings.website;
        if (generalSettings.vatNumber) footerTxt += " - TVA : " + generalSettings.vatNumber;
        

        // Prepare form data
        let data = {
            css: [],
            html: document.getElementById(pdfComponentId).innerHTML,
            zoom: process.env.REACT_APP_PDF_ZOOM,
            header: `
                <div style="width: 90%; font-style: italic; font-size: 12px; text-align: right; margin: 0 auto; border-bottom: 1px solid;">
                    {{page}}/{{pages}}
                </div>`,
            footer: `
                <div style="font-size: 8px; width: 90%; margin: 0 auto; text-align: center; border-top: 1px solid;">
                    <p style="color: black; font-style: italic; font-size: 12px; line-height: 0px; margin-top: 20px; width: 100%;">
                    `+ footerTxt + `
                    </p>
                </div>`
        };

        // Use this to load and add a CSS file to the PDF. You can load as many as you please by chaining the method with itself
        var addCSS = function(url, successCallback){
            axios.get(url)
            .then(function(result){
                // Add CSS to form data
                data.css.push(result.data);

                return successCallback();
            }).catch(function(error){
                throw error;
            });
        };

        var sendToBE = function(){
            axios.post(ApiURL.toPDF, data, {responseType: "arraybuffer"})
            .then(function(result){
                // Save received data into a PDF file
                let pdf = new Blob([result.data], {type:"application/pdf"});

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(pdf);
                link.download = fileName + ".pdf";

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Success callback
                if(successCallback) successCallback();
            }).catch(function(error){
                throw error;
            });
        };

        // Launch the chain
        addCSS(pathCssFile, 
            () => sendToBE()
        );
    }

}
