import { GET_AUDIT_DESK_SETTINGS } from "../../../actions/settings/auditSettings/auditDeskSettings";

export default function auditDeskSettings(state = {}, action) {
  switch (action.type) {
    case GET_AUDIT_DESK_SETTINGS:
      return action.auditDeskSettings;
    default:
      return state;
  }
}
