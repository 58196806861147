import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import OrderRowAdmin from "./OrderRowAdmin";
import ConfirmationModal from '../../sub/modals/ConfirmationModal';
import LoadingModal from '../../sub/modals/LoadingModal';
import SuccessModal from '../../sub/modals/SuccessModal';
import SplitModal from './SplitOrderModalAdmin';
import OrderStatus from '../../../enums/OrderStatus';
import Paginator from '../../sub/Paginator';
import DateUtil from '../../../util/DateUtil';
import StringUtil from '../../../util/StringUtil';
import Util from '../../../util/Util';
import ErrorModal from '../../sub/modals/ErrorModal';
import CustomLabel from "../../sub/CustomLabel";
import { mustGetEtatDossier } from "../../../actions/apiMust/apiMust";
import { lomacoGetEtatDocument } from "../../../actions/apiLomaco/apiLomaco";
import Roles from "../../../enums/Roles";
import MenuButton from "../../sub/bootstrap/MenuButton";
import { CrmProviders } from "../../../enums/CrmProviders";

class OrdersAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            clientFilter: "",
            referenceFilter: "",
            dateStartFilter: "",
            dateEndFilter: "",
            statusFilter: "",
            crm_sync_order_status: this.props.company.crm?.options?.must_allow_sync_order_status === true ? this.props.company.crm?.options?.must_allow_sync_order_status : this.props.company.crm?.options?.lomaco?.allow_sync_order_status === true ? this.props.company.crm?.options?.lomaco?.allow_sync_order_status : false,
        };

        this.paginator = new Paginator(this);
    }

    openSplitModal(order) {
        this.setState({
            modal: <SplitModal isOpen={true}
                order={order}
                closeConfModal={() => this.closeModal()} />
        });
    }

    openErrorModal(title, content, successCallback) {
        this.setState({
            modal: <ErrorModal isOpen={true}
                title={title}
                content={content}
                successCallback={successCallback}
                closeModal={() => this.closeModal()} />
        });
    }

    openConfModal(title, content, successCallback) {
        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={title}
                content={content}
                successCallback={successCallback}
                closeModal={() => this.closeModal()} />
        });
    }

    openSuccessModal(title, content) {
        this.setState({
            modal: <SuccessModal isOpen={true}
                title={title}
                content={content}
                closeModal={() => this.closeModal()} />
        });
    }

    openLoadingModal(title, content, event) {
        this.setState({
            modalnext: <LoadingModal isOpen={true}
                title={title}
                content={content}
                event={event}
                closeModal={() => this.closeModalNext()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    closeModalNext() {
        this.setState({ modalnext: null });
    }

    sortByDateDesc(order1, order2) {
        if (order1.creation_date > order2.creation_date)
            return -1;
        if (order1.creation_date < order2.creation_date)
            return 1;
        return 0;
    }

    getProductsByOrder(order, products) {

        var currentOrderProducts = [];

        for (let op of order.products) {
            for (let p of products) {
                if (op.id_product === p._id) {
                    currentOrderProducts.push(p);
                }
            }
        }

        return currentOrderProducts;
    }

    setStartDateFilter = (date) => {
        this.setState({ dateStartFilter: new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 0, 0, 0, 0) });
    }

    setEndDateFilter = (date) => {
        this.setState({ dateEndFilter: new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 23, 59, 59, 999) });
    }

    areResultsFiltered = () => {
        if (
            !Util.emptyString(this.state.clientFilter) ||
            !Util.emptyString(this.state.referenceFilter) ||
            !Util.emptyString(this.state.dateStartFilter) ||
            !Util.emptyString(this.state.dateEndFilter) ||
            !Util.emptyString(this.state.statusFilter)
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    resetSearchFields() {
        this.setState({
            clientFilter: "",
            referenceFilter: "",
            dateStartFilter: "",
            dateEndFilter: "",
            statusFilter: "",
        });
    }

    syncDossierModal() {
        if (this.props.company.crm.software === CrmProviders.MUST.software) {
            this.openConfModal(
                <FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: CrmProviders.MUST.software }} />,
                <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Content" values={{ crmSoftware: CrmProviders.MUST.software }} />,
                () => {
                    this.openLoadingModal(
                        <FormattedMessage id="Sync.In.Progress" />,
                        <FormattedMessage id="Sync.In.Progress.Warning" />,
                        () => {
                            this.props.onGetEtatDossier(
                                this.props.company._id,
                                () => {
                                    this.closeModalNext();
                                    this.openSuccessModal(
                                        <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Title" />,
                                        <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Content" values={{ crmSoftware: CrmProviders.MUST.software }} />)
                                },
                                (r) => {
                                    this.closeModalNext();
                                    this.openErrorModal(
                                        <FormattedMessage id="API.CRM.Sync.Error" />,
                                        <div>
                                            <p className="alert alert-danger text-justify"><FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Error.Content" /></p>
                                            <div className="alert alert-light text-break">
                                                <p><FormattedMessage id="Error.Detail" />:</p>
                                                <code>{JSON.stringify(r?r.message:<FormattedMessage id="Message.Undefined" />)}</code>
                                            </div>
                                        </div>,
                                        () => { }
                                    )
                                }
                            )
                        }
                    )
                }
            )
        } else if (this.props.company.crm.software === CrmProviders.LOMACO.software) {
            this.openConfModal(
                <FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: CrmProviders.LOMACO.software }} />,
                <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Content" values={{ crmSoftware: CrmProviders.LOMACO.software }} />,
                () => {
                    this.openLoadingModal(
                        <FormattedMessage id="Sync.In.Progress" />,
                        <FormattedMessage id="Sync.In.Progress.Warning" />,
                        () => {
                            this.props.onGetEtatDocument(
                                this.props.company._id,
                                () => {
                                    this.closeModalNext();
                                    this.openSuccessModal(
                                        <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Title" />,
                                        <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Content" values={{ crmSoftware: CrmProviders.LOMACO.software }} />)
                                },
                                () => {
                                    this.closeModalNext();
                                    this.openErrorModal(
                                        <FormattedMessage id="API.CRM.Sync.Error" />,
                                        <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Error.Content" />,
                                        () => { }
                                    )
                                }
                            )
                        }
                    )
                }
            )
        }
    }

    render() {
        if (!this.props.orders || this.props.orders.length === 0) {
            return (
                <React.Fragment>

                    <div className="alert alert-secondary" role="alert">
                        <FormattedMessage id="Admin.Empty.Orders" />
                    </div>

                </React.Fragment>
            );
        }

        // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
        // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
        const { enabled: crmEnabled = false, software: crmSoftware = null } = this.props.company.crm ? this.props.company.crm : {};
        const { must_allow_sync_order_status: mustAllowSyncOrderStatus = false } = this.props.company?.crm?.options ? this.props.company?.crm?.options : {};
        const { allow_sync_order_status: lomacoAllowSyncOrderStatus = false } = this.props.company?.crm?.options?.lomaco ? this.props.company?.crm?.options.lomaco : {};

        let orders = this.props.orders.sort(this.sortByDateDesc);

        this.paginator.init();

        let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

        var i = 0;
        let ordersNode = orders.map((order) => {
            var client;
            for (var c of this.props.clients) {
                if (c._id === order.id_client) {
                    client = c;
                    break;
                }
            }

            if (!client) return null;

            if (this.state.clientFilter && this.state.clientFilter !== "") {
                if (client._id !== this.state.clientFilter) return null;
            }

            if (this.state.referenceFilter && this.state.referenceFilter !== "") {
                if (order.ref.indexOf(this.state.referenceFilter) === -1) return null;
            }

            if (this.state.dateStartFilter && this.state.dateStartFilter !== "") {
                if (new Date(order.creation_date) < new Date(this.state.dateStartFilter)) return null;
            }

            if (this.state.statusFilter && this.state.statusFilter !== "") {
                if (order.status !== Number(this.state.statusFilter)) return null;
            }

            if (order.status === OrderStatus.TBV) return null;

            if (this.props.limit && ++i > this.props.limit) return null;

            if (!this.paginator.keep()) return null;

            return (
                <OrderRowAdmin
                    key={order._id}
                    order={order}
                    products={order.products}
                    productsForCrm={this.getProductsByOrder(order, this.props.products)}
                    mercurials={this.props.mercurials}
                    client={client}
                    openConfModal={(title, content, successCallback) => this.openConfModal(title, content, successCallback)}
                    openSuccessModal={(title, content) => this.openSuccessModal(title, content)}
                    openSplitModal={(order) => this.openSplitModal(order)}
                    openErrorModal={(title, content, successCallback) => this.openErrorModal(title, content, successCallback)}
                    limit={this.props.limit && true}
                />
            );
        });

        var clientsNode = this.props.clients.map(client => {
            return <option key={client._id} value={client._id}>{client.name.toUpperCase()} {StringUtil.ucFirst(client.first_name)}</option>
        });

        var statusNode = Object.values(OrderStatus).map(option => {
            return <option key={option} value={option}>{this.props.intl.formatMessage({ id: "Order.Status." + option })}</option>
        });

        return (
            <React.Fragment>

                {!this.props.limit && <div className="row search-filters">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-inline">
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Client" })}
                                        htmlFor="search_client"
                                        labelClassName="my-1 mr-2" />
                                    <select id="search_client" className="form-control mr-sm-3" onChange={(e) => { this.setState({ clientFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.clientFilter}>
                                        <option value="">{this.props.intl.formatMessage({ id: "All" })}</option>
                                        {clientsNode}
                                    </select>
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Reference" })}
                                        htmlFor="search_reference"
                                        labelClassName="my-1 mr-2" />
                                    <input id="search_reference" className="form-control col-1 mr-sm-3" onChange={(e) => { this.setState({ referenceFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.referenceFilter} />
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Date" })}
                                        htmlFor="search_date"
                                        labelClassName="my-1 mr-2" />
                                    <input id="search_date" className="form-control mr-sm-3" type="date" onChange={(e) => { this.setStartDateFilter(e.target.value) }} disabled={disableFormInput} value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)} />
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Status" })}
                                        htmlFor="search_status"
                                        labelClassName="my-1 mr-2" />
                                    <select id="search_status" className="form-control mr-sm-3" onChange={(e) => { this.setState({ statusFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.statusFilter}>
                                        <option value="">{this.props.intl.formatMessage({ id: "All" })}</option>
                                        {statusNode}
                                    </select>
                                    <MenuButton
                                        onClick={() => this.resetSearchFields()}
                                        hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                                        variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                                        icon="filter"
                                        disabled={!this.areResultsFiltered() || disableFormInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <table className="table tablee4mad">
                    <thead>
                        <tr className="d-flex align-items-center">
                            <th scope="col" className="col col-2"><FormattedMessage id="Client" /></th>
                            <th scope="col" className="col"><FormattedMessage id="Reference" /></th>
                            <th scope="col" className="col"><FormattedMessage id="Date" /></th>
                            <th scope="col" className="col"><FormattedMessage id="Expected.Delivery.Date" /></th>
                            <th scope="col" className="col"><FormattedMessage id="Total.Excl.Tax" /></th>
                            <th scope="col" className="col">
                                <FormattedMessage id="Order.Status" />
                                {(!this.props.limit && this.props.user.role === Roles.ADMIN && crmEnabled && (mustAllowSyncOrderStatus || lomacoAllowSyncOrderStatus) && this.state.crm_sync_order_status) &&
                                    <MenuButton
                                        hover={<FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: CrmProviders.MUST.software }} />}
                                        onClick={() => this.syncDossierModal()}
                                        icon="arrow-right-arrow-left"
                                        className="ml-2 btn-sm"
                                        variant="info"
                                    >
                                        <FormattedMessage id="API.CRM.Sync.Short" />
                                    </MenuButton>
                                }
                            </th>
                            {crmEnabled && <th scope="col">
                                <FormattedMessage id="API.CRM.Sync.Short" values={{ crmSoftware: crmSoftware }} />
                            </th>
                            }
                            {!this.props.limit && <th scope="col" className="col-1 text-center"><FormattedMessage id="Actions" /></th>}
                        </tr>
                    </thead>
                    <tbody>

                        {ordersNode}

                    </tbody>

                </table>

                {!this.props.limit && this.paginator.render()}

                {this.state.modal}
                {this.state.modalnext}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.orders,
        clients: state.clients,
        collaborators: state.collaborators,
        products: state.products,
        mercurials: state.mercurials,
        company: state.company,
        ordersSettingsAdmin: state.ordersSettingsAdmin,
        user: state.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetEtatDossier: (data, successCallback, failureCallback) => dispatch(mustGetEtatDossier(data, successCallback, failureCallback)),
        onGetEtatDocument: (data, successCallback, failureCallback) => dispatch(lomacoGetEtatDocument(data, successCallback, failureCallback)),
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrdersAdmin));
