import APIUrl from "../../APIUrl";
import axios from 'axios';

export const GET_CHECKPOINTS = "GET_CHECKPOINTS";

function getCheckpointsAction(checkpoints){return {type: GET_CHECKPOINTS, checkpoints: checkpoints}};

export const getCheckpoints = function(successCallback){
    return function(dispatch){
        return axios.get(APIUrl.getCheckpoints)
            .then(function(response){
                if(response) dispatch(getCheckpointsAction(response.data));
                if(successCallback) successCallback();
            })
            .catch(function(err){
                throw err;
            });
    };
};
