import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Tab, Tabs } from 'react-bootstrap';

import Role from '../../enums/Roles';
import Util from '../../util/Util';

import UpdateAccountForm from './UpdateAccountForm';
import UpdatePwdForm from './UpdatePwdForm';
import Contacts from './Contacts';

class Account extends React.Component {
    render() {
        const {user, intl, admin, generalSettings} = this.props;

        if (Util.emptyObject(user)) return null;

        return (
            <React.Fragment>
                <Tabs defaultActiveKey="account-infos" id="accountTabs">
                    <Tab eventKey="account-infos" title={intl.formatMessage({ id: "My.Informations" })}>
                        <UpdateAccountForm user={user} />
                    </Tab>

                    <Tab eventKey="account-password" title={intl.formatMessage({ id: "Change.Password" })}>
                        <UpdatePwdForm />
                    </Tab>

                    {user.role === Role.CLIENT &&
                        <Tab eventKey="account-contact" title={intl.formatMessage({ id: "Contacts" })}>
                            <Contacts
                                admin={admin}
                                generalSettings={generalSettings}
                            />
                        </Tab>
                    }
                </Tabs>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        admin: state.admin,
        generalSettings: state.generalSettings,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Account));