import { GET_RGPD } from "../../actions/rgpd/rgpd";

export default function rgpd(state = {}, action) {

    switch (action.type) {
        case GET_RGPD:
            return action.rgpd;
        default:
            return state;
    }

};
