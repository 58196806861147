import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import InterventionRow from "./InterventionRow";
import Paginator from "../sub/Paginator";
import { Button, Modal } from 'react-bootstrap';
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import { deleteIntervention } from "../../actions/interventions/Interventions";
import Roles from "../../enums/Roles";
import CustomLabel from "../sub/CustomLabel";
import Util from '../../util/Util';
import InterventionTypes from "../../enums/InterventionTypes";
import StringUtil from '../../util/StringUtil';
import { nanoid } from "nanoid";
import DateUtil from '../../util/DateUtil';
import MenuButton from "../sub/bootstrap/MenuButton";

class Interventions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null,

            disabled: null,

            collapsed: false,
            displayPDF: false,
            disableExport: false,

            // filters
            dateFilter: "",
            patientNameFilter: "",
            interventionTypeFilter: "",
            technicianNameFilter: ""
        };

        this.paginator = new Paginator(this);
    }

    close() {
        this.props.closeModal();
    }

    stopEvent(e) {
        e.stopPropagation();
    }

    closeModal() {
        this.setState({ modal: null });
    }

    // DELETE AN INTERVENTION
    _deleteIntervention(intervention) {
        const onConfirm = () => {
            let data = {
                interventionId: intervention._id,
                patientId: intervention.patient_id,
            };

            this.props.onDeleteIntervention(data);
        }

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                context="danger"
                title={<FormattedMessage id="Warning" />}
                content={<><FormattedMessage id="Confirm.Intervention.Removal" /><p><FormattedMessage id="Please.Confirm" /></p></>}
                successCallback={onConfirm}
                closeModal={() => this.closeModal()} />
        });
    }

    useModal(title, content) {
        return <Modal
            show={true}
            onHide={() => this.close()}
            backdrop="static"
            keyboard={false}
            onClick={(e) => this.stopEvent(e)}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {content}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="btn btn-info" onClick={() => this.close()}><FormattedMessage id="Close" /></Button>
            </Modal.Footer>
            {this.state.modal}
        </Modal>
    }

    areResultsFiltered = () => {
        if (
            !Util.emptyString(this.state.dateFilter) ||
            !Util.emptyString(this.state.patientNameFilter) ||
            !Util.emptyString(this.state.interventionTypeFilter) ||
            !Util.emptyString(this.state.technicianNameFilter)
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    resetSearchFields() {
        this.setState({
            dateFilter: "",
            patientNameFilter: "",
            interventionTypeFilter: "",
            technicianNameFilter: ""
        });
    }

    interventionTypeList() {

        let options = [];

        for (const key in InterventionTypes) {
            options.push(<option key={key} value={InterventionTypes[key]}>{this.props.intl.formatMessage({ id: "Intervention.Type." + InterventionTypes[key] })}</option>);
        }

        return options;
    }

    render() {

        let user = this.props.user;

        this.paginator.init();

        let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

        const displayAll = !this.props.equipment ? true : false;

        var content = [];

        let intervention = [];

        if (displayAll) {
            intervention = this.props.interventions;
        }
        else {
            for (var interventions of this.props.interventions) {
                if (interventions.equipment_id === this.props.equipment._id) {
                    intervention.push(interventions);
                }
            }
        }

        let patientNode = this.props.patients.map((patient) => {
            return (
                <option key={patient._id} value={patient._id}>{patient.name && patient.name.toUpperCase() + " " + StringUtil.ucFirst(patient.first_name)}</option>
            );
        });

        let technicianNode = this.props.collaborators.map((collaborator) => {
            return (
                <option key={collaborator._id} value={collaborator._id}>{collaborator.name && collaborator.name.toUpperCase() + " " + StringUtil.ucFirst(collaborator.first_name)}</option>
            );
        });

        // If there are some interventions
        if (intervention.length > 0) {
            let i = 0;

            content.push(
                !this.props.limit && <div key={nanoid()} className="row search-filters">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-inline">
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Date" })}
                                        htmlFor="search_date"
                                        labelClassName="my-1 mr-2" />
                                    <input id="search_date" className="form-control mr-sm-3" type="date" onChange={(e) => { this.setState({ dateFilter: e.target.value }) }} value={this.state.dateFilter} disabled={disableFormInput} />
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Intervention.Type" })}
                                        htmlFor="search_type"
                                        labelClassName="my-1 mr-2" />
                                    <select id="search_type" className="form-control mr-sm-3" onChange={(e) => { this.setState({ interventionTypeFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.interventionTypeFilter}>
                                        <option value="">{this.props.intl.formatMessage({ id: "All" })}</option>
                                        {this.interventionTypeList()}
                                    </select>
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Patient" })}
                                        htmlFor="search_patient"
                                        labelClassName="my-1 mr-2" />
                                    <select id="search_patient" className="form-control mr-sm-3" onChange={(e) => { this.setState({ patientNameFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.patientNameFilter}>
                                        <option value="">{this.props.intl.formatMessage({ id: "All" })}</option>
                                        {patientNode}
                                    </select>
                                    {user.role !== Roles.CLIENT && <><CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Technician" })}
                                        htmlFor="search_technician"
                                        labelClassName="my-1 mr-2" />
                                        <select id="search_technician" className="form-control mr-sm-3" onChange={(e) => { this.setState({ technicianNameFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.technicianNameFilter}>
                                            <option value="">{this.props.intl.formatMessage({ id: "All" })}</option>
                                            {technicianNode}
                                        </select></>}
                                    <MenuButton
                                        onClick={() => this.resetSearchFields()}
                                        hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                                        variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                                        icon="filter"
                                        disabled={!this.areResultsFiltered() || disableFormInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

            var interventionsNode = intervention.map(intervention => {

                if (this.state.dateFilter && this.state.dateFilter !== "") {
                    if (DateUtil.toDate(intervention.creation_date) !== DateUtil.toDate(this.state.dateFilter)) return null;
                }

                if (this.state.patientNameFilter && this.state.patientNameFilter !== "") {
                    if (intervention.patient_id !== this.state.patientNameFilter) return null;
                }

                if (this.state.technicianNameFilter && this.state.technicianNameFilter !== "") {
                    if (intervention.technician_id !== this.state.technicianNameFilter) return null;
                }

                if (this.state.interventionTypeFilter && this.state.interventionTypeFilter !== "") {
                    if (parseInt(intervention.intervention_type) !== parseInt(this.state.interventionTypeFilter)) return null;
                }

                if (this.props.limit && ++i > this.props.limit) return null;

                if (!this.paginator.keep()) return null;

                return (
                    <InterventionRow
                        key={intervention._id}
                        intervention={intervention}
                        equipment={this.props.equipment}
                        closeModal={() => this.closeModal()}
                        deleteIntervention={(intervention) => this._deleteIntervention(intervention)}
                        limit={this.props.limit && true}
                    />
                );
            });

            content.push(
                <table key={nanoid()} className="table tablee4mad">
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="Date" />
                            </th>
                            <th>
                                <FormattedMessage id="Intervention.Type" />
                            </th>
                            {(user.role !== Roles.CLIENT && !this.props.equipment) && <th>
                                <FormattedMessage id="Patient" />
                            </th>}
                            <th>
                                {user.role !== Roles.CLIENT ? <FormattedMessage id="Technician" /> : <FormattedMessage id="Patient" />}
                            </th>
                            {!this.props.limit && <th className="col-2 text-center">
                                <FormattedMessage id="Actions" />
                            </th>}
                        </tr>
                    </thead>

                    <tbody>
                        {interventionsNode}
                    </tbody>
                </table>
            );

            !this.props.limit && content.push(this.paginator.render());
        }
        else {
            content.push(
                <div key={nanoid()} className="alert alert-secondary" role="alert">
                    <FormattedMessage id={!this.props.equipment ? "Empty.Interventions" : "Equipment.Empty.Interventions"} />
                </div>
            );
        }

        return this.props.equipment ? this.useModal(<FormattedMessage id="Interventions.History" />, content) : <div>{content}{this.state.modal}</div>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        interventions: state.interventions,
        patients: state.patients,
        collaborators: state.collaborators
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteIntervention: (data, successCallback) => dispatch(deleteIntervention(data, successCallback)),
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Interventions));
