import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import TextareaCounter from 'react-textarea-counter';

class PromptModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false
        }
    }

    close() {
        if (this.state.disabled) return;
        this.props.closeModal();
    }

    confirm() {
        let callback = async () => {
            await this.props.successCallback();
        };

        this.setState({ disabled: true }, callback);
    }

    render() {
        const { title, content } = this.props;

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <label htmlFor="comment">{content} :</label>
                    <div>
                        <TextareaCounter countLimit={1000} className="textareaCounter" id="comment" name="comment" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.close()} disabled={this.state.disabled}><FormattedMessage id="No" /></Button>
                    <Button variant="btn btn-info" onClick={() => this.confirm()} disabled={this.state.disabled}><FormattedMessage id="Yes" /></Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PromptModal;