import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Util from '../../util/Util';
import { addPatient, updPatientAdmin } from "../../actions/patients/patients";
import DateUtil from '../../util/DateUtil';
import Roles from '../../enums/Roles';
import CustomLabel from "../sub/CustomLabel";
import ComboBox from "../sub/ComboBox";
import Geolocation from '../api/gouv/Geolocation';
import TextareaCounter from 'react-textarea-counter';
import { checkIfEmailIsValid, checkIfUsernameExists } from '../../actions/user/user';
import { addClient, updClient } from '../../actions/clients/clients';
import { Form, Row, Col, Alert, Button } from 'react-bootstrap';
import Icon from '../sub/Icon';
import MercurialStatus from '../../enums/MercurialStatus';
import PatientAccountModal from './PatientAccountModal';

class PatientGeneralInfo extends React.Component {

    constructor(props) {
        super(props);

        const { patient, clients } = this.props;

        this.isUpdate = (!!patient);

        const patientAccountData = this.isUpdate ? clients.find((c) => c._id === patient.user_id[0]) : null;

        const getValue = (field) => (patient) ? patient[field] : "";

        this.state = {
            first_name: getValue("first_name"),
            name: getValue("name"),
            birth_date: getValue("birth_date") || "",
            address: getValue("address"),
            addressAdditionnal: getValue("addressAdditionnal") || "",
            postal_code: getValue("postal_code") || "",
            city: getValue("city"),
            phone: getValue("phone"),
            mobile: getValue("mobile"),
            email: getValue("email"),
            codeCRM: getValue("codeCRM"),
            comments: getValue("comments"),
            username: (patientAccountData && patientAccountData.username) || "",
            mercurial_Id: (patientAccountData && patientAccountData.mercurial_Id) || "",
            notifyByEmail: false,
            phoneError: null,
            mobileError: null,
            emailError: null,
            postal_codeError: null,
            usernameError: null,
            listCities: getValue("city") ? [{ _id: getValue("city"), name: getValue("city") }] : [],
            loading: false,
            hasLogin: patientAccountData && patientAccountData.username ? true : false,
            modal: null,
        };

    }

    getCityList(postal_code) {

        if (!postal_code || postal_code === this.state.postal_code || this.state.postal_code === "") {
            return;
        }
        else {
            Geolocation.getCitiesByPostalCode(postal_code, this.state.city, (liste) => {
                this.setState({ listCities: liste });
            });
        }

    }

    onSubmit() {
        this.setState({ loading: true });

        const patient = {
            first_name: this.state.first_name,
            name: this.state.name,
            birth_date: this.state.birth_date,
            address: this.state.address,
            addressAdditionnal: this.state.addressAdditionnal,
            postal_code: this.state.postal_code,
            city: this.state.city,
            phone: this.state.phone,
            mobile: this.state.mobile,
            email: this.state.email,
            codeCRM: this.state.codeCRM,
            comments: this.state.comments,
            active: 1,
        };

        const client = {
            first_name: this.state.first_name,
            name: this.state.name,
            address: this.state.address,
            addressAdditionnal: this.state.addressAdditionnal,
            postal_code: this.state.postal_code,
            city: this.state.city,
            phone: this.state.phone,
            mobile: this.state.mobile,
            email: this.state.email,
            username: this.state.username,
            client_type: "patient",
            account_type: "0",
            notifyByEmail: this.state.notifyByEmail
        }

        if (this.state.mercurial_Id !== "") client.mercurial_Id = this.state.mercurial_Id;

        const close = (patient) => this.setState({ loading: false }, () => this.props.openModal(patient));
        const successCallback = (client) => {

            patient.user_id = client._id;

            const updateClient = (patient) => {
                const updatedClient = {
                    _id: client._id,
                    updatedField: "patient_id",
                    updatedValue: patient._id
                };

                this.props.onUpdClient(updatedClient, () => close(patient));
            }

            this.props.onAddPatient(patient, updateClient);
        }

        // Send to BE
        if (this.state.hasLogin && !Util.emptyString(this.state.username)) return this.props.onAddClient(client, successCallback);
        return this.props.onAddPatient(patient, close);
    }

    disabled() {
        return (
            Util.emptyString(this.state.name) ||
            Util.emptyString(this.state.first_name) ||
            Util.emptyString(this.state.address) ||
            Util.emptyString(this.state.postal_code) ||
            Util.emptyString(this.state.city) ||
            (this.state.hasLogin && Util.emptyString(this.state.username)) ||
            (this.state.hasLogin && Util.emptyString(this.state.email)) ||
            this.state.phoneError ||
            this.state.mobileError ||
            this.state.emailError ||
            this.state.usernameError ||
            this.state.postal_codeError ||
            this.state.loading
        );
    }

    onChange(field, value) {

        if (field === "postal_code" && value.length > 5) {
            return;
        }

        this.setState({ [field]: value });

        if (field === "phone") {
            if (!Util.emptyString(value) && !Util.isPhone(value)) this.setState({ phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" /> });
            else this.setState({ phoneError: null });
        } else if (field === "postal_code") {
            if (!Util.emptyString(value) && !Util.isPostal(value)) {
                this.setState({
                    postal_codeError: <FormattedMessage id="Invalid.Postal.Number.Error" />,
                    city: ""
                });
            }
            else {
                this.setState(
                    {
                        postal_codeError: null,
                        listCities: this.state.city ? [{ _id: this.state.city, name: this.state.city }] : []
                    }, this.getCityList(value)
                );
            }
        } else if (field === "mobile") {
            if (!Util.emptyString(value) && !Util.isPhone(value)) this.setState({ mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" /> });
            else this.setState({ mobileError: null });
        } else if (field === "username") {
            this.setState({ usernameError: null });

            this.setState({ [field]: value });

            if (Util.emptyString(value)) {
                this.setState({ usernameError: <FormattedMessage id="Empty.Username" /> });
                return;
            }

            var checkUsername = Util.isValidUserName(value, 3, 20);

            if (checkUsername instanceof Object === true) {
                this.setState({ usernameError: <FormattedMessage id="Invalid.Username" values={{ minLength: checkUsername.minLength, maxLength: checkUsername.maxLength }} /> });
                return;
            } else {
                this.setState({ usernameError: null })
            }

            if (!this.isUpdate || (this.isUpdate && this.props.patient.username !== value)) {
                this.props.onCheckIfUsernameExists(
                    value,
                    () => { this.setState({ usernameError: <FormattedMessage id="Username.Already.Exists" values={{ username: value }} /> }) },
                    () => { this.setState({ usernameError: null }); }
                );
            }
        } else if (field === "email") {
            if (Util.emptyString(value)) this.setState({ emailError: null });
            if (!Util.emptyString(value) && !Util.isEmail(value)) {
                this.setState({ emailError: <FormattedMessage id="Invalid.Email.Error" /> });
            } else if (!Util.emptyString(value) && Util.isEmail(value)) {
                this.props.onCheckIfEmailIsValid(
                    value,
                    (response) => {
                        // console.log("data", response.data.message);
                        this.setState({ emailError: null });
                    },
                    (response) => {
                        // console.log("data", response);
                        this.setState({ emailError: <span><FormattedMessage id="Invalid.Email.Error" /> ({response.data.reason})</span> });
                        return;
                    }
                );
            }
        } else if (field === "city") {
            let list = this.state.listCities;
            let response = false;
            for (let listobject of list) {
                if (listobject._id === value) {
                    response = true;
                }
            }
            if (!response) {
                list.push({ _id: value, name: value });
                this.setState({
                    listCities: list
                })
                this.setState({ [field]: value });
            }

        }
    }

    onBlur(field, value) {

        if (!this.isUpdate) return;

        if (field === "phone" && this.state.phoneError) return;
        else if (field === "mobile" && this.state.mobileError) return;
        else if (field === "postal_code" && this.state.postal_codeError) return;
        else if (field === "email" && this.state.emailError) return;

        // Required fields
        else if (field === "name" && Util.emptyString(this.state.name)) return;
        else if (field === "first_name" && Util.emptyString(this.state.first_name)) return;
        else if (field === "city" && Util.emptyString(this.state.city)) return;
        else if (field === "address" && Util.emptyString(this.state.address)) return;
        else if (field === "postal_code" && Util.emptyString(this.state.postal_code)) return;
        else if (field === "username" && this.state.usernameError) return;
        else if (field === "mercurial_Id" && (Util.emptyString(this.state.username) || this.state.usernameError)) return;

        const patient = {
            _id: this.props.patient._id,
            [field]: value
        };

        const updatedClient = {
            _id: this.props.patient.user_id[0],
            updatedField: field,
            updatedValue: value
        };

        const newClient = {
            first_name: this.state.first_name,
            name: this.state.name,
            address: this.state.address,
            addressAdditionnal: this.state.addressAdditionnal,
            postal_code: this.state.postal_code,
            city: this.state.city,
            phone: this.state.phone,
            mobile: this.state.mobile,
            email: this.state.email,
            username: this.state.username,
            client_type: "patient",
            account_type: "0",
            notifyByEmail: this.state.notifyByEmail,
            patient_id: this.props.patient._id,
        };

        const successCallback = (client) => {
            const updatedPatient = {
                _id: this.props.patient._id,
                user_id: client._id
            };

            this.props.onUpdPatientAdmin(updatedPatient);
        }

        if (field === "username" && this.props.patient.user_id.length === 0) return this.props.onAddClient(newClient, successCallback);
        if ((field === "username" || field === "mercurial_Id") && this.props.patient.user_id.length > 0) return this.props.onUpdClient(updatedClient);

        // Send to BE
        if (this.state.hasLogin && !Util.emptyString(this.state.username)) return this.props.onUpdPatientAdmin(patient, () => this.props.onUpdClient(updatedClient));
        return this.props.onUpdPatientAdmin(patient);
    }

    closeModal() {
        this.setState({ modal: null }, () => this.props.close());
    }

    openPatientUserModal() {
        this.setState({modal: (
            <PatientAccountModal
                patient={this.props.patient}
                closeModal={(patient) => this.closeModal(patient)}
            />
        )})
    }

    render() {
        const { user } = this.props;
        const { role } = user;

        if (role === undefined) return null;

        const now = new Date();

        return (
            <React.Fragment>
                <Col>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Name" })}
                            htmlFor="name"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="name" autoComplete="off"
                                value={this.state.name} onChange={(e) => this.onChange("name", e.target.value.toUpperCase())}
                                onBlur={(e) => this.onBlur("name", e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "First.Name" })}
                            htmlFor="first_name"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control text-capitalize" type="text" id="first_name" autoComplete="off"
                                value={this.state.first_name} onChange={(e) => this.onChange("first_name", e.target.value.toLowerCase())}
                                onBlur={(e) => this.onBlur("first_name", e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Birth.Date" })}
                            htmlFor="birth_date"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="date" name="birth_date"
                                value={DateUtil.toyyyyMMddPatient(this.state.birth_date)} onChange={(e) => this.onChange("birth_date", e.target.value)}
                                onBlur={(e) => this.onBlur("birth_date", e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Address" })}
                            htmlFor="address"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="address" autoComplete="off"
                                value={this.state.address} onChange={(e) => this.onChange("address", e.target.value)}
                                onBlur={(e) => this.onBlur("address", e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Address.Compl" })}
                            htmlFor="address"
                            labelClassName="col-12 col-sm-5 col-form-label"
                        />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="address" autoComplete="off"
                                value={this.state.addressAdditionnal} onChange={(e) => this.onChange("addressAdditionnal", e.target.value)}
                                onBlur={(e) => this.onBlur("addressAdditionnal", e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Postal.Code" })}
                            htmlFor="postal_code"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="postal_code" autoComplete="off"
                                value={this.state.postal_code} onChange={(e) => this.onChange("postal_code", e.target.value)}
                                onBlur={(e) => this.onBlur("postal_code", e.target.value)} />
                            <div className="text-danger"><small>{this.state.postal_codeError}</small></div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "City" })}
                            htmlFor="city"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required />
                        <Col>
                            {role !== Roles.CLIENT &&
                                <ComboBox
                                    onCreateOption={(option, successCallback) => { successCallback({ _id: option.name, name: option.name }) }}
                                    onChange={e => { this.onChange("city", e) }}
                                    onBlur={e => { this.onBlur("city", e) }}
                                    defaultOption={this.state.city}
                                    options={this.state.listCities}
                                    disabled={role === Roles.CLIENT}
                                    placeholder={this.state.listCities.length ? this.props.intl.formatMessage({ id: "Select" }) : this.props.intl.formatMessage({ id: "Type.Postal.Code.First" })}
                                />
                            }
                            {role === Roles.CLIENT &&
                                <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="city" autoComplete="off"
                                    value={this.state.city} onChange={(e) => this.onChange("city", e.target.value)}
                                    onBlur={(e) => this.onBlur("city", e.target.value)} />
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Phone" })}
                            htmlFor="phone"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="phone" autoComplete="off"
                                value={this.state.phone} onChange={(e) => this.onChange("phone", e.target.value)}
                                onBlur={(e) => this.onBlur("phone", e.target.value)} />
                            <div className="text-danger"><small>{this.state.phoneError}</small></div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Mobile" })}
                            htmlFor="mobile"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="mobile" autoComplete="off"
                                value={this.state.mobile} onChange={(e) => this.onChange("mobile", e.target.value)}
                                onBlur={(e) => this.onBlur("mobile", e.target.value)} />
                            <div className="text-danger"><small>{this.state.mobileError}</small></div>
                        </Col>
                    </Form.Group>
                    {!this.state.hasLogin && <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Email" })}
                            htmlFor="email"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="email" autoComplete="off"
                                value={this.state.email} onChange={(e) => this.onChange("email", e.target.value)}
                                onBlur={(e) => this.onBlur("email", e.target.value)} />
                            <div className="text-danger"><small>{this.state.emailError}</small></div>
                        </Col>
                    </Form.Group>}
                    <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Code.CRM" })}
                            htmlFor="codeCRM"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        <Col>
                            <input disabled={role === Roles.CLIENT} className="form-control" type="text" id="codeCRM" autoComplete="off"
                                value={this.state.codeCRM} onChange={(e) => this.onChange("codeCRM", e.target.value)}
                                onBlur={(e) => this.onBlur("codeCRM", e.target.value)} />
                        </Col>
                    </Form.Group>
                    {role !== Roles.CLIENT && <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Internal.Comment" })}
                            htmlFor="comments"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            popOver={this.props.intl.formatMessage({ id: "Internal.Comment.Popover" })} />
                        <Col>
                            <TextareaCounter id="comments" countLimit={500} className="textareaCounter"
                                initialValue={this.state.comments}
                                onChange={(e) => this.onChange("comments", e.target.value)}
                                onBlur={(e) => this.onBlur("comments", e.target.value)} />
                        </Col>
                    </Form.Group>}

                    {this.isUpdate && user.role === Roles.ADMIN && this.props.patient.user_id.length === 0 &&
                        <Row>
                            <Col>
                                <Alert variant="warning"><Icon icon="warning" /> <FormattedMessage id="Patient.Has.No.Access" /> <Button variant='warning' onClick={() => this.openPatientUserModal()}><Icon icon="user"/> <FormattedMessage id="Create.Access" /></Button></Alert>
                            </Col>
                        </Row>
                    }

                    {!this.isUpdate && <Form.Group as={Row}>
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Access.E4MAD" })}
                            htmlFor="login-switch-label"
                        />
                        <Col>
                            <div className="custom-control custom-switch mx-auto switch-success text-left">
                                <input type="checkbox"
                                    id="login-switch"
                                    disabled={user.role === Roles.CLIENT}
                                    className="custom-control-input switch-bg-blue"
                                    onChange={(e) => { this.setState({ hasLogin: !this.state.hasLogin }) }}
                                    checked={this.state.hasLogin} />
                                <CustomLabel
                                    htmlFor={"login-switch"}
                                    labelClassName="custom-control-label" />
                            </div>
                        </Col>
                    </Form.Group>}

                    {this.state.hasLogin && 
                        <>
                            <Form.Group as={Row}>
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Email" })}
                                    htmlFor="email"
                                    labelClassName="col-12 col-sm-5 col-form-label"
                                    required />
                                <Col>
                                    <input disabled={this.props.user.role === Roles.CLIENT} className="form-control" type="text" id="email" autoComplete="off"
                                        value={this.state.email} onChange={(e) => this.onChange("email", e.target.value)}
                                        onBlur={(e) => this.isUpdate && this.onBlur("email", e.target.value)} />
                                    <div className="text-danger"><small>{this.state.emailError}</small></div>
                                </Col>
                            </Form.Group>
            
                            <Form.Group as={Row}>
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Username.Connexion" })}
                                    htmlFor="username"
                                    labelClassName="col-12 col-sm-5 col-form-label"
                                    required />
                                <Col>
                                    <input disabled={this.props.user.role === Roles.CLIENT} className="form-control col-12 col-md-7 d-inline" type="text" id="username" autoComplete="off"
                                        value={this.state.username} onChange={(e) => this.onChange("username", e.target.value.toLowerCase())}
                                        onBlur={(e) => this.isUpdate && this.onBlur("username", e.target.value.toLowerCase())} />
                                    <span className="col-12 col-md-3">-{this.props.company.url}</span>
                                    <div className="text-danger"><small>{this.state.usernameError}</small></div>
                                </Col>
                            </Form.Group>
            
                            {!Util.emptyString(this.state.username) && !this.state.usernameError && <Form.Group as={Row}>
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Mercurial.Linked" })}
                                    htmlFor="mercurial_Id"
                                    labelClassName="col-12 col-sm-5 col-form-label" />
                                <Col>
                                    <select className="form-control w-100"
                                        value={this.state.mercurial_Id} onChange={(e) => this.onChange("mercurial_Id", e.target.value)}
                                        onBlur={(e) => this.isUpdate && this.onBlur("mercurial_Id", e.target.value)}>
                                        <option value="">{this.props.intl.formatMessage({ id: "None.Feminine" })}</option>
                                        {
                                            this.props.mercurials.map(t => {
                                                const hasExpired = (new Date(t.start_date) > now || new Date(t.end_date) < now);
                                                if (hasExpired || t.status === MercurialStatus.INACTIVE) return null;
            
                                                return (
                                                    <option key={t._id} value={t._id} disabled={hasExpired}>{t.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                            </Form.Group>}
            
                            {!this.isUpdate && <Form.Group as={Row}>
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Send.Mail.Notify.Created.Account" })}
                                    htmlFor="notifyByEmail"
                                    labelClassName="col-12 col-sm-5 col-form-label"
                                />
                                <Col>
                                    <input type={"checkbox"}
                                        id="notifyByEmail"
                                        onChange={e => { this.onChange("notifyByEmail", e.target.checked); }}
                                        checked={this.state.notifyByEmail}
                                    />
                                </Col>
                            </Form.Group>}
                        </>
                    }
                    
                    <Col>
                        {(!this.isUpdate) &&
                            <div className="col-12 col-lg-6 offset-lg-3">
                                <button className="btn btn-info btn-block" disabled={this.disabled()} onClick={() => this.onSubmit()}>
                                    <FormattedMessage id="Add" />
                                </button>
                            </div>
                        }
                    </Col>
                </Col>
                {this.state.modal}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        company: state.company,
        mercurials: state.mercurials,
        clients: state.clients,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAddClient: (client, successCallback) => dispatch(addClient(client, successCallback)),
        onAddPatient: (patient, successCallback) => dispatch(addPatient(patient, successCallback)),
        onUpdPatientAdmin: (patient, successCallback) => dispatch(updPatientAdmin(patient, successCallback)),
        onUpdClient: (client, successCallback) => dispatch(updClient(client, successCallback)),
        onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) => dispatch(checkIfUsernameExists(username, existsCallback, noExistsCallback)),
        onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) => dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientGeneralInfo));
