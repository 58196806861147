import APIUrl from "../../APIUrl";
import axios from 'axios';

export const GET_PROMO_OPERATIONS = "GET_PROMO_OPERATIONS";

function getPromoOperationsAction(promoOperations){return {type: GET_PROMO_OPERATIONS, promoOperations: promoOperations}};

export const getPromoOperationsAdmin = function(){
    return function(dispatch){
        return axios.get(APIUrl.getPromoOperationsAdmin)
            .then(function(response){
                dispatch(getPromoOperationsAction(response.data));
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const getPromoOperations = function(){
    return function(dispatch){
        return axios.get(APIUrl.getPromoOperations)
            .then(function(response){
                dispatch(getPromoOperationsAction(response.data));
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const createPromoOperation = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.createPromoOperation, data)
            .then(function(response){
                dispatch(getPromoOperationsAdmin());
                if (successCallback) return successCallback(response.data);
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const addPromoOperationFile = function (operationPromotionalId, data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.addPromoOperationFile + operationPromotionalId, data, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(function () {
                dispatch(getPromoOperationsAdmin());
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const deletePromoOperation = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.deletePromoOperation, data)
            .then(function () {
                dispatch(getPromoOperationsAdmin());
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

export const updatePromoOperation = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.updatePromoOperation, data)
            .then(function () {
                dispatch(getPromoOperationsAdmin());
                if (successCallback) return successCallback();
            })
            .catch(function (err) {
                throw (err);
            });
    };
};

// export const updatePromoOffers = function(){
//     return function(dispatch){
//         return axios.get(APIUrl.getPromoOffers)
//             .then(function(response){
//                 dispatch(getPromoOfferAction(response.data));
//             })
//             .catch(function(err){
//                 throw err;
//             });
//     };
// };