import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary: ${({ theme }) => theme.colors.primary};
    --secondary: ${({ theme }) => theme.colors.secondary};
    --light: ${({ theme }) => theme.colors.light};
    --dark: ${({ theme }) => theme.colors.dark};
    --menu-primary-color: ${({ theme }) => theme.colors.menu.primary};
    --menu-secondary-color: ${({ theme }) => theme.colors.menu.secondary};
    --menu-border-color-hover: ${({ theme }) => theme.colors.menu.border_hover};
    --menu-border-color-selected: ${({ theme }) => theme.colors.menu.border_selected};
    --menu-background-color: ${({ theme }) => theme.colors.menu.background};
  }
`;
