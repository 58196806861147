import { GET_ADMIN_ORDERS_SETTINGS } from "../../../../actions/settings/ordersSettings/admin/ordersSettings";

export default function ordersSettingsAdmin(state = [], action) {

    switch (action.type) {
        case GET_ADMIN_ORDERS_SETTINGS:
            return action.ordersSettingsAdmin;
        default:
            return state;
    }

};
