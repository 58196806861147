import React from 'react';
import { connect } from 'react-redux';
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from 'react-intl';
import ExcelUtil from '../../util/ExcelUtil';
import FileUtil from '../../util/FileUtil';
import MercurialStatus from "../../enums/MercurialStatus";
import ActionMenu from "../sub/ActionMenu";
import { getProductsByMercurialIdInactive, getProductsByMercurialIdAdmin } from "../../actions/products/products";
import Icon from '../sub/Icon.js';
import MercurialsUtil from "../../util/mercurialsUtil.js";

class MercurialRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: "text-danger",
            progress: 0,
        };

        if (this.props.mercurial.synced_with_crm && this.props.mercurial.synced_with_crm === true) {
            this.state = {
                loading: "text-success",
                progress: 0,
            }
        }
    }

    getMercurialProducts(mercurial) {
        let products = [];

        for (let product of this.props.products) {
            if (product.mercurial_id === mercurial._id) {
                products.push(product);
            }
        }

        return products;
    }

    getMercurialProductsInactive(mercurial) {
        let mercurialId = mercurial._id;
        //let productss = this.props.onGetProductsByMercurialIdInactive(mercurialId,successCallback);
        let products = Promise.resolve(this.props.onGetProductsByMercurialIdInactive(mercurialId));


        return products;
    }

    getMercurialProductsFromBE(mercurial) {
        let mercurialId = mercurial._id;
        let products = Promise.resolve(this.props.onGetProductsByMercurialIdAdmin(mercurialId));
        return products;
    }

    download(mercurial) {
        // Convert data to Excel format
        let products = this.getMercurialProductsFromBE(mercurial);
        let excelData;
        let currentMercurial = new MercurialsUtil(this.props.company);

        products.then((productsFromBE) => {
            let pro = [];

            for (let product of productsFromBE) {
                if (product.mercurial_id === mercurial._id) {
                    pro.push(product);
                }
            }

            excelData = ExcelUtil.toExcel(pro, currentMercurial.getMercurialMapping(), currentMercurial.getExcludedExportFields());

            // Sanitize the file name
            let fileName = FileUtil.toFileName(mercurial.name);

            // Save the file
            ExcelUtil.save(excelData, fileName);
        });

    }

    syncIcon(enabled, crmSoftware, icon) {

        return icon;

        /*
        if (enabled && icon.props.className.includes("success")) {
            return <>
                {icon}
                <UncontrolledTooltip delay={{ show: 0, hide: 0 }} placement="top" target={"sync" + this.props.mercurial._id} >
                    <FormattedMessage id="API.CRM.Sync.Ok" values={{ crmSoftware: crmSoftware }} />
                </UncontrolledTooltip>
            </>
        }
        else {
            return <>
                {icon}
                <UncontrolledTooltip delay={{ show: 0, hide: 0 }} placement="top" target={"sync" + this.props.mercurial._id} >
                    <FormattedMessage id="API.CRM.Sync.Nok" values={{ crmSoftware: crmSoftware }} />
                </UncontrolledTooltip>
            </>
        }
        */
    }

    getLengthProducts() {
        let length = 0;
        if (this.props.products.length !== 0) {
            for (let p of this.props.products) {
                if (p.mercurial_id === this.props.mercurial._id) {
                    length = length + 1;
                }
            }
        }
        return length;
    }

    checkProductsExistByMercurialId(mercurialId) {
        for (let i = 0; i < this.props.products.length; i++) {
            if (this.props.products[i].mercurial_id === mercurialId) {
                return false;
            }
        }
        return true;
    }

    render() {
        if (!this.props.mercurial) return null;

        let mercurial = this.props.mercurial;
        let products = this.getMercurialProducts(mercurial);
        let hasGreaterVersion = false;

        // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
        // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
        const { enabled: crmEnabled = false, software: crmSoftware = null } = this.props.company.crm ? this.props.company.crm : {};




        for (let m of this.props.mercurials) {
            if (m.name === mercurial.name && m.version > mercurial.version) {
                hasGreaterVersion = true;
                break;
            }
        }

        let versionXS = null;
        if (hasGreaterVersion || mercurial.version > 1) versionXS = <span className="d-table-cell d-md-none">(<FormattedMessage id="Version" /> {mercurial.version})</span>;

        let isOutDated = (new Date(mercurial.end_date) < new Date());

        const status = (isOutDated) ? MercurialStatus.INACTIVE : mercurial.status;
        const isInactive = (status === MercurialStatus.INACTIVE);
        const clientId = "none";
        /**
                * Check if the articles of the mercurial are synced with a CRM
                * @param {*} products List of products
                * @param {*} crmSoftware Name of the CRM used
                */


        let menuItems = [];

        menuItems.push(
            // Show/hide products
            {
                id: "see" + mercurial._id,
                icon: (!isOutDated && !isInactive) ? "eye" : "eye-slash",
                action: () => {
                    if (!isOutDated && !isInactive) {
                        if (this.checkProductsExistByMercurialId(mercurial._id)) {
                            this.props.onGetProductsByMercurialIdAdmin(mercurial._id);
                            window.location.hash = "/home/families/" + mercurial._id + "/" + clientId;
                        } else {
                            window.location.hash = "/home/families/" + mercurial._id + "/" + clientId;
                        }
                    }
                },
                disabled: (isOutDated && isInactive),
                text: <FormattedMessage id="Display.Active.Mercurial" />
            }, {
            id: "upl" + mercurial._id,
            icon: "upload",
            action: () => (!isOutDated && !isInactive) && this.props.openMercurialUpdModal(mercurial),
            disabled: ((isOutDated && isInactive) || (this.state.loading === "progress-bar")),
            text: <FormattedMessage id="Mercurials.Update" />
        }, {
            id: "dwl" + mercurial._id,
            icon: "download",
            action: () => this.download(mercurial),
            text: <FormattedMessage id="Export.Mercurial" />
        }, {
            id: "images" + mercurial._id,
            icon: "file-image",
            action: () => (!isOutDated && !isInactive) && this.props.openImgsModal(mercurial),
            disabled: (isOutDated && isInactive),
            text: <FormattedMessage id="Import.Image.Mercurial" />
        }, {
            id: "delete" + mercurial._id,
            icon: "trash",
            action: () => (this.state.loading !== "progress-bar") && this.props.openLayersModal(mercurial),
            disabled: (this.state.loading === "progress-bar"),
            text: <FormattedMessage id="Delete" />
        },
            // /!\ Warning: We check first if crm is enabled or not before rendering menu element
            crmEnabled && {
                id: "sync" + mercurial._id,
                icon: "arrows-rotate",
                action: () => { if (this.state.loading !== "progress-bar") { this.props.openSyncModal(products, crmSoftware, crmEnabled, this.props.mercurial); } },
                disabled: (this.state.loading === "progress-bar" || (this.props.mercurial.synced_with_crm && this.props.mercurial.synced_with_crm === true)),
                text: (this.props.mercurial.synced_with_crm && this.props.mercurial.synced_with_crm === true) ? <FormattedMessage id="API.CRM.Already.Synchronized.With" /> : <FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: crmSoftware }} />
            })

        let menuAction = <ActionMenu items={menuItems} />;
        return (
            <React.Fragment>
                <tr className={(isOutDated || isInactive) ? "font-italic text-muted" : ""}>
                    <td>
                        {mercurial.name}
                        {versionXS}
                    </td>
                    <td className="d-none d-md-table-cell">{DateUtil.toDate(mercurial.start_date)}</td>
                    <td className="d-none d-md-table-cell">{DateUtil.toDate(mercurial.end_date)}</td>
                    <td className="d-none d-sm-table-cell">{mercurial.file_name}</td>
                    <td className="d-none d-md-table-cell">{mercurial.version}</td>
                    <td>
                        {this.props.intl.formatMessage({ id: "Mercurial.Status." + status })}
                    </td>
                    {
                        crmEnabled && <td className="d-flex align-items-center">

                            {((this.state.loading !== "progress-bar") && !isInactive && products.length !== 0 && this.props.mercurial.synced_with_crm && this.props.mercurial.synced_with_crm === true) && this.syncIcon(crmEnabled, crmSoftware, <Icon icon="circle-check" id={"sync" + this.props.mercurial._id} size="xl" className={"text-success"} />)}
                            {((this.state.loading !== "progress-bar") && !isInactive && products.length !== 0 && this.props.mercurial.synced_with_crm !== true) && this.syncIcon(crmEnabled, crmSoftware, <Icon icon="circle-xmark" variant="far" id={"sync" + this.props.mercurial._id} size="xl" className={"text-danger"} />)}
                            {((this.state.loading !== "progress-bar") && !isInactive && products.length === 0 && this.props.mercurial.synced_with_crm && this.props.mercurial.synced_with_crm === true) && this.syncIcon(crmEnabled, crmSoftware, <Icon icon="circle-check" id={"sync" + this.props.mercurial._id} size="xl" className={"text-success"} disabled />)}
                            {((this.state.loading !== "progress-bar") && !isInactive && products.length === 0 && this.props.mercurial.synced_with_crm !== true) && this.syncIcon(crmEnabled, crmSoftware, <Icon icon="circle-xmark" variant="far" id={"sync" + this.props.mercurial._id} size="xl" className={"text-danger"} disabled />)}
                            {((this.state.loading !== "progress-bar") && isInactive && this.props.mercurial.synced_with_crm && this.props.mercurial.synced_with_crm === true) && this.syncIcon(crmEnabled, crmSoftware, <Icon icon="circle-check" id={"sync" + this.props.mercurial._id} size="xl" className={"text-success"} disabled />)}
                            {((this.state.loading !== "progress-bar") && isInactive && this.props.mercurial.synced_with_crm !== true) && this.syncIcon(crmEnabled, crmSoftware, <Icon icon="circle-xmark" variant="far" id={"sync" + this.props.mercurial._id} size="xl" className={"text-danger"} disabled />)}
                        </td>
                    }
                    {
                        !this.props.limit && <td className="col-1 text-center align-middle">
                            {menuAction}
                        </td>
                    }
                </tr >
            </React.Fragment >
        );
    }

}

const mapStateToProps = state => {
    return {
        products: state.products,
        company: state.company,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
        onGetProductsByMercurialIdInactive: (mercurialId) => dispatch(getProductsByMercurialIdInactive(mercurialId)),
        onGetProductsByMercurialIdAdmin: (mercurialId) => dispatch(getProductsByMercurialIdAdmin(mercurialId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MercurialRow));
