import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import AddPatientEquipmentModal from './AddPatientEquipmentModal';
import { updPatientAdmin } from "../../actions/patients/patients";
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import DateUtil from '../../util/DateUtil';
import EqSellModes from '../../enums/EqSellModes';
import ActionMenu from "../sub/ActionMenu";
import MaintenancesModal from "../maintenance/MaintenancesModal";
import Interventions from '../intervention/Interventions';
import { Alert, Modal } from 'react-bootstrap';
import Util from "../../util/Util";

class PatientsEquipmentsRemindedModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };
    }

    openModal(patient, equipment) {
        this.setState({
            modal: <AddPatientEquipmentModal
                patient={patient}
                equipment={equipment}
                close={() => this.closeModal()} />
        });
    }

    openInterventions(patient, equipment) {
        this.setState({
            modal: (
                <Interventions
                    equipment={equipment}
                    patient={patient}
                    closeModal={() => this.closeModal()}
                />
            )
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    delete(patient, equipment) {
        if (!patient || !equipment) return;

        const onConfirm = () => {
            const updatedPatient = {
                _id: patient._id,
                equipments: patient.equipments,
            };

            updatedPatient.equipments = updatedPatient.equipments.filter(e => e._id !== equipment._id);

            // Send to BE
            this.props.onUpdPatientAdmin(updatedPatient);
        }

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={<FormattedMessage id="Confirm" />}
                content={<FormattedMessage id="Confirm.Equipment.Removal" />}
                successCallback={onConfirm}
                closeModal={() => this.closeModal()} />
        });
    }

    renewalDateAlert(renewalDate) {
        const expirationWarningInMonths = 2;
        const todayIsoDate = DateUtil.DateTime().now().toISO();
        const warningDate = DateUtil.removeMonth(renewalDate, expirationWarningInMonths);

        // Renewal date expired
        if (DateUtil.DateTime().fromISO(renewalDate) < DateUtil.DateTime().fromISO(todayIsoDate)) {
            return "danger";
        }

        // Renewal date will expire within two months
        if (DateUtil.DateTime().fromISO(warningDate) < DateUtil.DateTime().fromISO(todayIsoDate)) {
            return "warning";
        }

        // Not expired, expiration will be in more than two months
        return "success";
    }

    // Calculate renew date
    renewalDate(equipment) {

        // Get stored date
        let date = equipment.last_prescription_date;

        if (!equipment.auditEquipment) return null;

        // Calculate duration in SELL mode
        if (equipment.mode === EqSellModes.SALE) {

            if (Util.emptyString(equipment.auditEquipment.purchase_renewal)) {
                equipment.auditEquipment.purchase_renewal = "0";
            }

            if (parseInt(equipment.auditEquipment.purchase_renewal) !== 0) {
                date = DateUtil.addYear(date, equipment.auditEquipment.purchase_renewal);
            }
            else {
                date = null;
            }
        }

        // Calculate duration in LEASING mode
        else if (equipment.mode === EqSellModes.LEASING) {
            // date = new Date(date.setMonth(date.getMonth() + parseInt(equipment.rental_renewal)));
            date = DateUtil.addMonth(date, equipment.rental_renewal);
        }

        return date;
    }

    openMaintenancesModal(equipment, patient) {
        // Quick patch to fix body while waiting to completely redo the interface
        // (Try to fix bug of difficulty to add a signature when multiple modals are opened)
        document.body.classList.add("modal-fixed");
        this.setState({
            modal: (
                <MaintenancesModal
                    equipment={equipment}
                    patient={patient}
                    closeModal={() => this.closeModal()}
                />
            )
        });
    }

    render() {
        const { equipments } = this.props;

        const EquipmentsNode = equipments.map((e) => {
            const renewalDate = this.renewalDate(e);

            const menuItems = [];

            menuItems.push({
                id: null,
                icon: "pen-to-square",
                disabled: false,
                action: () => this.openModal(e.patient, e),
                text: <FormattedMessage id="Modify" />
            });

            menuItems.push({
                id: null,
                icon: "wrench",
                disabled: !(e.auditEquipment && e.auditEquipment.checkpoints && e.auditEquipment.checkpoints.length),
                action: () => this.openMaintenancesModal(e, e.patient),
                text: < FormattedMessage id="Maintenance" />
            });

            menuItems.push({
                id: null,
                icon: "list",
                // Allow maintenance only if DM has checkpoints
                disabled: !(e.auditEquipment),
                action: () => this.openInterventions(e.patient, e),
                text: < FormattedMessage id="Intervention" />
            });

            menuItems.push({
                id: null,
                icon: "trash",
                disabled: false,
                action: () => this.delete(e.patient, e),
                text: <FormattedMessage id="Delete" />
            });

            const menuAction = <ActionMenu variant={this.renewalDateAlert(renewalDate)} items={menuItems} />;

            return (
                <tr key={"equipment-" + e._id} className={"order-tr order-tr"} >
                    <td className="d-md-table-cell mw-200 align-middle">{(e.patient) && (`${e.patient.name.toUpperCase()} ${e.patient.first_name}`)}</td>
                    <td className="d-md-table-cell mw-200 align-middle">{(e.auditEquipment) && e.auditEquipment.name}</td>
                    <td className="d-md-table-cell mw-200 align-middle">{this.props.intl.formatMessage({ id: "EqSellMode." + e.mode })}</td>
                    <td className="d-none d-md-table-cell mw-200 align-middle">{DateUtil.DateTime().fromISO(e.last_prescription_date).isValid ? DateUtil.DateTime().fromISO(e.last_prescription_date).toLocaleString() : <FormattedMessage id={"None.Feminine"} />}</td>
                    <td className="d-none d-md-table-cell mw-200 align-middle">{DateUtil.DateTime().fromISO(e.first_install_date).isValid ? DateUtil.DateTime().fromISO(e.first_install_date).toLocaleString() : <FormattedMessage id={"None.Feminine"} />}</td>
                    <td className="d-none d-md-table-cell mw-200 align-middle">
                        <Alert variant={this.renewalDateAlert(renewalDate)}>{DateUtil.DateTime().fromISO(renewalDate).toLocaleString()}</Alert>
                    </td>
                    <td className="col-1">
                        {menuAction}
                    </td>
                </tr>
            );
        });
        

        return (
            <Modal
                show={true}
                onHide={() => this.props.close()}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="Equipments" /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {(equipments && equipments.length > 0) &&
                        <table className="table tablee4mad mt-3">
                            <thead>
                                <tr className="tablerowhead">
                                    <th className="d-md-table-cell"><FormattedMessage id="Patient" /></th>
                                    <th className="d-md-table-cell"><FormattedMessage id="Equipment" /></th>
                                    <th className="d-md-table-cell"><FormattedMessage id="Mode" /></th>
                                    <th className="d-none d-md-table-cell"><FormattedMessage id="Last.Prescription.Date" /></th>
                                    <th className="d-none d-md-table-cell"><FormattedMessage id="First.Install.Date" /></th>
                                    <th className="d-none d-md-table-cell"><FormattedMessage id="Next.Renewal.Date" /></th>
                                    <th className="d-none d-md-table-cell"><FormattedMessage id="Actions" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {EquipmentsNode}
                            </tbody>
                        </table>
                    }

                    {this.state.modal}
                </Modal.Body>
            </Modal>
        );
    }

}

const mapStateToProps = state => {
    return {
        //
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdPatientAdmin: (patient, successCallback) => dispatch(updPatientAdmin(patient, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientsEquipmentsRemindedModal));
