import APIUrl from "../../APIUrl";
import axios from 'axios';

export const contact = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.contact, data)
            .then(function(response){
                return successCallback()
            })
            .catch(function(err){
                throw err;
            });
    };
};
