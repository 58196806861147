import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PrescriberTypeModalAdmin from './PrescriberTypeModalAdmin';
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { deletePrescriberType, updatePrescriberType } from "../../../../actions/settings/prescriberType/admin/prescriberType";
import ActionMenu from "../../../sub/ActionMenu";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";

class PrescriberTypes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
        };
    }

    openPrescriberTypeModal() {
        this.setState({
            modal: <PrescriberTypeModalAdmin
                isOpen={true}
                closeModal={() => this.closeModal()}
            />
        });
    }

    openEditModal(prescriberType) {
        this.setState({
            modal: <PrescriberTypeModalAdmin
                isOpen={true}
                closeModal={() => this.closeModal()}
                prescriberType={prescriberType}
            />
        });
    }

    delete(_id, name) {
        var title = <FormattedMessage id="Confirm" />;
        var content = <FormattedMessage id="Client.Type.Remove.Confirmation" values={{ name: name }} />;

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={title}
                content={content}
                successCallback={() => this.props.onDeletePrescriberType(_id)}
                closeModal={() => this.closeModal()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    render() {

        let prescriberTypesRow = this.props.prescriberTypes.map((t, i) => {

            let menuItems = [];

            menuItems.push(
                {
                    icon: "pen-to-square",
                    action: () => this.openEditModal(t),
                    text: <FormattedMessage id="Modify" />,
                },
                {
                    icon: "trash",
                    action: () => this.delete(t._id, t.name),
                    text: <FormattedMessage id="Delete" />,
                },
            );

            return <tr key={i}>
                <td>{t.name}</td>
                <td className="col-2 tdaction text-center">
                    <ActionMenu
                        items={menuItems}
                    />
                </td>
            </tr>
        });

        return (
            <React.Fragment>
                <TableToolbar>
                    <button
                        className="btn btn-info ml-auto"
                        onClick={(e) => this.openPrescriberTypeModal()}>
                        <FormattedMessage id="Add.Client.Type" />
                    </button>
                </TableToolbar>

                {(!this.props.prescriberTypes || this.props.prescriberTypes.length === 0) &&
                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id="Empty.Client.Types" />
                    </div>
                }

                {(this.props.prescriberTypes && this.props.prescriberTypes.length !== 0) &&
                    <table className="table tablee4mad mt-3 col-md-8 col-lg-6">
                        <thead>
                            <tr className="tablerowhead">
                                <th className="d-md-table-cell"><FormattedMessage id="Client.Type" /> </th>
                                <th className="text-center"><FormattedMessage id="Actions" /> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {prescriberTypesRow}
                        </tbody>
                    </table>
                }

                {this.state.modal}

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        prescriberTypes: state.prescriberTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDeletePrescriberType: (_id) => dispatch(deletePrescriberType(_id)),
        onUpdatePrescriberType: (prescriberTypes) => dispatch(updatePrescriberType(prescriberTypes))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriberTypes);
