import React, { useEffect, useState } from "react";
import { Container, Row, Col, ListGroup, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import Icon from "../sub/Icon";
import axios from "axios";
import APIUrl from "../../APIUrl";
import { FormattedMessage } from "react-intl";
import DateUtil from "../../util/DateUtil";
import ActionMenu from "../sub/ActionMenu";
import MenuButton from "../sub/bootstrap/MenuButton";

export default function FileExplorer({
  data,
  appScope,
  openFolderModal,
  openFileModal,
  deleteFolder,
  deleteFile,
  indent = 0,
  isAdmin,
  isPrescriber,
  isPatient,
  isUpload,
  setParentState,
}) {
  const MAX_CHAR = 16;

  const [expandedFolders, setExpandedFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState(data ? data[0] : null);
  const [childFolders, setChildFolders] = useState([]);
  const [childFiles, setChildFiles] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selected, setSelected] = useState(false);
  const [previousFolder, setPreviousFolder] = useState([]);

  const accessValidation = (item) => {
    return ((item.app_scope.e4bizz || item.app_scope[appScope]) && ((isAdmin && !isUpload) || (item.user_scope.prescriber && isPrescriber) || (item.user_scope.patient && isPatient)))
  };

  useEffect(() => {
    if (activeFolder === null) return;
    setSelectedItem(null);
    axios
      .get(`${APIUrl.getDocumentsDbCategoriesDescendants}${activeFolder._id}`)
      .then((response) => {
        setChildFolders(response.data.children);
        // Make root folder already open on init
        if (activeFolder.parent === null) {
          setExpandedFolders([activeFolder._id]);
        }
      });

    axios
      .post(APIUrl.getDocumentsDbFilesByCategoryId, {
        category_id: activeFolder._id,
      })
      .then((response) => {
        setChildFiles(response.data);
      });
  }, [data, activeFolder]);

  const handleFolderClick = (folder) => {
    setActiveFolder(folder);
  };

  const toggleFolder = (folderId, parentId) => {
    const previousOpenedFolder = expandedFolders[expandedFolders.length - 1];

    if (previousOpenedFolder !== parentId) {
      const sliceIndex = expandedFolders.indexOf(parentId);
      const slicedFolderList = expandedFolders.slice(0, sliceIndex + 1);
      setExpandedFolders([...slicedFolderList, folderId]);
    }
    else {
      setExpandedFolders([...expandedFolders, folderId]);
    }
  };

  const toggleSelected = (current) => {
    setSelected(current !== selectedItem ? false : !selected);
  };

  const isFolderExpanded = (folderId) => {
    return expandedFolders.includes(folderId);
  };

  const onFolderClick = (folder) => {
    addPreviousFolderToStack(activeFolder);
    toggleFolder(folder._id, folder.parent);
    handleFolderClick(folder);
  };

  const addPreviousFolderToStack = (activeFolder) => {
    let previousFolderList = previousFolder;
    previousFolderList.push(activeFolder);
    setPreviousFolder(previousFolderList);
  }

  const openPreviousFolder = () => {
    let previousFolderList = previousFolder;
    setActiveFolder(previousFolderList[previousFolderList.length - 1]);
    previousFolderList.pop();
    setPreviousFolder(previousFolderList);
  }

  const renderFileSystem = (data, indent) => {
    return (
      <ListGroup
        variant="flush"
        className={`file-manager-list indent-${indent}`}
      >
        {data.filter((item) => accessValidation(item)).map((item) => (
          <ListGroup.Item key={item._id} className={`file-manager-item-folder`}>
            <div
              className={`item-content${activeFolder && activeFolder._id === item._id ? "-active" : ""
                }`}
              onClick={() => {
                onFolderClick(item);
              }}
            >
              <div className="folder-icon">
                <Icon
                  icon={
                    isFolderExpanded(item._id) ? "folder-open" : "folder-closed"
                  }
                />{" "}
                {item.name}
              </div>
            </div>
            {isFolderExpanded(item._id) && (
              <div className="item-children">
                {renderFileSystem(item.children, indent + 1)}
              </div>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const getFileIcon = (fileExtension) => {
    switch (true) {
      case fileExtension === "pdf":
        return "file-pdf";
      case fileExtension === "docx":
        return "file-word";
      case fileExtension === "png" || fileExtension === "jpg":
        return "image";
      default:
        return "file";
    }
  };

  const renderItem = (item, itemName, iconName, onClick, onDoubleClick) => (
    <small
      key={item._id}
    >
      {itemName.length > MAX_CHAR && <OverlayTrigger
        delay={{ hide: 0, show: 0 }}
        overlay={(props) => <Tooltip {...props}>{itemName}</Tooltip>}
        placement="top"
      >
        <div
          className={`text-center p-3 mr-3 ml-3 mt-3 mb-1 main-file-content-item${selectedItem && (selectedItem._id === item._id) && !selected ? "-active" : ""}`}
          onClick={onClick}
          onDoubleClick={(isUpload && iconName === "folder") ? onDoubleClick : () => { }}
        >
          <Icon id="main-file-icon" icon={iconName} />
          <div id={`item-${item._id}`} className="text-truncate">
            {itemName}
          </div>
          <small>{DateUtil.toDate(item.updatedAt)}</small>
        </div>
      </OverlayTrigger>}

      {itemName.length <= MAX_CHAR && <div
        className={`text-center p-3 mr-3 ml-3 mt-3 mb-1 main-file-content-item${selectedItem && (selectedItem._id === item._id) && !selected ? "-active" : ""}`}
        onClick={onClick}
        onDoubleClick={(isUpload && iconName === "folder") ? onDoubleClick : () => { }}
      >
        <Icon id="main-file-icon" icon={iconName} />
        <div id={`item-${item._id}`} className="text-truncate">
          {itemName}
        </div>
        <small>{DateUtil.toDate(item.updatedAt)}</small>
      </div>}

      {(selectedItem && (selectedItem._id === item._id) && !selected) &&
        <Row>
          <Col className="text-center">
            {(isUpload && iconName !== "folder") && <MenuButton
              hover={<FormattedMessage id="Select" />}
              placement="bottom"
              icon="check"
              size="sm"
              className="mr-1"
              onClick={onDoubleClick}
            />}
            {(!isUpload || (isUpload && iconName !== "folder")) && <MenuButton
              hover={<FormattedMessage id={iconName === "folder" ? "Display.Content" : "Display"} />}
              placement="bottom"
              icon={iconName === "folder" ? "folder-open" : "eye"}
              size="sm"
              onClick={iconName !== "folder" ? () => window.open(fileUrl(item), "_blank") : onDoubleClick}
            />}
            {!isUpload && <MenuButton
              hover={<FormattedMessage id={iconName === "folder" ? "Rename" : "Replace"} />}
              placement="bottom"
              className="ml-1 mr-1"
              icon={iconName === "folder" ? "pen" : "right-left"}
              size="sm"
              onClick={() =>
                selectedItem.file_name
                  ? openFileModal(activeFolder, selectedItem)
                  : openFolderModal(activeFolder, selectedItem)
              }
            />}
            {!isUpload && <MenuButton
              hover={<FormattedMessage id="Delete" />}
              placement="bottom"
              icon="trash"
              size="sm"
              onClick={() =>
                selectedItem.file_name
                  ? deleteFile(selectedItem)
                  : deleteFolder(selectedItem)
              }
            />}
          </Col>
        </Row>
      }
    </small>
  );

  const fileUrl = (file) => APIUrl.getDocumentsDbFile + file._id + "/?token=" + APIUrl.jwtToken;

  const updateParentState = (file) => {
    file.url = fileUrl(file).split("?token=")[0];
    setParentState(file);
  }

  const renderActionMenu = (activeFolder) => {

    let menuAction;
    let menuItems = [];

    menuItems.push({
      icon: "file-circle-plus",
      action: () => openFileModal(activeFolder),
      text: <FormattedMessage id="Upload.File" />,
    });

    menuItems.push({
      icon: "folder-plus",
      action: () => openFolderModal(activeFolder),
      text: <FormattedMessage id="New.Folder" />,
    });

    menuAction = <ActionMenu
      items={menuItems}
      icon="circle-plus"
      className="ml-1"
      size="2xl"
    />;

    return menuAction;
  }

  return (
    <Container fluid>
      <Row>
        <Col sm={3} className="sidebar p-0">
          <div className="sidebar-content">
            {renderFileSystem(data, indent)}
          </div>
        </Col>
        <Col sm={9} className="main-content pt-3">
          <Card bg="light">
            <Card.Body className="p-2">
              <Row className="d-flex align-items-center">
                <Col>
                  <strong>
                    {activeFolder.name}
                  </strong>
                </Col>
                <Col>
                  <div className="form-inline">
                    <MenuButton className="ml-auto" icon="arrow-left" variant="info" disabled={activeFolder.parent === null && true} hover={activeFolder.parent !== null && <FormattedMessage id="Previous" />} onClick={() => openPreviousFolder()} />
                    {(isAdmin && !isUpload) && renderActionMenu(activeFolder)}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="main-file-content d-flex flex-wrap">
            {childFolders &&
              childFolders.filter((item) => accessValidation(item)).map((folder) =>
                renderItem(
                  folder,
                  folder.name,
                  "folder",
                  () => {
                    !isUpload && toggleSelected(folder);
                    setSelectedItem(folder);
                  },
                  () => onFolderClick(folder)
                )
              )}

            {childFiles &&
              childFiles.filter((item) => accessValidation(item)).map((file) =>
                renderItem(
                  file,
                  file.file_name,
                  getFileIcon(file.file_extension),
                  () => {
                    !isUpload && toggleSelected(file);
                    setSelectedItem(file);
                  },
                  () => {
                    isUpload && updateParentState(file);
                  }
                )
              )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
