import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import TextareaCounter from 'react-textarea-counter';
import CustomLabel from "../sub/CustomLabel";

class CartConfModalAdmin extends React.Component {

    close() {
        this.props.closeModal();
    }

    confirm() {
        this.props.successCallback();
        this.close();
    }

    render() {
        let clientsNode = this.props.clients.map(client => {
            return <option key={client._id} value={client._id}>{client.username}</option>
        });

        return (
            <Modal
                show={this.props.isOpen}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="xl"
            >

                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="Order.Confirm" /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Choose.Client" } + ":")}
                        htmlFor="order-clientId"
                        labelClassName="form-control col-12 no-border bg-light" />
                    <select id="order-clientId" className="form-control mb-3 w-100">
                        {clientsNode}
                    </select>
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Comment" } + ":")}
                        htmlFor="comment"
                        labelClassName="form-control col-12 no-border bg-light" />
                    <div>
                        <TextareaCounter countLimit={1000} className="textareaCounter" id="comment" name="comment" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.close()}><FormattedMessage id="Cancel" /></Button>
                    <Button variant="btn btn-info" onClick={() => this.confirm()}><FormattedMessage id="Confirm" /></Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        clients: state.clients,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CartConfModalAdmin)
);
