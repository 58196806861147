import { GET_DESK_AUDIT } from "../../actions/deskaudit/deskaudit";

export default function audits(state = [], action) {
  switch (action.type) {
    case GET_DESK_AUDIT:
      return action.deskaudits;
    default:
      return state;
  }
}
