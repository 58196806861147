import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Roles from '../../enums/Roles';
import NotificationSubject from '../../enums/NotificationSubject';

import NotificationModal from '../../components/sub/modals/NotificationModal.js';
import RgpdModal from "../rgpd/RgpdModal";
import MenuMAD from './Menus/MenuMAD';
import SwitchComponent from './SwitchComponent';

import { getUser } from '../../actions/user/user';
import { getRgpd } from '../../actions/rgpd/rgpd';
import { getCart } from '../../actions/cart/cart';
import { getOrders, getOrdersAdmin } from '../../actions/orders/orders';
import { getClients, getClientsAdmin } from '../../actions/clients/clients';
import { getOrdersSettings } from '../../actions/settings/ordersSettings/ordersSettings';
import { getOrdersSettingsAdmin } from "../../actions/settings/ordersSettings/admin/ordersSettings";
import { getAuditRooms } from "../../actions/settings/auditRooms/admin/auditRooms";
import { getDeskCategories } from "../../actions/settings/deskCategories/admin/deskCategories";
import { getDeskProducts } from "../../actions/settings/deskCategories/admin/deskProducts";

import { getPromoOperations, getPromoOperationsAdmin } from "../../actions/promoOperations/promoOperations";
import { getAuditDMs } from "../../actions/settings/auditDM/admin/auditDM";
import { getRequests, getAllRequests } from '../../actions/requests/requests';
import { getNotifs, deleteNotif } from '../../actions/notifications/notifications';
import { getMercurialsAdmin, getMercurial } from "../../actions/mercurials/mercurials";
import { getCheckpoints } from "../../actions/checkpoints/checkpoints";
import { getCollaboratorTypes } from '../../actions/configurables/collaboratorTypes';
import { getGeneralSettings } from '../../actions/settings/generalSettings/generalSettings';
import { getCollaborators } from '../../actions/collaborators/collaborators';
import { getAdmin } from '../../actions/admin/admin';
import { getPatientsAdmin } from '../../actions/patients/patients';
import { getPatients } from '../../actions/patients/patients';
import { getPrescriberTypes } from "../../actions/settings/prescriberType/admin/prescriberType";
import { getAuditSettings } from '../../actions/settings/auditSettings/auditSettings';
import { getAuditDeskSettings } from "../../actions/settings/auditSettings/auditDeskSettings";

import { getAudits, getAuditsAdmin } from "../../actions/audits/audits";
import { getDeskAudits, getDeskAuditsAdmin } from "../../actions/deskaudit/deskaudit";
import { getCompany } from '../../actions/company/company';
import { getMaintenances } from "../../actions/maintenances/maintenances";
import { getInterventions, getInterventionsAdmin } from "../../actions/interventions/Interventions";

import { NotificationContainer } from 'react-notifications';

class Homepage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rgpdModal: null
        };
    }

    closeRgpdModal() {
        this.setState({ rgpdModal: null });
    }

    componentDidMount() {
        Promise.all([
            // First, fetch the user
            this.props.onGetRgpd(),
            this.props.onGetUser(),
        ]).then(() => {
            // Next things to fetch
            if (!this.props.rgpd) this.setState({ rgpdModal: <RgpdModal closeRgpdModal={() => this.closeRgpdModal()} /> });

            this.props.onGetCart();
            this.props.onGetNotifs();
            this.props.onGetCheckpoints();
            this.props.onGetCollaboratorTypes();
            this.props.onGetGeneralSettings();
            this.props.onGetCompany();
            this.props.onGetAuditDMs();
            this.props.onGetMaintenances();

            // Client specific
            if (this.props.user.role === Roles.CLIENT) {
                this.props.onGetOrders();
                this.props.onGetOrdersSettings();
                this.props.onGetRequests();
                this.props.onGetMercurial();
                this.props.onGetAdmin();
                this.props.onGetClients();
                this.props.onGetPatients();
                this.props.onGetAudits();
                this.props.onGetDeskAudits();
                this.props.onGetPromoOperations();
                this.props.onGetInterventions();
            }

            // Admin & Sales rep
            if (this.props.user.role === Roles.SALES_REP || this.props.user.role === Roles.ADMIN) {
                this.props.onGetAuditRooms();
                this.props.onGetOrdersAdmin();
                this.props.onGetClientsAdmin();
                this.props.onGetMercurials();
                this.props.onGetAllRequests();
                this.props.onGetOrdersSettingsAdmin();
                this.props.onGetPatientsAdmin();
                this.props.onGetPrescriberTypes();
                this.props.onGetAuditSettings();
                this.props.onGetAuditsAdmin();
                this.props.onGetDeskAuditsAdmin();
                this.props.onGetInterventionsAdmin();
                this.props.onGetDeskCategories();
                this.props.onGetDeskProducts();
                this.props.onGetAuditDeskSettings();
                this.props.onGetCollaborators();
            }

            // Admin specific
            if (this.props.user.role === Roles.ADMIN) {
                this.props.onGetPromoOperationsAdmin();
            }
        });
    }

    closeNotifModal(notif) {
        this.props.onDeleteNotif({ notifId: notif._id });
    }

    render() {
        const { user, notifications } = this.props;

        let notifModals = [];
        if (notifications.length !== 0) {
            for (let notif of notifications) {
                if (notif.subject === NotificationSubject.MERCURIAL_DELETE_OR_UPDATE) {
                    let title = <FormattedMessage id="Notification.Mercurial.Upd.Title" />;
                    let content = <FormattedMessage id="Notification.Mercurial.Upd.Content" />;
                    notifModals.push(<NotificationModal
                        key={notif._id}
                        title={title}
                        content={content}
                        successCallback={() => this.closeNotifModal(notif)}
                        closeModal={() => this.closeNotifModal(notif)} />
                    );
                }
            }
        }

        return (
            <React.Fragment>
                <MenuMAD
                    userRole={user.role}
                />
                <div className="container-fluid p-3">
                    <SwitchComponent
                        userRole={user.role}
                    />
                </div>
                {notifModals}

                <NotificationContainer />

                {this.state.rgpdModal}
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        rgpd: state.rgpd,
        user: state.user,
        notifications: state.notifications,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetUser: () => dispatch(getUser()),
        onGetRgpd: () => dispatch(getRgpd()),
        onGetCart: () => dispatch(getCart()),
        onGetOrders: () => dispatch(getOrders()),
        onGetNotifs: () => dispatch(getNotifs()),
        onDeleteNotif: (data) => dispatch(deleteNotif(data)),
        onGetOrdersSettings: () => dispatch(getOrdersSettings()),
        onGetRequests: () => dispatch(getRequests()),
        onGetMercurial: () => dispatch(getMercurial()),
        onGetCollaboratorTypes: () => dispatch(getCollaboratorTypes()),
        onGetCheckpoints: () => dispatch(getCheckpoints()),
        onGetAdmin: () => dispatch(getAdmin()),
        onGetGeneralSettings: () => dispatch(getGeneralSettings()),
        onGetPatients: () => dispatch(getPatients()),
        onGetAudits: () => dispatch(getAudits()),
        onGetDeskAudits: () => dispatch(getDeskAudits()),
        onGetCompany: () => dispatch(getCompany()),
        onGetPromoOperations: () => dispatch(getPromoOperations()),
        onGetAuditDMs: () => dispatch(getAuditDMs()),
        onGetMaintenances: () => dispatch(getMaintenances()),
        onGetInterventions: () => dispatch(getInterventions()),
        onGetClients: () => dispatch(getClients()),

        // ADMIN & SALES_REP
        onGetAuditRooms: () => dispatch(getAuditRooms()),
        onGetOrdersAdmin: () => dispatch(getOrdersAdmin()),
        onGetClientsAdmin: () => dispatch(getClientsAdmin()),
        onGetMercurials: () => dispatch(getMercurialsAdmin()),
        onGetAllRequests: () => dispatch(getAllRequests()),
        onGetPatientsAdmin: () => dispatch(getPatientsAdmin()),
        onGetPrescriberTypes: () => dispatch(getPrescriberTypes()),
        onGetAuditSettings: () => dispatch(getAuditSettings()),
        onGetAuditsAdmin: () => dispatch(getAuditsAdmin()),
        onGetDeskAuditsAdmin: () => dispatch(getDeskAuditsAdmin()),
        onGetInterventionsAdmin: () => dispatch(getInterventionsAdmin()),
        onGetDeskCategories: () => dispatch(getDeskCategories()),
        onGetDeskProducts: () => dispatch(getDeskProducts()),
        onGetAuditDeskSettings: () => dispatch(getAuditDeskSettings()),

        // ADMIN only
        onGetOrdersSettingsAdmin: () => dispatch(getOrdersSettingsAdmin()),
        onGetCollaborators: () => dispatch(getCollaborators()),
        onGetPromoOperationsAdmin: () => dispatch(getPromoOperationsAdmin())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
