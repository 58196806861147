import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ComboBox from "../../../sub/ComboBox";
import { addCollaboratorType } from "../../../../actions/configurables/collaboratorTypes";
import Util from "../../../../util/Util";
import {
    addCollaborator,
    updateCollaborator
} from "../../../../actions/collaborators/collaborators";
import {
    checkIfUsernameExists,
    checkIfEmailExists,
    checkIfEmailIsValid
} from "../../../../actions/user/user";
import CustomLabel from "../../../sub/CustomLabel";

class Collaborator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            name: "",
            first_name: "",
            email: "",
            collaborator_type_id: "",
            phone: "",
            // substitute_id: "",
            // on_leave: false,

            usernameError: null,
            emailError: null,
            disabled: false,
        }

        if (this.isUpdate()) {
            this.state = {
                username: this.props.collaborator.username,
                name: this.props.collaborator.name,
                first_name: this.props.collaborator.first_name,
                email: this.props.collaborator.email,
                collaborator_type_id: this.props.collaborator.collaborator_type_id || "",
                phone: this.props.collaborator.phone || "",
                usernameError: null,
                emailError: null,
                disabled: false,
            }
        }
    }

    isUpdate() {
        return (this.props.collaborator !== undefined && this.props.collaborator !== null);
    }

    close() {
        this.props.close();
    }

    stopEvent(e) {
        e.stopPropagation();
    }

    onChange(field, value) {
        this.setState({ [field]: value });


        if (field === "email") {
            if (Util.emptyString(value)) return;

            if (this.isUpdate() && this.props.collaborator.email === value) {
                this.setState({ emailError: null })
                return;
            }

            if (!Util.isEmail(value)) {
                this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
                return;
            } else {
                this.props.onCheckIfEmailIsValid(
                    value,
                    (response) => {
                        // console.log("data", response.data.message);
                        this.setState({ emailError: null });
                    },
                    (response) => {
                        // console.log("data", response);
                        this.setState({ emailError: <span><FormattedMessage id="Invalid.Email.Error" /> ({response.data.reason})</span> });
                        return;
                    }
                );
            }
        }

        if (field === "username") {
            if (Util.emptyString(value)) return;

            // Force this value to be in lowercase
            value = value.toLowerCase();

            this.setState({ [field]: value });

            // Test username syntax
            var checkUsername = Util.isValidUserName(value, 3, 20);
            if (checkUsername instanceof Object === true) {
                this.setState({ usernameError: <FormattedMessage id="Invalid.Username" values={{ minLength: checkUsername.minLength, maxLength: checkUsername.maxLength }} /> })
                return;
            } else {
                this.setState({ usernameError: null })
            }

            if (this.isUpdate() && this.props.collaborator.username === value) {
                this.setState({ usernameError: null })
                return;
            }

            this.props.onCheckIfUsernameExists(
                value,
                () => { this.setState({ usernameError: <FormattedMessage id="Username.Already.Exists" values={{ username: value }} /> }) },
                () => { this.setState({ usernameError: null }) },
            );
        }
    }

    update(field) {
        if (this.disabled() || !this.isUpdate()) return;

        if (this.isUpdate() && field === "email" && this.props.collaborator.email === this.state[field]) return;

        if (this.isUpdate() && field === "username" && this.props.collaborator.username === this.state[field]) return;

        this.props.onUpdateCollaborator({ collaboratorId: this.props.collaborator._id, updatedField: field, updatedValue: this.state[field] });
    }

    disabled() {
        return Util.emptyString(this.state.username) ||
            Util.emptyString(this.state.name) ||
            Util.emptyString(this.state.first_name) ||
            Util.emptyString(this.state.email) ||
            !Util.isEmail(this.state.email) ||
            this.state.emailError ||
            this.state.usernameError ||
            this.state.disabled;
    }

    onSubmit() {
        if (this.disabled()) return;

        // Prepare data to send to the BE, exclude irrelevant fields
        var { disabled, ...data } = this.state;

        // Remove empty values
        Object.keys(data).forEach(key => Util.emptyString(data[key]) && delete data[key]);

        // Lock the button
        this.setState({ disabled: true });

        // Send to BE
        this.props.onAddCollaborator(data, (collaborator) => this.props.onCollaboratorCreated(collaborator));
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-12 col-lg-9 col-xl-8">

                    <div className="form-group row">

                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Username" })}
                            htmlFor="username"
                            labelClassName="col-12 col-md-5 col-form-label"
                            required={true}
                        />
                        <div className="col-12 col-md-7">
                            <input type="text" className="form-control col-12 col-md-7 d-inline" id="username"
                                value={this.state.username}
                                onChange={(e) => this.onChange('username', e.target.value)}
                                onBlur={(e) => this.update('username', e.target.value)} />
                            <span className="col-12 col-md-3">-{this.props.company.url}</span>
                            <div className="text-danger"><small>{this.state.usernameError}</small></div>
                        </div>

                    </div>

                    <div className="form-group row">

                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Name" })}
                            htmlFor="name"
                            labelClassName="col-12 col-md-5 col-form-label"
                            required={true}
                        />
                        <div className="col-12 col-md-7">
                            <input type="text" className="form-control text-uppercase" id="name"
                                value={this.state.name}
                                onChange={(e) => this.onChange('name', e.target.value.toUpperCase())}
                                onBlur={(e) => this.update('name', e.target.value)} />
                        </div>

                    </div>

                    <div className="form-group row">

                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "First.Name" })}
                            htmlFor="first_name"
                            labelClassName="col-12 col-md-5 col-form-label"
                            required={true}
                        />
                        <div className="col-12 col-md-7">
                            <input type="text" className="form-control text-capitalize" id="first_name"
                                value={this.state.first_name}
                                onChange={(e) => this.onChange('first_name', e.target.value.toLowerCase())}
                                onBlur={(e) => this.update('first_name', e.target.value)} />
                        </div>

                    </div>

                    <div className="form-group row">

                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Email" })}
                            htmlFor="email"
                            labelClassName="col-12 col-md-5 col-form-label"
                            required={true}
                        />
                        <div className="col-12 col-md-7">
                            <input type="text" className="form-control" id="email"
                                value={this.state.email}
                                onChange={(e) => this.onChange('email', e.target.value)}
                                onBlur={(e) => this.update('email', e.target.value)} />
                            <div className="text-danger"><small>{this.state.emailError}</small></div>
                        </div>

                    </div>

                    <div className="form-group row">

                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Function" })}
                            htmlFor="collaborator_type_id"
                            labelClassName="col-12 col-md-5 col-form-label"
                        />
                        <div className="col-12 col-md-7">
                            <ComboBox
                                menuPlacement="auto"
                                onChange={(value) => this.onChange("collaborator_type_id", value)}
                                onBlur={(value) => this.update("collaborator_type_id", value)}
                                defaultOption={this.state.collaborator_type_id}
                                options={this.props.collaboratorTypes}
                                onCreateOption={(data, successCallback) => this.props.onAddCollaboratorType(data, successCallback)} />
                        </div>

                    </div>

                    <div className="form-group row">

                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Phone" })}
                            htmlFor="phone"
                            labelClassName="col-12 col-md-5 col-form-label"
                        />
                        <div className="col-12 col-md-7">
                            <input type="text" className="form-control" id="phone"
                                value={this.state.phone}
                                onChange={(e) => this.onChange('phone', e.target.value)}
                                onBlur={(e) => this.update('phone', e.target.value)} />
                        </div>

                    </div>

                </div>

                {!(this.isUpdate()) &&
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <button className="btn btn-info btn-block" disabled={this.disabled()} onClick={() => this.onSubmit()}>
                            <FormattedMessage id="Add" />
                        </button>
                    </div>
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        collaborators: state.collaborators,
        collaboratorTypes: state.collaboratorTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddCollaboratorType: (data, successCallback) => dispatch(addCollaboratorType(data, successCallback)),
        onAddCollaborator: (data, successCallback) => dispatch(addCollaborator(data, successCallback)),
        onUpdateCollaborator: (data) => dispatch(updateCollaborator(data)),
        onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) => dispatch(checkIfUsernameExists(username, existsCallback, noExistsCallback)),
        onCheckIfEmailExists: (email, existsCallback, noExistsCallback) => dispatch(checkIfEmailExists(email, existsCallback, noExistsCallback)),
        onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) => dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Collaborator));
