import { GET_ORDERS_SETTINGS } from "../../../actions/settings/ordersSettings/ordersSettings";

export default function ordersSettings(state = [], action) {

    switch (action.type) {
        case GET_ORDERS_SETTINGS:
            return action.ordersSettings;
        default:
            return state;
    }

};
