import APIUrl from "../../APIUrl";
import axios from 'axios';

export const GET_ADMIN = "GET_ADMIN";

function getAdminAction(admin){return {type: GET_ADMIN, admin: admin}};

export const getAdmin = function(successCallback){
    return function(dispatch){
        return axios.get(APIUrl.getAdmin)
            .then(function(response){
                dispatch(getAdminAction(response.data));
            })
            .catch(function(err){
                throw err
            });
    };
};
