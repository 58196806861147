import { GET_DESK_PRODUCTS } from "../../../actions/settings/deskCategories/admin/deskProducts";

export default function deskProducts(state = [], action) {
  switch (action.type) {
    case GET_DESK_PRODUCTS:
      return action.deskProducts;
    default:
      return state;
  }
}
