import React from 'react';
import PaginationComponent from "react-js-pagination";
import Icon from "./Icon.js";
export default class Pagination {

    constructor(parent) {
        this.parent = parent;
        this.nbrElems = 0;
        this.paginationIndex = 1;
        this.paginationElems = 5;
        this.pageRangeDisplayed = 5;
    }

    keep() {
        if (this.nbrElems < ((this.paginationIndex - 1) * this.paginationElems)
            || this.nbrElems >= (this.paginationIndex * this.paginationElems)) {
            // Increment
            this.nbrElems++;

            return false;
        }

        // Increment
        this.nbrElems++;

        return true;
    }

    init() {
        this.nbrElems = 0;
    }

    updateIndex(i) {
        this.paginationIndex = i;
        this.nbrElems = 0;

        this.parent.setState({});
    }

    handlePageChange(pageNumber) {
        this.updateIndex(pageNumber);
        // console.log(`active page is ${pageNumber}`);
        this.paginationIndex = pageNumber;
    }

    render() {
        if (this.nbrElems < this.paginationElems) return null;

        return (
            <div className="pagination justify-content-center">
                <PaginationComponent
                    activePage={this.paginationIndex}
                    itemsCountPerPage={this.paginationElems}
                    totalItemsCount={this.nbrElems}
                    pageRangeDisplayed={this.pageRangeDisplayed}
                    onChange={this.handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link text-info"
                    activeLinkClass="bg-info paginator-active-page text-info-active"
                    firstPageText={<><Icon icon="caret-left" fw /><Icon icon="caret-left" fw /></>}
                    prevPageText={<Icon icon="caret-left" fw />}
                    nextPageText={<Icon icon="caret-right" fw />}
                    lastPageText={<><Icon icon="caret-right" fw /><Icon icon="caret-right" fw /></>}
                />
            </div>
        );
    }

}