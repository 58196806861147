import { addLocaleData } from 'react-intl';
import {GET_USER} from "../../actions/user/user";
import {enMessages, frMessages} from '../../i18n';
import frLocaleData from 'react-intl/locale-data/fr';

// I18n config. By default, as long as we don't have the user's preferences, check the browser lang and stick to it
addLocaleData(frLocaleData);
let language = navigator.language || navigator.userLanguage || navigator.languages[0];
if(language === undefined || language === null) language = "en_EN";
let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

export default function i18n(state={
    lang: languageWithoutRegionCode,
    messages: findTradFor(languageWithoutRegionCode),
}, action){
    switch (action.type) {
        // We have received the user. Update the lang accordingly!
        case GET_USER:
          return Object.assign({}, state, {lang: action.user.lang, messages: findTradFor(action.user.lang)});
        default:
          return state;
    }
}

function findTradFor(lang){
    switch(lang){
      case 'fr': return frMessages;
      case 'en': return enMessages;
      default: return enMessages;
    }
  }