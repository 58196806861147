import React from 'react';

import "../css/notfound.css";

export default class NotFound extends React.Component{

    render() {
        return (
            <div className="not-found">404 Not Found</div>
        );
    }
}