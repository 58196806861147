import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Util from "../../../util/Util";
import DateUtil from "../../../util/DateUtil";
import { Button, Modal } from 'react-bootstrap';
import { updClient } from "../../../actions/clients/clients";
import { CrmProviders } from "../../../enums/CrmProviders";

class FindCliModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            apiData: this.props.apiData || "",
            crmId: this.props.crmId || "",
            linkedAccount: this.props.linkedAccount || false,
            disabled: false,
        };

    }

    close() {
        this.props.closeModal();
    }

    updateLink(crmId) {
        this.setState({ disabled: true });

        let callback = () => { this.props.onComplete(crmId); }

        // client already exists, update field value
        if (Util.getNested(this.props.client, '_id')) {
            this.props.onUpdatePrescriber({
                _id: this.props.client._id,
                updatedField: 'crm_id',
                updatedValue: crmId
            }, callback);
        }
    }

    // Specific to LOMACO
    toClient(data){
        return {
            Numero : this.state.crmId,
            Nom : data.nom,
            CodePostal : data.codePostal,
            Ville : data.ville,
            Actif : data.actif?data.actif:<FormattedMessage id="Unknown" />,
            CodeType : data.categorie,
            CodeEtablissement : data.agences[0].id,
            CodeUsrCreation : data.createdBy?data.createdBy:<FormattedMessage id="Unknown" />,
            DateCreation : data.dateMiseAJour
        }
    }

    render() {
        let client;
        let mustError;

        // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
        // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
        const { software } = this.props.company.crm ? this.props.company.crm : {};

        if (this.state.apiData) {
            if(software === CrmProviders.MUST.software){
                client = Util.getNested(this.state.apiData, 'FindClisResult', 'Clients', 'Client', 0);
            }else{
                client = this.toClient(this.state.apiData);
            }
        } else {
            mustError = Util.getNested(this.state.apiData, 'FindClisResult', 'Erreur');
        }

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                size={"lg"}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="API.CRM.Link.Client.Account" /> {software}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {client ?

                        <table className="table table-striped table-bordered col-12 tablee4mad mt-3">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="Field" /></th>
                                    <th><FormattedMessage id="Value" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><FormattedMessage id="Client.Code" /></td>
                                    <td>{client['Numero']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Name" /></td>
                                    <td>{client['Nom']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Postal.Code" /> &amp; <FormattedMessage id="City" /></td>
                                    <td>{client['CodePostal']} {client['Ville']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Active" /></td>
                                    <td>{client['Actif']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Client.Type" /></td>
                                    <td>{client['CodeType']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Establishment.Code" /></td>
                                    <td>{client['CodeEtablissement']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Created.By" /></td>
                                    <td>{client['CodeUsrCreation']}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="Creation.Date" /></td>
                                    <td>{DateUtil.toDateWithHour(client['DateCreation'])}</td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        mustError['Message']
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.close()}><FormattedMessage id="Cancel" /></Button>
                    {/** Check if client is already associated to MUST or not */
                        (client) ?
                            (!this.state.linkedAccount) ?
                                <Button variant="btn btn-info" onClick={() => this.updateLink(client['Numero'])}><FormattedMessage id="Confirm" /></Button>
                                :
                                <Button variant="btn btn-danger" onClick={() => this.updateLink("")} disabled={this.state.crmId === ""}><FormattedMessage id="Separate" /></Button>
                            :
                            ""
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.company
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdatePrescriber: (data, successCallback) => dispatch(updClient(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FindCliModal));