import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import OperationPromotionalModal from './OperationPromotionalModal';
import PromotionalOperationsRow from "./PromotionalOperationsRow";
import { deletePromoOperation } from "../../../../actions/promoOperations/promoOperations"
// import OperationPromotionalModal from './PrescriberTypeModalAdmin';
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
// import { deletePrescriberType, updatePrescriberType } from "../../../../actions/settings/prescriberType/admin/prescriberType";

class PromotionalOperations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
        };
    }

    editPromotionalOperationModal(promoOperation) {
        this.setState({
            modal: <OperationPromotionalModal
                isOpen={true}
                promoOperationId={promoOperation._id}
                promoOperation={promoOperation}
                mercurial_id={promoOperation.mercurial_id}
                concernedProduct={this.getProduct(promoOperation.product_id, promoOperation.mercurial_id)}
                closeModal={() => this.closeModal()} />
        });
    }

    deletePromotionalOperationModal(operationPromotionalId) {
        const deleteCbk = () => this.props.onDeletePromoOperation({ operationPromotionalId: operationPromotionalId });

        this.setState({
            modal: <ConfirmationModal
                isOpen={true}
                title={<FormattedMessage id={"Confirm"} />}
                content={<FormattedMessage id={"Promotional.Operation.Remove.Confirmation"} />}
                successCallback={deleteCbk}
                closeModal={() => this.closeModal()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    openOperationPromotionalModal() {
        this.setState({
            modal: <OperationPromotionalModal
                required={true}
                isOpen={true}
                editPromotionalOperationModal={(operationPromotional) => this.editPromotionalOperationModal(operationPromotional)}
                closeModal={() => this.closeModal()} />
        });
    }

    getProduct(productId, mercurialId) {
        for (var product of this.props.products) {
            if (product._id === productId && product.mercurial_id === mercurialId) {
                return product;
            }
        }
    }

    render() {
        if (this.props.promoOperations.length === 0) {
            return (
                <React.Fragment>
                    {this.state.modal}
                    <div className="row search-filters">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    {/* <h5 className="card-title">Recherche</h5> */}
                                    <div className="form-inline">
                                        <div className="alert alert-info w-100">
                                            <FormattedMessage id="No.Promo" />
                                        </div>
                                        <button className="btn btn-info ml-auto" onClick={(e) => this.openOperationPromotionalModal(e)}>
                                            <FormattedMessage id="Add.Promotional.Operation" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment >
            )
        }

        var PromotionalOperationsRowNode = this.props.promoOperations.map((promoOperation,index) => {
            var product = this.getProduct(promoOperation.product_id, promoOperation.mercurial_id);
            var mercurial;
            if (this.props.mercurials) {
                mercurial = this.props.mercurials.find(p => p._id === promoOperation.mercurial_id);
            }
            return <PromotionalOperationsRow
                key={index}
                keyId={promoOperation._id}
                product={product}
                mercurial={mercurial}
                promoOperation={promoOperation}
                editPromotionalOperationModal={(operationPromotional) => this.editPromotionalOperationModal(operationPromotional)}
                deletePromotionalOperationModal={(operationPromotionalId) => this.deletePromotionalOperationModal(operationPromotionalId)} />;

        });

        return (
            <React.Fragment>
                {this.state.modal}

                <div className="row search-filters">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <h5 className="card-title">Recherche</h5> */}
                                <div className="form-inline">
                                    <button className="btn btn-info ml-auto" onClick={(e) => this.openOperationPromotionalModal(e)}>
                                        <FormattedMessage id="Add.Promotional.Operation" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table className="table tablee4mad">
                    <thead>
                        <tr className="d-flex">
                            <th scope="col" className="col"><FormattedMessage id="Operation.Name" /></th>
                            <th scope="col" className="col col-2"><FormattedMessage id="Start.Date" /></th>
                            <th scope="col" className="col col-2"><FormattedMessage id="End.Date" /></th>
                            <th scope="col" className="col col-3"><FormattedMessage id="Documentation" /></th>
                            <th className="col col-1 text-center"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {PromotionalOperationsRowNode}
                    </tbody>
                </table>

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        products: state.products,
        mercurials: state.mercurials,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDeletePromoOperation: (operationPromotionalId) => dispatch(deletePromoOperation(operationPromotionalId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalOperations);
