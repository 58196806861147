import { GET_NOTIFS } from "../../actions/notifications/notifications";

export default function notifications(state = [], action) {

    switch (action.type) {
        case GET_NOTIFS:
            return action.notifications;
        default:
            return state;
    }

};
