import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import Util from '../../util/Util';
import DateUtil from '../../util/DateUtil';
import { updPatientAdmin } from "../../actions/patients/patients";
import EqSellModes from '../../enums/EqSellModes';
import CustomLabel from "../sub/CustomLabel";

class AddPatientEquipmentModal extends React.Component {

    constructor(props) {
        super(props);

        const { equipment } = this.props;

        this.isUpdate = (!!equipment);

        this.state = {
            audit_equipment_id: "",
            mode: EqSellModes.SALE,
            first_install_date: "",
            last_prescription_date: "",
            rental_renewal: 1,
            internal_ref: "",
            serial_nbr: "",

            first_install_date_error: null,
            last_prescription_date_error: null,

            loading: false
        };

        if (equipment) {
            this.state = {
                audit_equipment_id: equipment.audit_equipment_id,
                mode: "" + equipment.mode,
                first_install_date: DateUtil.toyyyyMMdd(equipment.first_install_date),
                last_prescription_date: DateUtil.toyyyyMMdd(equipment.last_prescription_date),
                rental_renewal: (equipment.rental_renewal) ? parseInt(equipment.rental_renewal) : 1,
                internal_ref: equipment.internal_ref ? equipment.internal_ref : "",
                serial_nbr: equipment.serial_nbr ? equipment.serial_nbr : "",

                loading: false
            };

        }
    }

    onSubmit() {
        this.setState({ loading: true });

        const equipment = {
            audit_equipment_id: this.state.audit_equipment_id,
            mode: this.state.mode,
            first_install_date: this.state.first_install_date,
            last_prescription_date: this.state.last_prescription_date,
            rental_renewal: this.state.rental_renewal,
            internal_ref: this.state.internal_ref,
            serial_nbr: this.state.serial_nbr
        };

        const updatedPatient = {
            _id: this.props.patient._id,
            equipments: this.props.patient.equipments,
        }

        if (!updatedPatient.equipments) updatedPatient.equipments = [];
        updatedPatient.equipments.push(equipment);

        // Send to BE
        this.props.onUpdPatientAdmin(updatedPatient, () => this.props.close());
    }

    onChange(field, value) {
        if (field === "rental_renewal") {
            if (value >= 1 && value <= 12) {
                this.onBlur(field, value);
            }
            else {
                return;
            }
        }

        this.setState({ [field]: value });

    }

    onBlur(field, value) {
        const { patient, equipment } = this.props;

        if (!this.isUpdate || !patient || !equipment || (!value && field === "audit_equipment_id")) return;

        const updatedPatient = {
            _id: this.props.patient._id,
            equipments: this.props.patient.equipments,
        };

        for (let e of updatedPatient.equipments) {
            if (e._id === equipment._id) {
                e[field] = value;
            }
        }

        // Send to BE
        this.props.onUpdPatientAdmin(updatedPatient);
    }

    disabled() {
        return Util.emptyString(this.state.audit_equipment_id) ||
            Util.emptyString(this.state.mode) ||
            //Util.emptyString(this.state.last_prescription_date) ||
            this.state.dateError ||
            this.state.loading;
    }

    render() {
        const { auditRooms, auditEquipments } = this.props;

        let selectedRoom = false;

        let selectRoomNode = auditRooms.map(room => {

            let currentRoomDM = auditEquipments
                .filter(DM => DM.currentRoom._id === room._id)
                .map(DM => {

                    if ((this.state.audit_equipment_id === DM._id) && (this.state.audit_equipment_id !== "")) {
                        selectedRoom = DM._id;
                    }

                    return (
                        <option key={DM._id} value={DM._id}>{DM.name}</option>
                    );
                });

            if (currentRoomDM.length) {
                currentRoomDM = <optgroup key={room._id} label={room.name}>{currentRoomDM}</optgroup>
            }

            return currentRoomDM;
        });

        if (!auditEquipments) return null;

        return (
            <Modal
                show={true}
                onHide={() => this.props.close()}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="Equipments" /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group row">
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Equipment" })}
                            htmlFor="audit_equipment_id"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required
                        />
                        <div className="col-7 col-sm-7">
                            <select id="audit_equipment_id" className="form-control"
                                value={selectedRoom}
                                // disabled={(selectedRoom) ? true : false}
                                onBlur={(e) => this.onBlur("audit_equipment_id", e.target.value)}
                                onChange={(e) => this.onChange("audit_equipment_id", e.target.value)}>
                                <option value="">{this.props.intl.formatMessage({ id: "Select.DM" })}</option>
                                {selectRoomNode}
                            </select>

                        </div>
                    </div>

                    <div className="form-group row">
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Intern.Ref" })}
                            htmlFor="internal_ref"
                            labelClassName="col-12 col-sm-5 col-form-label"
                        />
                        <div className="col-7 col-sm-7">
                            <input type="text" className="form-control" id="internal_ref"
                                value={this.state.internal_ref}
                                onChange={(e) => this.onChange("internal_ref", e.target.value)}
                                onBlur={(e) => this.onBlur("internal_ref", e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Serial.Number" })}
                            htmlFor="serial_nbr"
                            labelClassName="col-12 col-sm-5 col-form-label"
                        />
                        <div className="col-7 col-sm-7">
                            <input type="text" className="form-control" id="serial_nbr"
                                value={this.state.serial_nbr}
                                onChange={(e) => this.onChange("serial_nbr", e.target.value)}
                                onBlur={(e) => this.onBlur("serial_nbr", e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Mode" })}
                            htmlFor="mode"
                            labelClassName="col-12 col-sm-5 col-form-label"
                            required
                        />
                        <div className="col-7 col-sm-7">
                            <select id="mode" className="form-control"
                                value={this.state.mode}
                                disabled={this.props.year}
                                onBlur={(e) => this.onBlur("mode", e.target.value)}
                                onChange={(e) => this.onChange("mode", e.target.value)}>
                                {
                                    Object.values(EqSellModes).map((EqSellMode) => {
                                        if (EqSellMode === 2) return null;
                                        return <option key={EqSellMode} value={EqSellMode}>{this.props.intl.formatMessage({ id: "EqSellMode." + EqSellMode })}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    {(parseInt(this.state.mode) === EqSellModes.LEASING &&
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Rental.Renewal" })}
                                htmlFor="rental_renewal"
                                labelClassName="col-12 col-sm-5 col-form-label"
                                required
                            />
                            <div className="col-7 col-sm-7">
                                <div className="form-row">
                                    <div className="col-3">
                                        <Button variant="outline-secondary" className="btn-block" onClick={(e) => this.onChange("rental_renewal", this.state.rental_renewal - 1)}>-</Button>
                                    </div>
                                    <div className="col-6 pl-4 pr-4">
                                        <div className="form-control text-center">{this.state.rental_renewal} <FormattedMessage id="Month" /></div>
                                    </div>
                                    <div className="col-3">
                                        <Button variant="outline-secondary" className="btn-block" onClick={(e) => this.onChange("rental_renewal", this.state.rental_renewal + 1)}>+</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="form-group row">
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "First.Install.Date" })}
                            htmlFor="first_install_date"
                            labelClassName="col-12 col-sm-5 col-form-label"
                        />
                        <div className="col-7 col-sm-7">
                            <input type="date" className="form-control" id="first_install_date"
                                max={DateUtil.DateTime().now().toISODate()}
                                value={this.state.first_install_date}
                                onChange={(e) => this.onChange("first_install_date", e.target.value)}
                                onBlur={(e) => this.onBlur("first_install_date", e.target.value)} />
                            {/* <div className="text-danger"><small>{this.state.first_install_date_error}</small></div> */}
                        </div>
                    </div>

                    <div className="form-group row">
                        <CustomLabel
                            label={this.props.intl.formatMessage({ id: "Last.Prescription.Date" })}
                            htmlFor="last_prescription_date"
                            labelClassName="col-12 col-sm-5 col-form-label"
                        />
                        <div className="col-7 col-sm-7">
                            <input type="date" className="form-control" id="last_prescription_date"
                                max={DateUtil.DateTime().now().toISODate()}
                                value={this.state.last_prescription_date}
                                onChange={(e) => this.onChange("last_prescription_date", e.target.value)}
                                onBlur={(e) => this.onBlur("last_prescription_date", e.target.value)} />
                            <div className="text-danger"><small>{this.state.last_prescription_date_error}</small></div>
                        </div>
                    </div>
                </Modal.Body>

                {!this.isUpdate &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.close()}><FormattedMessage id="Cancel" /></Button>
                        <Button variant="btn btn-info" onClick={() => this.onSubmit()} disabled={this.disabled()}><FormattedMessage id="Add" /></Button>
                    </Modal.Footer>
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        auditEquipments: state.auditDMs,
        auditRooms: state.auditRooms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdPatientAdmin: (patient, successCallback) => dispatch(updPatientAdmin(patient, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddPatientEquipmentModal));
