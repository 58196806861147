import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import Icon from "../../sub/Icon.js";

const propTypes = {
    formattedMessageId: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
    link: PropTypes.string.isRequired
};

/**
 * Item for one row of split home
 * @param {String} formattedMessageId id to get formatted message
 * @param {*} content react component to display on card
 * @param {String} link navlink to redirect
 */
class SplitHomeItem extends React.Component {
    render() {
        const { formattedMessageId, content, link } = this.props;
        return (
            <div className="m-2 zoom-7">
                <Card className="p-3">
                    <Card.Title><h4><FormattedMessage id={formattedMessageId} /></h4></Card.Title>
                    {content}
                    <Link to={link} className="overlay text-center"><Icon icon="magnifying-glass" /></Link>
                </Card>
            </div>
        );
    }
}

SplitHomeItem.propTypes = propTypes;

export default SplitHomeItem;
