import React from 'react';
import { connect } from 'react-redux';
import { removeCollaboratorClient } from '../../../../actions/collaborators/collaborators';
import { FormattedMessage, injectIntl } from 'react-intl';
import StringUtil from '../../../../util/StringUtil';
import MenuButton from "../../../sub/bootstrap/MenuButton";

class CollaboratorAssignmentsRow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false
        }
    }


    removeClient(clientId) {
        if (!clientId || this.state.disabled) return;

        this.setState({ disabled: true });

        const data = {
            collaboratorId: this.props.collaborator._id,
            clientId: clientId
        };

        const successCallback = () => {
            this.setState({ disabled: false });
        };

        this.props.onRemoveCollaboratorClient(data, successCallback);
    }

    getClientLabel(clientTypeId) {
        let clientType = this.props.clientTypes.find((p) => p._id === clientTypeId);
        if (clientType && clientType.name) return clientType.name;
        return null;
    }

    getTypeName = (client) => {
        if (client.client_type && client.client_type === 'helper') {
            return this.props.intl.formatMessage({ id: "Client.Helper" });
        }

        if (client.client_type && client.client_type === 'prescriber') {
            return this.props.intl.formatMessage({ id: "Client.Prescriber" });
        }
    }

    render() {

        return (
            <tr key={this.props.client._id}>
                <td className="d-none d-md-table-cell">{this.props.client.name.toUpperCase()} {StringUtil.ucFirst(this.props.client.first_name)}</td>
                <td className="d-none d-md-table-cell">{this.getTypeName(this.props.client)}</td>
                <td className="d-none d-md-table-cell">{this.getClientLabel(this.props.client.type)}</td>
                <td className="text-center tdaction">
                    <MenuButton
                        icon="unlink"
                        onClick={() => this.removeClient(this.props.client._id)}
                        hover={<FormattedMessage id="Separate" />}
                        disabled={this.state.disabled}
                    />
                </td>
            </tr>
        );
    }

}

const mapStateToProps = state => {
    return {
        //
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveCollaboratorClient: (data, successCallback) => dispatch(removeCollaboratorClient(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollaboratorAssignmentsRow));
