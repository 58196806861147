import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import OrderRow from "./OrderRow";
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import OrderStatus from '../../enums/OrderStatus';
import Paginator from '../sub/Paginator';
import DateUtil from '../../util/DateUtil';
import Util from '../../util/Util';
import CustomLabel from "../sub/CustomLabel";
import MenuButton from "../sub/bootstrap/MenuButton";

class Orders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            referenceFilter: "",
            dateStartFilter: "",
            dateEndFilter: "",
            statusFilter: ""
        };

        this.paginator = new Paginator(this);
    }

    openConfModal(title, content, successCallback) {
        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={title}
                content={content}
                successCallback={successCallback}
                closeModal={() => this.closeModal()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    sortByDateDesc(order1, order2) {
        if (order1.creation_date > order2.creation_date) return -1;
        if (order1.creation_date < order2.creation_date) return 1;
        return 0;
    }

    setStartDateFilter = (date) => {
        this.setState({ dateStartFilter: new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 0, 0, 0, 0) });
    }

    setEndDateFilter = (date) => {
        this.setState({ dateEndFilter: new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 23, 59, 59, 999) });
    }

    areResultsFiltered = () => {
        if (
            !Util.emptyString(this.state.referenceFilter) ||
            !Util.emptyString(this.state.dateStartFilter) ||
            !Util.emptyString(this.state.dateEndFilter) ||
            !Util.emptyString(this.state.statusFilter)
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    resetSearchFields() {
        this.setState({
            referenceFilter: "",
            dateStartFilter: "",
            dateEndFilter: "",
            statusFilter: ""
        });
    }

    render() {
        if (this.props.orders.length === 0) {
            return (
                <React.Fragment>
                    <a href="#/home/families" className="iconed-btn nostyle-a hoverable">
                        <button className="btn btn-info m-auto">
                            <FormattedMessage id="Order.Pass.Some" />
                        </button>
                    </a>
                    <div className="alert alert-secondary mt-3" role="alert">
                        <FormattedMessage id="Empty.Orders" />
                    </div>
                </React.Fragment>
            );
        }

        let orders = this.props.orders.sort(this.sortByDateDesc);

        this.paginator.init();

        let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

        var i = 0;
        let ordersNode = orders.map((order) => {
            if (this.state.referenceFilter && this.state.referenceFilter !== "") {
                if (order.ref.indexOf(this.state.referenceFilter) === -1) return null;
            }

            if (this.state.dateStartFilter && this.state.dateStartFilter !== "") {
                if (new Date(order.creation_date) < new Date(this.state.dateStartFilter)) return null;
            }

            if (this.state.dateEndFilter && this.state.dateEndFilter !== "") {
                if (new Date(order.creation_date) >= new Date(this.state.dateEndFilter)) return null;
            }

            if (this.state.statusFilter && this.state.statusFilter !== "") {
                if (order.status !== Number(this.state.statusFilter)) return null;
            }

            if (this.props.limit && ++i > this.props.limit) return null;

            if (!this.paginator.keep()) return null;

            return (
                <OrderRow
                    key={order._id}
                    order={order}
                    admin={this.props.admin}
                    client={this.props.user}
                    products={order.products}
                    openConfModal={(title, content, successCallback) => this.openConfModal(title, content, successCallback)}
                    limit={this.props.limit && true}
                />
            );
        });

        var statusNode = Object.values(OrderStatus).map(option => {
            return <option key={option} value={option}>{this.props.intl.formatMessage({ id: "Order.Status." + option })}</option>
        });

        return (
            <React.Fragment>
                {this.state.modal}

                {!this.props.limit && <div className="row search-filters">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-inline">
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Reference" })}
                                        htmlFor="search_reference"
                                        labelClassName="my-1 mr-2" />
                                    <input id="search_reference" className="form-control col-1 mr-sm-3" onChange={(e) => { this.setState({ referenceFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.referenceFilter} />
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Date" })}
                                        htmlFor="search_date"
                                        labelClassName="my-1 mr-2" />
                                    <input id="search_date" className="form-control mr-sm-3" type="date" onChange={(e) => { this.setStartDateFilter(e.target.value) }} disabled={disableFormInput} value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)} />
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Delivery.Date" })}
                                        htmlFor="search_delivery_date"
                                        labelClassName="my-1 mr-2" />
                                    <input id="search_delivery_date" className="form-control mr-sm-3" type="date" onChange={(e) => { this.setEndDateFilter(e.target.value) }} value={DateUtil.toyyyyMMdd(this.state.dateEndFilter)} disabled={disableFormInput} />
                                    <CustomLabel
                                        label={this.props.intl.formatMessage({ id: "Status" })}
                                        htmlFor="search_status"
                                        labelClassName="my-1 mr-2" />
                                    <select id="search_status" className="form-control mr-sm-3" onChange={(e) => { this.setState({ statusFilter: e.target.value }) }} disabled={disableFormInput} value={this.state.statusFilter}>
                                        <option value="">{this.props.intl.formatMessage({ id: "All" })}</option>
                                        {statusNode}
                                    </select>
                                    <MenuButton
                                        onClick={() => this.resetSearchFields()}
                                        hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                                        variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                                        icon="filter"
                                        disabled={!this.areResultsFiltered() || disableFormInput}
                                    />
                                    <a href="#/home/families" className="btn btn-info ml-auto">
                                        <FormattedMessage id="Go.To.Mercurial" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <table className="table tablee4mad">
                    <thead>
                        <tr className="d-flex">
                            {/* <th className="d-none d-md-table-cell"><FormattedMessage id="User" /></th> */}
                            <th scope="col" className="col"><FormattedMessage id="Reference" /></th>
                            <th scope="col" className="col"><FormattedMessage id="Date" /></th>
                            <th scope="col" className="col"><FormattedMessage id="Order.Estimated.Delivery.Date" /></th>
                            <th scope="col" className="col col-1"><FormattedMessage id="Total.Incl.Tax" /></th>
                            <th scope="col" className={!this.props.limit ? "col col-2 align-middle" : "col col-3 align-middle"}><FormattedMessage id="Order.Status" /></th>
                            {!this.props.limit && <th scope="col" className="col col-1 text-center"><FormattedMessage id="Actions" /></th>}
                            {/* <th scope="col" className="col col-1 text-center"><FormattedMessage id="Urgent" /></th> */}
                        </tr>
                    </thead>
                    <tbody>

                        {ordersNode}

                    </tbody>
                </table>

                {!this.props.limit && this.paginator.render()}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.orders,
        user: state.user,
        admin: state.admin,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Orders));
