import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';

import { addCollaboratorType } from "../../../../../actions/configurables/collaboratorTypes";

import CustomLabel from '../../../../sub/CustomLabel';

class CollaboratorTypesModalAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            nameError: null,
            modal: null,
            disabled: true,
        };
    }

    close() {
        this.props.closeModal();
    }

    add() {
        this.setState({ disabled: true });

        let data = {
            name: this.state.name.trim(),
        };

        let callback = () => {
            this.close();
        }

        this.props.onAddCollaboratorType(data, callback);
    }

    closeModal() {
        this.setState({ modal: null });
    }

    checkCollaboratorType(name) {
        let isValid = true
        for (let collaboratorType of this.props.collaboratorTypes) {
            if (collaboratorType.name.toLowerCase() === name.toLowerCase().trim()) isValid = false;
        }
        return isValid;
    }

    onChange(field, value) {
        this.setState({ [field]: value });
    }

    onBlur(field, value) {
        if (field === "name") {
            if (this.checkCollaboratorType(value)) {
                this.setState({ disabled: false, nameError: null });
            } else {
                this.setState({ disabled: true, nameError: this.props.intl.formatMessage({ id: "Collaborator.Type.Already.Exist" }) });
            }
        }
    }

    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="Add.Collaborator.Type" /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="form-group row">
                                <CustomLabel
                                    label={this.props.intl.formatMessage({ id: "Name" })}
                                    htmlFor="name"
                                    labelClassName="col-12 col-md-4 col-form-label"
                                    required
                                />
                                <div id="name" className="col-12 col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="collaboratorType"
                                        autoComplete="off"
                                        value={this.state.name}
                                        onChange={(e) => this.onChange("name", e.target.value)}
                                        onBlur={(e) => this.onBlur("name", e.target.value)}
                                    />
                                    <small className="text-danger">{this.state.nameError}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.close()}><FormattedMessage id="Cancel" /></Button>
                    <Button variant="btn btn-info" onClick={() => this.add()} disabled={this.state.disabled || this.state.nameError || this.state.name === ""}><FormattedMessage id="Add" /></Button>
                </Modal.Footer>
                {this.state.modal}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddCollaboratorType: (data, successCallback) => dispatch(addCollaboratorType(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollaboratorTypesModalAdmin));