import {GET_COLLABORATOR_TYPES} from "../../actions/configurables/collaboratorTypes";

export default function collaboratorTypes(state=[], action) {

  switch (action.type) {
    case GET_COLLABORATOR_TYPES:
      return action.collaboratorTypes;
    default:
      return state;
  }

};
