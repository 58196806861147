import APIUrl from "../../APIUrl";
import axios from 'axios';

export const LOGOUT = "LOGOUT";
export const GET_URL = "GET_URL";

function getLogoutAction(){return {type: LOGOUT}};

export const logout = function(successCallback){
    return function(dispatch){
        // Delete tokens
        axios.defaults.headers.common['jwtToken'] = null;
        
        delete axios.defaults.headers.jwtToken;
        delete axios.defaults.headers.common['jwtToken'];
        delete APIUrl.jwtToken;

        // Clear session storage
        sessionStorage.clear();

        dispatch(getLogoutAction());
        successCallback();
    };
};