import APIUrl from "../../APIUrl";
import axios from 'axios';

export const GET_PANIER = "GET_PANIER";

function getCartAction(cart){return {type: GET_PANIER, cart: cart}};

export const addProductToCart = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addProductToCart, data)
            .then(function(response){
                successCallback();

                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const getCart = function(){
    return function(dispatch){
        return axios.get(APIUrl.getCart)
            .then(function(response){
                if(response) dispatch(getCartAction(response.data));
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const updateUrgent = function(data){
    return function(dispatch){
        return axios.post(APIUrl.updateUrgent, data)
            .then(function(response){
                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const increaseQuantity = function(data){
    return function(dispatch){
        return axios.post(APIUrl.increaseQuantity, data)
            .then(function(response){
                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const decreaseQuantity = function(data){
    return function(dispatch){
        return axios.post(APIUrl.decreaseQuantity, data)
            .then(function(response){
                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const deleteCartById = function(data){
    return function(dispatch){
        return axios.post(APIUrl.deleteCartById, data)
            .then(function(response){
                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const deleteAll = function(){
    return function(dispatch){
        return axios.post(APIUrl.deleteAll)
            .then(function(response){
                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

// =======================================================================
// =============================== ADMIN =================================
// =======================================================================


export const addProductToCartAdmin = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addProductToCartAdmin, data)
            .then(function(response){
                successCallback();

                // Immediately refresh the cart so it's up-to-date
                dispatch(getCart());
            })
            .catch(function(err){
                throw(err);
            });
    };
};