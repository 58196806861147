import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
    addCollaboratorType,
    removeCollaboratorType,
} from "../../../../../actions/configurables/collaboratorTypes";
import MenuButton from "../../../../sub/bootstrap/MenuButton";

class CollaboratorTypesRow extends React.Component {
    check(collaboratorTypeId) {
        let { collaborators } = this.props;
        let items = collaborators;
        let result = [];

        for (let item of items) {
            if (item.collaborator_type_id === collaboratorTypeId) result.push(item);
        }
        return result;
    }

    modalDeleteCollaboratorType(modalTitle, modalContent, successCallback) {
        let { collaboratorType, openConfModal } = this.props;
        let errors = this.check(collaboratorType._id);

        if (errors.length > 0) {
            this.openDataIntegrityModal(errors);
        } else {
            openConfModal(modalTitle, modalContent, successCallback);
        }
    }

    deleteCollaboratorType(collaboratorTypeId) {
        var data = {
            collaboratorTypeId: collaboratorTypeId,
            collaborators: this.props.collaborators,
        };
        this.props.onDeleteCollaboratorType(data);
    }

    openDataIntegrityModal(dataProblems) {
        var errorModalTitle = <FormattedMessage id="Error" />;
        var errorModalContent = (
            <div>
                <div className="alert alert-danger">
                    <div>
                        <FormattedMessage
                            id="Collaborator.Type.Can.Not.Be.Deleted"
                            values={{ collaboratorType: this.props.collaboratorType.name }}
                        />
                    </div>
                    {/* {(Object.values(dataProblems).length === 30) && <div><FormattedMessage id="Mercurial.File.Error.Count" values={{ count: Object.values(dataProblems).length }} /></div>} */}
                </div>
                <table className="table table-striped tablee4mad">
                    <thead>
                        <tr className="d-flex">
                            <th scope="col" className="col col-3">
                                <FormattedMessage id="First.Name" />
                            </th>
                            <th scope="col" className="col col-3">
                                <FormattedMessage id="Name" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataProblems.length > 0 &&
                            dataProblems.map((row, index) => {
                                return (
                                    <tr key={index} className="d-flex">
                                        <td className="col col-3">{row.first_name}</td>
                                        <td className="col col-3">{row.name}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        );

        this.props.openErrorModal(errorModalTitle, errorModalContent);
    }

    render() {
        let { collaboratorType, key } = this.props;

        var modalTitle = <FormattedMessage id="Confirmation" />;
        var modalContent = (
            <p className="card-text">
                <FormattedMessage
                    id="Delete.Collaborator.Types"
                    values={{ collaboratorType: collaboratorType.name }}
                />
            </p>
        );
        var successCallback = () =>
            this.deleteCollaboratorType(collaboratorType._id);

        return (
            <React.Fragment>
                <tr key={key} className="tre4mad">
                    <td>{collaboratorType.name}</td>
                    <td className="text-center">
                        <MenuButton
                            icon="trash"
                            onClick={() =>
                                this.modalDeleteCollaboratorType(
                                    modalTitle,
                                    modalContent,
                                    successCallback
                                )
                            }
                            hover={<FormattedMessage id="Delete" />}
                        />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddCollaboratorType: (data, successCallback) => dispatch(addCollaboratorType(data, successCallback)),
        onDeleteCollaboratorType: (data) => dispatch(removeCollaboratorType(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorTypesRow);
