import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import Util from '../../util/Util';
import { addClient, updClient } from "../../actions/clients/clients";
import { checkIfUsernameExists, checkIfEmailIsValid } from '../../actions/user/user';
import MercurialStatus from '../../enums/MercurialStatus';
import CustomLabel from "../sub/CustomLabel";
import ComboBox from "../sub/ComboBox";
import Geolocation from '../api/gouv/Geolocation';

class AddPrescriberModal extends React.Component {

    constructor(props) {
        super(props);

        const { prescriberId } = this.props;
        const prescriber = this.props.clients.find(p => p._id === prescriberId);

        this.isUpdate = (!!prescriber);

        const getValue = (field) => (prescriber) ? prescriber[field] : "";

        const getPrescriberType = () => {
            if (prescriber) return prescriber.type;
            if (this.props.prescriberTypes && this.props.prescriberTypes.length) {
                return this.props.prescriberTypes._id;
            }

            return "";
        }

        const getMercurialId = () => {
            if (prescriber) return prescriber.mercurial_Id;
            if (this.props.mercurials && this.props.mercurials.length) {
                return this.props.mercurials._id;
            }

            return "";
        }


        this.state = {
            first_name: getValue("first_name") || "",
            name: getValue("name") || "",
            address: getValue("address") || "",
            addressAdditionnal: getValue("addressAdditionnal") || "",
            mercurial_Id: getMercurialId() || "",
            postal_code: getValue("postal_code") || "",
            city: getValue("city") || "",
            phone: getValue("phone") || "",
            mobile: getValue("mobile") || "",
            email: getValue("email") || "",
            username: getValue("username") || "",
            numero_agrement: getValue("numero_agrement") || "",
            type: getPrescriberType() || "",
            client_type: getValue("client_type") || "",
            account_type: getValue("account_type") || "0",
            notifyByEmail: false,
            nameError: null,
            clientTypeError: null,
            firstNameError: null,
            phoneError: null,
            mobileError: null,
            emailError: null,
            usernameError: null,
            postal_codeError: null,
            listCities: getValue("city") ? [{ _id: getValue("city"), name: getValue("city") }] : [],
            loading: false
        };

    }

    getCityList(postal_code) {

        if (!postal_code || postal_code === this.state.postal_code || this.state.postal_code === "") {
            return;
        }
        else {
            Geolocation.getCitiesByPostalCode(postal_code, this.state.city, (liste) => {
                this.setState({ listCities: liste });
            });
        }

    }

    onSubmit() {
        this.setState({ loading: true });

        let mercurialId = this.state.mercurial_Id;
        let type = this.state.type;

        if (mercurialId === "") mercurialId = null;
        if (type === "") type = null;

        const prescriber = {
            first_name: this.state.first_name,
            name: this.state.name,
            address: this.state.address,
            addressAdditionnal: this.state.addressAdditionnal,
            postal_code: this.state.postal_code,
            city: this.state.city,
            mercurial_Id: mercurialId,
            phone: this.state.phone,
            mobile: this.state.mobile,
            email: this.state.email,
            username: this.state.username,
            numero_agrement: this.state.numero_agrement,
            type: type,
            client_type: this.state.client_type === '' ? 'helper' : this.state.client_type,
            account_type: this.state.account_type,
            notifyByEmail: this.state.notifyByEmail
        };

        const successCallback = () => {
            this.setState({ loading: false }, () => this.props.close())
        }

        // Send to BE
        this.props.onAddClient(prescriber, successCallback);
    }

    disabled() {
        return (
            Util.emptyString(this.state.client_type) ||
            Util.emptyString(this.state.name) ||
            (Util.emptyString(this.state.first_name) && this.state.account_type === 0) ||
            Util.emptyString(this.state.username) ||
            Util.emptyString(this.state.email) ||
            Util.emptyString(this.state.account_type) ||
            this.state.clientTypeError ||
            this.state.phoneError ||
            this.state.mobileError ||
            this.state.emailError ||
            this.state.usernameError ||
            this.state.doctorIdError ||
            this.state.postal_codeError ||
            this.state.loading
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (nextState !== this.state);
    }

    onChange(field, value) {

        if (field === "postal_code" && value.length > 5) {
            return;
        }

        if (field === "notifyByEmail") {
            this.setState({ notifyByEmail: value });
            this.forceUpdate(() => this.setState({ notifyByEmail: value }));

        } else {
            this.setState({ [field]: value });
        }

        const prescriber = this.props.clients.find(p => p._id === this.props.prescriberId);

        if (field === "name") {
            if (Util.emptyString(value)) this.setState({ nameError: <FormattedMessage id="Empty.Name" /> });
            else this.setState({ nameError: null });
        } else if (field === "first_name") {
            if (Util.emptyString(value)) this.setState({ firstNameError: <FormattedMessage id="Empty.FirstName" /> });
            else this.setState({ firstNameError: null });
        } else if (field === "phone") {
            if (!Util.emptyString(value) && !Util.isPhone(value)) this.setState({ phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" /> });
            else this.setState({ phoneError: null });
        } else if (field === "client_type") {
            if (Util.emptyString(value)) this.setState({ clientTypeError: <FormattedMessage id="Empty.Client.Type" /> });
            else this.setState({ clientTypeError: null });
        } else if (field === "postal_code") {
            if (!Util.emptyString(value) && !Util.isPostal(value)) {
                this.setState({
                    postal_codeError: <FormattedMessage id="Invalid.Postal.Number.Error" />,
                    city: ""
                });
            }
            else {
                this.setState(
                    {
                        postal_codeError: null,
                        listCities: this.state.city ? [{ _id: this.state.city, name: this.state.city }] : []
                    }, this.getCityList(value));
            }
        } else if (field === "mobile") {
            if (!Util.emptyString(value) && !Util.isPhone(value)) this.setState({ mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" /> });
            else this.setState({ mobileError: null });
        } else if (field === "username") {
            this.setState({ usernameError: null });

            // Force this value to be in lowercase
            // value = value.toLowerCase();

            // We call setState again due to case change above
            this.setState({ [field]: value });

            if (Util.emptyString(value)) {
                this.setState({ usernameError: <FormattedMessage id="Empty.Username" /> });
                return;
            }

            // Test username syntax
            var checkUsername = Util.isValidUserName(value, 3, 20);

            // There is an error with password syntax
            if (checkUsername instanceof Object === true) {
                this.setState({ usernameError: <FormattedMessage id="Invalid.Username" values={{ minLength: checkUsername.minLength, maxLength: checkUsername.maxLength }} /> });
                return;
            } else {
                this.setState({ usernameError: null })
            }

            if (!this.isUpdate || (this.isUpdate && prescriber.username !== value)) {
                this.props.onCheckIfUsernameExists(
                    value,
                    () => { this.setState({ usernameError: <FormattedMessage id="Username.Already.Exists" values={{ username: value }} /> }) },
                    () => { this.setState({ usernameError: null }); }
                );
            }
        } else if (field === "email") {

            if (!Util.emptyString(value) && !Util.isEmail(value)) {
                this.setState({ emailError: <FormattedMessage id="Invalid.Email.Error" /> });
            } else {
                this.props.onCheckIfEmailIsValid(
                    value,
                    (response) => {
                        // console.log("data", response.data.message);
                        this.setState({ emailError: null });
                    },
                    (response) => {
                        // console.log("data", response);
                        this.setState({ emailError: <span><FormattedMessage id="Invalid.Email.Error" /> ({response.data.reason})</span> });
                        return;
                    }
                );
            }

        } else if (field === "city") {
            let list = this.state.listCities;
            let response = false;
            for (let listobject of list) {
                if (listobject._id === value) {
                    response = true;
                }
            }
            if (!response) {
                list.push({ _id: value, name: value });
                this.setState({
                    listCities: list
                })
                this.setState({ [field]: value });
            }
        }
    }

    onBlur(field, value) {

        if (!this.isUpdate) return;

        if (field === "name" && this.state.nameError) return;
        else if (field === "client_type" && this.state.clientTypeError) return;
        else if (field === "first_name" && this.state.firstNameError) return;
        else if (field === "phone" && this.state.phoneError) return;
        else if (field === "mobile" && this.state.mobileError) return;
        else if (field === "email" && this.state.emailError) return;
        else if (field === "username" && this.state.usernameError) return;
        else if (field === "postal_code" && this.state.postal_codeError) return;

        const client = {
            _id: this.props.prescriberId,
            updatedField: field,
            updatedValue: value
        };

        // Send to BE
        this.props.onUpdClient(client);
    }

    render() {
        var now = new Date();
        return (
            <div>
                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Account.Type" })}
                        htmlFor="account_type"
                        labelClassName="col-12 col-sm-5 col-form-label" required />
                    <div className="col-12 col-sm-7">
                        <select className="form-control w-100"
                            value={this.state.account_type} onChange={(e) => this.onChange("account_type", e.target.value)}
                            onBlur={(e) => this.onBlur("account_type", e.target.value)}>
                            <option key={0} value={0}>{this.props.intl.formatMessage({ id: "Account.Type." + 0 })}</option>
                            <option key={1} value={1}>{this.props.intl.formatMessage({ id: "Account.Type." + 1 })}</option>
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Client.Type" })}
                        htmlFor="client_type"
                        labelClassName="col-12 col-sm-5 col-form-label" required />
                    <div className="col-12 col-sm-7">
                        <select className="form-control w-100"
                            value={this.state.client_type} onChange={(e) => this.onChange("client_type", e.target.value)}
                            onBlur={(e) => this.onBlur("client_type", e.target.value)}>
                            <option value="">{this.props.intl.formatMessage({ id: "Select.Client.Type" })}</option>
                            <option value="helper">{this.props.intl.formatMessage({ id: "Client.Helper" })}</option>
                            <option value="prescriber">{this.props.intl.formatMessage({ id: "Client.Prescriber" })}</option>
                        </select>
                        <div className="text-danger"><small>{this.state.clientTypeError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Client.Label" })}
                        htmlFor="type"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <select className="form-control w-100"
                            value={this.state.type} onChange={(e) => this.onChange("type", e.target.value)}
                            onBlur={(e) => this.onBlur("type", e.target.value)}>
                            <option value="">{this.props.intl.formatMessage({ id: "None.Masculine" })}</option>
                            {
                                this.props.prescriberTypes.map(
                                    t => (
                                        <option key={t._id} value={t._id}>{t.name}</option>
                                    )
                                )
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Mercurial.Linked" })}
                        htmlFor="mercurial_Id"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <select className="form-control w-100"
                            value={this.state.mercurial_Id} onChange={(e) => this.onChange("mercurial_Id", e.target.value)}
                            onBlur={(e) => this.onBlur("mercurial_Id", e.target.value)}>
                            <option value="">{this.props.intl.formatMessage({ id: "None.Feminine" })}</option>
                            {
                                this.props.mercurials.map(t => {
                                    const hasExpired = (new Date(t.start_date) > now || new Date(t.end_date) < now);
                                    if (hasExpired || t.status === MercurialStatus.INACTIVE) return null;

                                    return (
                                        <option key={t._id} value={t._id} disabled={hasExpired}>{t.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                {this.state.account_type + "" === "0" && <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "First.Name" })}
                        htmlFor="first_name"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required />
                    <div className="col-12 col-sm-7">
                        <input className="form-control text-capitalize" type="text" id="first_name" autoComplete="off"
                            value={this.state.first_name} onChange={(e) => this.onChange("first_name", e.target.value.toLowerCase())}
                            onBlur={(e) => this.onBlur("first_name", e.target.value)} />
                        <div className="text-danger"><small>{this.state.firstNameError}</small></div>
                    </div>
                </div>}

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Name" })}
                        htmlFor="name"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required />
                    <div className="col-12 col-sm-7">
                        <input className="form-control text-uppercase" type="text" id="name" autoComplete="off"
                            value={this.state.name} onChange={(e) => this.onChange("name", e.target.value.toUpperCase())}
                            onBlur={(e) => this.onBlur("name", e.target.value)} />
                        <div className="text-danger"><small>{this.state.nameError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Address" })}
                        htmlFor="address"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="address" autoComplete="off"
                            value={this.state.address} onChange={(e) => this.onChange("address", e.target.value)}
                            onBlur={(e) => this.onBlur("address", e.target.value)} />
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Address.Compl" })}
                        htmlFor="address"
                        labelClassName="col-12 col-sm-5 col-form-label"
                    />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="address" autoComplete="off"
                            value={this.state.addressAdditionnal} onChange={(e) => this.onChange("addressAdditionnal", e.target.value)}
                            onBlur={(e) => this.onBlur("addressAdditionnal", e.target.value)} />
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Postal.Code" })}
                        htmlFor="postal_code"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="postal_code" autoComplete="off"
                            value={this.state.postal_code} onChange={(e) => this.onChange("postal_code", e.target.value)}
                            onBlur={(e) => this.onBlur("postal_code", e.target.value)} />
                        <div className="text-danger"><small>{this.state.postal_codeError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "City" })}
                        htmlFor="city"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <ComboBox
                            onCreateOption={(option, successCallback) => { successCallback({ _id: option.name, name: option.name }) }}
                            onChange={e => { this.onChange("city", e) }}
                            onBlur={e => this.onBlur("city", e)}
                            defaultOption={this.state.city}
                            options={this.state.listCities}
                            placeholder={this.state.listCities.length ? this.props.intl.formatMessage({ id: "Select" }) : this.props.intl.formatMessage({ id: "Type.Postal.Code.First" })}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Phone" })}
                        htmlFor="phone"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="phone" autoComplete="off"
                            value={this.state.phone} onChange={(e) => this.onChange("phone", e.target.value)}
                            onBlur={(e) => this.onBlur("phone", e.target.value)} />
                        <div className="text-danger"><small>{this.state.phoneError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Mobile" })}
                        htmlFor="mobile"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="mobile" autoComplete="off"
                            value={this.state.mobile} onChange={(e) => this.onChange("mobile", e.target.value)}
                            onBlur={(e) => this.onBlur("mobile", e.target.value)} />
                        <div className="text-danger"><small>{this.state.mobileError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Numero.Agrement" })}
                        htmlFor="numero_agrement"
                        labelClassName="col-12 col-sm-5 col-form-label" />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="numero_agrement" autoComplete="off"
                            value={this.state.numero_agrement} onChange={(e) => this.onChange("numero_agrement", e.target.value)}
                            onBlur={(e) => this.onBlur("numero_agrement", e.target.value)} />
                        <div className="text-danger"><small>{this.state.doctorIdError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Username.Connexion" })}
                        htmlFor="username"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required />
                    <div className="col-12 col-sm-7">
                        <input className="form-control col-12 col-md-7 d-inline" type="text" id="username" autoComplete="off"
                            value={this.state.username} onChange={(e) => this.onChange("username", e.target.value.toLowerCase())}
                            onBlur={(e) => this.onBlur("username", e.target.value.toLowerCase())} />
                        <span className="col-12 col-md-3">-{this.props.company.url}</span>
                        <div className="text-danger"><small>{this.state.usernameError}</small></div>
                    </div>
                </div>

                <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Email" })}
                        htmlFor="email"
                        labelClassName="col-12 col-sm-5 col-form-label"
                        required />
                    <div className="col-12 col-sm-7">
                        <input className="form-control" type="text" id="email" autoComplete="off"
                            value={this.state.email} onChange={(e) => this.onChange("email", e.target.value)}
                            onBlur={(e) => this.onBlur("email", e.target.value)} />
                        <div className="text-danger"><small>{this.state.emailError}</small></div>
                    </div>
                </div>
                {!this.isUpdate && <div className="form-group row">
                    <CustomLabel
                        label={this.props.intl.formatMessage({ id: "Send.Mail.Notify.Created.Account" })}
                        htmlFor="notifyByEmail"
                        labelClassName="col-12 col-sm-5 col-form-label"
                    />
                    <div className="col-12 col-sm-7 d-flex align-items-center">
                        <input type={"checkbox"}
                            id="notifyByEmail"
                            onChange={e => { this.onChange("notifyByEmail", e.target.checked); }}
                            checked={this.state.notifyByEmail}
                        />
                    </div>
                </div>}
                
                {!this.isUpdate &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.close()}><FormattedMessage id="Cancel" /></Button>
                        <Button variant="text-white btn btn-info" onClick={() => this.onSubmit()} disabled={this.disabled()}><FormattedMessage id="Add" /></Button>
                    </Modal.Footer>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        clients: state.clients,
        prescriberTypes: state.prescriberTypes,
        mercurials: state.mercurials,
        company: state.company,
        user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddClient: (client, successCallback) => dispatch(addClient(client, successCallback)),
        onUpdClient: (client, successCallback) => dispatch(updClient(client, successCallback)),
        onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) => dispatch(checkIfUsernameExists(username, existsCallback, noExistsCallback)),
        onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) => dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddPrescriberModal));
