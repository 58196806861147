import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Modal, Tab, Tabs } from 'react-bootstrap';

import Roles from '../../enums/Roles';

import PatientGeneralInfo from './PatientGeneralInfo';
import PatientClients from "./PatientClients";
import PatientTechnicalSheets from './PatientTechnicalSheets';
import PatientDocuments from './PatientDocuments';
import PatientEstimates from './PatientEstimates';
import PatientEquipments from './PatientEquipments';
import PatientAudit from './PatientAudit';
import PatientBills from "./PatientBills";
import PatientDesk from './PatientDesk';

class PatientModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null,
        }
    }

    getPatient(patientId) {
        for (let p of this.props.patients) {
            if (p._id === patientId) return p;
        }
    }

    render() {
        const { patientId, target, isOpen, intl, close, openModal, user, audits, deskaudits } = this.props;

        const patient = this.getPatient(patientId);

        if (patientId && !patient) { return null; }

        let aClassName = (patient) ? "" : "disabled";

        return (
            <Modal
                show={isOpen}
                onHide={() => close()}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Body>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <Tabs defaultActiveKey={target} id="patientsTabs" mountOnEnter>
                        <Tab eventKey="general" title={intl.formatMessage({ id: "General.Informations" })}>
                            <PatientGeneralInfo patient={patient} openModal={(patient) => openModal(patient, "general")} close={() => close()} />
                        </Tab>

                        <Tab eventKey="helpers" title={intl.formatMessage({ id: "Helpers" })} tabClassName={aClassName}>
                            <PatientClients patient={patient} client_typeFilter={"helper"} />
                        </Tab>

                        {user.role !== Roles.CLIENT &&
                            <Tab eventKey="prescribers" title={intl.formatMessage({ id: "Prescribers" })} tabClassName={aClassName}>
                                <PatientClients patient={patient} client_typeFilter={"prescriber"} />
                            </Tab>
                        }

                        <Tab eventKey="audit" title={intl.formatMessage({ id: "Audit.MAD" })} tabClassName={aClassName}>
                            <PatientAudit patient={patient} audits={audits} />
                        </Tab>

                        {user.access_module_desk &&
                            <Tab eventKey="patients-audit-desk" title={intl.formatMessage({ id: "Audit.Desk.Secure" })} tabClassName={aClassName}>
                                <PatientDesk patient={patient} audits={deskaudits} />
                            </Tab>
                        }

                        <Tab eventKey="equipments" title={intl.formatMessage({ id: "Equipments" })} tabClassName={aClassName}>
                            <PatientEquipments patient={patient} openModal={(patient) => openModal(patient, "equipments")} />
                        </Tab>

                        <Tab eventKey="tech-sheets" title={intl.formatMessage({ id: "Tech.Sheets" })} tabClassName={aClassName}>
                            <PatientTechnicalSheets patient={patient} />
                        </Tab>

                        <Tab eventKey="estimates" title={intl.formatMessage({ id: "Estimates" })} tabClassName={aClassName}>
                            <PatientEstimates patient={patient} />
                        </Tab>

                        <Tab eventKey="documents" title={intl.formatMessage({ id: "Documents" })} tabClassName={aClassName}>
                            <PatientDocuments patient={patient} />
                        </Tab>

                        <Tab eventKey="bills" title={intl.formatMessage({ id: "Bills" })} tabClassName={aClassName}>
                            <PatientBills patient={patient} />
                        </Tab>
                    </Tabs>
                    {this.state.modal}
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        patients: state.patients,
        clients: state.clients,
        audits: state.audits,
        deskaudits: state.deskaudits,
        user: state.user,
        company: state.company
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientModal));