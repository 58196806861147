import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from '../sub/modals/ConfirmationModal';
import Util from '../../util/Util';
import { updPatientAdmin } from "../../actions/patients/patients";
import CustomLabel from '../sub/CustomLabel';
import Icon from '../sub/Icon.js';
import { Button, Col } from "react-bootstrap";
import Roles from "../../enums/Roles";
import StringUtil from '../../util/StringUtil';
import Select from 'react-select';
import MenuButton from "../sub/bootstrap/MenuButton";
import PopoverHelper from "../sub/bootstrap/PopoverHelper";
import TableToolbar from "../sub/bootstrap/TableToolbar";

// Props
// client_typeFilter = "helper" | "prescriber"

class PatientClients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            selectedClient: null,
            prescriber_id: "",
            prescriber_lead: (this.props.patient) ? this.props.patient.prescriber_lead : null,
            helper_id: "",
            helper_lead: (this.props.patient) ? this.props.patient.helper_lead : null,
        }
    }

    closeModal() { this.setState({ modal: null }); }

    getSelectOptions(fieldNameIds) {
        const options = [];
        const clients = this.props.clients.filter((c) => c.client_type === this.props.client_typeFilter && !this.props.patient[fieldNameIds].includes(c._id));
        for (const c of clients) {
            options.push({ value: c._id, label: `${c.name} ${c.first_name}` });
        }
        return options;
    }

    onChange(field, value, clientId) {
        const updatePatientLead = (field) => {
            let clonedPatient = Util.shallowClone(this.props.patient);
            clonedPatient[field] = clientId;

            if (!value) clonedPatient[field] = null;

            const successCallback = (patient) => { this.setState({ [field]: patient[field] }); }

            const patient = {
                _id: this.props.patient._id,
                [field]: clientId
            };

            this.props.onUpdPatientAdmin(patient, successCallback);
        }

        if (field === "lead") {
            if (this.props.client_typeFilter === "helper") updatePatientLead("helper_lead");
            else if (this.props.client_typeFilter === "prescriber") updatePatientLead("prescriber_lead");
        } else {
            this.setState({ [field]: value });

            if (field === 'helper_id' && !this.props.patient.helpers_ids.length) {
                this.setState({ helper_lead: value });
            } else if (field === 'prescriber_id' && !this.props.patient.prescribers_ids.length) {
                this.setState({ prescriber_lead: value });
            }
        }
    }

    addClient(clientId) {
        if (!clientId || Util.emptyString(clientId)) return;

        const addClientToPatient = (fieldNameIds, fieldNameLead) => {
            let clonedPatient = Util.shallowClone(this.props.patient);

            if (!clonedPatient[fieldNameIds]) clonedPatient[fieldNameIds] = [];

            clonedPatient[fieldNameIds].push(clientId);

            if (clonedPatient[fieldNameIds].length === 1) {
                clonedPatient[fieldNameLead] = this.state[fieldNameLead];
            }

            return clonedPatient;
        }

        const successCallback = (patient) => this.setState({ modal: null, prescriber_lead: patient.prescriber_lead, helper_lead: patient.helper_lead, selectedClient: null });

        let clonedPatient = {};
        let clientsData = {};

        if (this.props.client_typeFilter === "helper") {
            clonedPatient = addClientToPatient("helpers_ids", "helper_lead");
            clientsData = {
                _id: this.props.patient._id,
                helpers_ids: clonedPatient.helpers_ids
            };
        } else if (this.props.client_typeFilter === "prescriber") {
            clonedPatient = addClientToPatient("prescribers_ids", "prescriber_lead");
            clientsData = {
                _id: this.props.patient._id,
                prescribers_ids: clonedPatient.prescribers_ids
            };
        }

        this.props.onUpdPatientAdmin(clientsData, successCallback);
    }

    removeClient(patient, clientId) {

        const removeClientFromPatient = (fieldNameIds, fieldNameLead) => {
            const clonedPatient = Util.shallowClone(patient);
            clonedPatient[fieldNameIds] = clonedPatient[fieldNameIds].filter((id) => id !== clientId);
            if (clonedPatient[fieldNameLead] === clientId) { clonedPatient[fieldNameLead] = null; }
            return clonedPatient;
        }

        const successCallback = (patient) => {
            if (this.props.client_typeFilter === "helper") {
                this.setState({ helper_lead: patient.helper_lead });
            } else if (this.props.client_typeFilter === "prescriber") {
                this.setState({ prescriber_lead: patient.prescriber_lead });
            }
        }

        let clonedPatient = {};
        let modalTitle = "";
        let clientsData = {};

        if (this.props.client_typeFilter === "helper") {
            clonedPatient = removeClientFromPatient("helpers_ids", "helper_lead");
            modalTitle = "Confirm.Helper.Removal";
            clientsData = {
                _id: this.props.patient._id,
                helpers_ids: clonedPatient.helpers_ids
            };
        } else if (this.props.client_typeFilter === "prescriber") {
            clonedPatient = removeClientFromPatient("prescribers_ids", "prescriber_lead");
            modalTitle = "Confirm.Prescriber.Removal";
            clientsData = {
                _id: this.props.patient._id,
                prescribers_ids: clonedPatient.prescribers_ids
            };
        }

        this.setState({
            modal: <ConfirmationModal isOpen={true}
                title={<FormattedMessage id="Warning" />}
                content={<div>
                    <div>
                        <FormattedMessage id={modalTitle} />
                    </div>
                    <div>
                        <FormattedMessage id="Irrevocable.Warning" />
                    </div>
                </div>}
                context="danger"
                successCallback={() => this.props.onUpdPatientAdmin(clientsData, successCallback)}
                closeModal={() => this.closeModal()}
            />
        });
    }

    render() {
        const { user, patient, clients } = this.props;

        if (!patient) return null;

        let fieldNameIds = "";
        let fieldNameLead = "";
        let alertInfoMessageId = "";
        let selectDefaultLabelId = "";

        if (this.props.client_typeFilter === "helper") {
            fieldNameIds = "helpers_ids";
            fieldNameLead = "helper_lead";
            alertInfoMessageId = "Assign.Helper";
            selectDefaultLabelId = "Select.Helper";
        } else if (this.props.client_typeFilter === "prescriber") {
            fieldNameIds = "prescribers_ids";
            fieldNameLead = "prescriber_lead";
            alertInfoMessageId = "Assign.Prescriber";
            selectDefaultLabelId = "Select.Prescriber";
        }

        let clientsAssignmentsNodes = patient[fieldNameIds].map((id) => {
            let client = clients.find(p => p._id === id);
            if (!client) { return null; }
            return (
                <tr className="d-flex" key={"client-" + client._id}>
                    <td className="col">{client.name.toUpperCase()} {StringUtil.ucFirst(client.first_name)}</td>
                    <td className="col col-3">
                        <div className="custom-control custom-switch mx-auto switch-success text-left">
                            <input type="checkbox"
                                id={"lead-switch" + client._id}
                                className="custom-control-input switch-bg-blue"
                                onChange={(e) => { this.onChange("lead", e.target.checked, client._id); }}
                                checked={this.state[fieldNameLead] === client._id}
                                disabled={user.role === Roles.CLIENT || (this.state.helper_lead === client._id || this.state.prescriber_lead === client._id)} />
                            <CustomLabel
                                htmlFor={"lead-switch" + client._id}
                                labelClassName="custom-control-label" />
                        </div>
                    </td>
                    {(user.role === Roles.ADMIN || user.role === Roles.SALES_REP) && <td className="col col-1 tdaction text-center">
                        <MenuButton
                            icon="trash"
                            className="mr-2"
                            hover={<FormattedMessage id="Delete" />}
                            onClick={(e) => this.removeClient(patient, client._id)}
                            disabled={patient[fieldNameIds].length > 1 ? (this.state.helper_lead === client._id || this.state.prescriber_lead === client._id) : false}
                            variant="outline-info"
                        />
                    </td>}
                </tr>
            );
        });

        return (
            <React.Fragment>
                {user.role !== Roles.CLIENT &&
                    <TableToolbar>
                        <Col md={1} className="text-right pr-0">
                            <PopoverHelper>
                                <FormattedMessage id={alertInfoMessageId} />
                            </PopoverHelper>
                        </Col>
                        <Col md={9} className="pr-0">
                            <Select
                                placeholder={<FormattedMessage id={selectDefaultLabelId} />}
                                options={this.getSelectOptions(fieldNameIds)}
                                isClearable
                                noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                                onChange={(option) => this.setState({ selectedClient: option })}
                                value={this.state.selectedClient}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button variant="info" onClick={() => this.addClient(this.state.selectedClient.value)} disabled={!this.state.selectedClient}><Icon icon="plus-circle" /> <FormattedMessage id="Assign" /></Button>
                        </Col>
                    </TableToolbar>
                }

                {(patient && patient[fieldNameIds] && patient[fieldNameIds].length > 0) &&
                    <table className="table col-12 tablee4mad mt-3">
                        <thead>
                            <tr className="d-flex">
                                <th scope="col" className="col"><FormattedMessage id="Name" /> <FormattedMessage id="And" /> <FormattedMessage id="First.Name" /></th>
                                <th scope="col" className="col col-3"><FormattedMessage id="Lead" /></th>
                                {(user.role === Roles.ADMIN || user.role === Roles.SALES_REP) && <th scope="col" className="col tdaction text-center col-1"><FormattedMessage id="Actions" /></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {clientsAssignmentsNodes}
                        </tbody>
                    </table>
                }
                {this.state.modal}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        clients: state.clients,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdPatientAdmin: (patient, successCallback) => dispatch(updPatientAdmin(patient, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientClients);