import APIUrl from "../../APIUrl";
import axios from 'axios';
import Util from "../../util/Util";

export const GET_USER = "GET_USER";

export function getUserAction(user){return {type: GET_USER, user: user}};

export const getUser = function(callback){
    return function(dispatch){
        return axios.get(APIUrl.getUser)
            .then(function(response){
                if (!response || !response.status) window.location.replace(Util.checkHttps(process.env.REACT_APP_LOGIN_FE_URL));

                dispatch(getUserAction(response.data));
                if(callback) callback();
            })
            .catch(function(){
                window.location.replace(Util.checkHttps(process.env.REACT_APP_LOGIN_FE_URL));
            });
    };
};

export const updateLang = function(lang){
    return function(dispatch){
        return axios.get(APIUrl.updateLang + lang)
            .then(function(){
                // Refresh the user state
                dispatch(getUser());
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const checkIfUsernameExists = function(username, existsCallback, noExistsCallback){
    return function(){
        return axios.get(APIUrl.checkIfUsernameExists + username)
            .then(function(response){
                if(response && response.status === 200) { if(existsCallback) existsCallback(); }
                else { if(noExistsCallback) noExistsCallback(); }
            });
    };
};

export const checkIfEmailIsValid = function(email, isValidCallback, notValidCallback){
    return function(){
        return axios.get(APIUrl.checkIfEmailIsValid + email)
            .then(function(response){
                if(response.status === 200) { 
                    if(isValidCallback) isValidCallback(response); 
                }
                else { 
                    if(notValidCallback) notValidCallback(response); 
                }
            });
    };
};

export const checkIfEmailExists = function(email, existsCallback, noExistsCallback){
    return function(){
        return axios.get(APIUrl.checkIfEmailExists + email)
            .then(function(response){
                if(response.status === 200) { if(existsCallback) existsCallback(); }
                else { if(noExistsCallback) noExistsCallback(); }
            });
    };
};

export const checkIfUpdatedEmailExists = function(email, existsCallback, noExistsCallback){
    return function(){
        return axios.get(APIUrl.checkIfUpdatedEmailExists + email)
            .then(function(response){
                if(response.status === 200) { if(existsCallback) existsCallback(); }
                else { if(noExistsCallback) noExistsCallback(); }
            });
    };
};

export const updateUser = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.updateUser, data)
            .then(function(){
                dispatch(getUser());
                if(successCallback) successCallback(); 
            });
    };
};

export const addFavorite = function(data, successCallback){
    // console.log("requete "+ data.updatedUser + " = "+data.updatedValue);
    return function(dispatch){
        return axios.post(APIUrl.addFavorite, data)
            .then(function(response){
                dispatch(getUser());
                if(successCallback) successCallback(); 
            });
    };
};

export const deleteFavorite = function(data, successCallback){
    // console.log("requetedelete "+ data.updatedUser + " = "+data.deletedValue);
    return function(dispatch){
        return axios.post(APIUrl.deleteFavorite, data)
            .then(function(response){
                dispatch(getUser());
                if(successCallback) successCallback(); 
            });
    };
};
