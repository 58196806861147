import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import AuditRoomsAddModalAdmin from './AuditRoomsAddModalAdmin';
import AuditDM from '../auditDM/AuditDM';
import { Modal, Tab, Tabs } from 'react-bootstrap';

class AuditRoomsModalAdmin extends React.Component {

    close() {
        this.props.closeModal();
    }

    stopEvent(e) {
        // e.preventDefault();
        e.stopPropagation();
    }

    getAuditRoom(auditRoomId) {
        for (let auditRoom of this.props.auditRooms) {
            if (auditRoom._id === auditRoomId) return auditRoom;
        }
    }

    render() {
        const { intl, auditRoomId } = this.props;

        let target = (this.props.target) ? this.props.target : "general";

        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id={auditRoomId ? "Audit.Room.Detail" : "Add.Room"} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Tabs defaultActiveKey={"settings-" + target} id="settingsTabs">
                        <Tab eventKey="settings-general" title={intl.formatMessage({ id: "General.Informations" })}>
                            <AuditRoomsAddModalAdmin
                                closeModal={() => this.close()}
                                auditRoomId={auditRoomId}
                            />
                        </Tab>
                        <Tab eventKey="settings-dmassociated" disabled={!auditRoomId} title={intl.formatMessage({ id: "Audit.Dm.list" })}>
                            {auditRoomId && <AuditDM
                                auditRoomId={auditRoomId}
                            />}
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        auditRooms: state.auditRooms,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuditRoomsModalAdmin));