import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_AUDIT_DESK_SETTINGS = "GET_AUDIT_DESK_SETTINGS";

function getAuditDeskSettingsAction(auditDeskSettings) {
  return {
    type: GET_AUDIT_DESK_SETTINGS,
    auditDeskSettings: auditDeskSettings,
  };
}

export const getAuditDeskSettings = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditDeskSettings)
      .then(function (response) {
        if (response) dispatch(getAuditDeskSettingsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateAuditDeskSettings = function (auditDeskSettings) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateAuditDeskSettings, auditDeskSettings)
      .then(function (response) {
        dispatch(getAuditDeskSettings());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addLogo = function (auditDeskSettingsId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addLogoAuditDeskSettings + auditDeskSettingsId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getAuditDeskSettings());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
