import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Button } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// Utils/Actions
import Util from '../../util/Util';
import { updateUser } from '../../actions/user/user';
// Components
import SuccessModal from "../sub/modals/SuccessModal";
import CustomLabel from "../sub/CustomLabel";

class UpdatePwdForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: "",
            passwordConfirmation: "",
            disabled: false,
            error: null,
            modal: null,
        }
    }

    onChange(key, value) {
        this.setState({
            [key]: value,
            error: null
        });
        return;
    }

    hasEmptyField() {
        return (Util.emptyString(this.state.password) || Util.emptyString(this.state.passwordConfirmation));
    }

    closeModal() {
        this.setState({
            modal: null,
            password: "",
            passwordConfirmation: "",
        });
        return;
    }

    update() {
        // Check password parity
        if ((this.state.password !== this.state.passwordConfirmation)) {
            let errorMessage = this.props.intl.formatMessage({ id: "Password.Does.Not.Match" });
            NotificationManager.error(errorMessage);
            return;
        }

        // Check password syntax
        if (!Util.checkValidPasswordForRecovery(this.state.password)) {
            let errorMessage = this.props.intl.formatMessage({ id: "Password.Not.Conform" });
            NotificationManager.error(errorMessage);
            return;
        }

        const data = {
            updatedField: "password",
            updatedValue: this.state.password
        };

        this.setState({ disabled: true });

        const onUpdatePwdSuccess = () => {
            const title = <FormattedMessage id="Success" />
            const content = <FormattedMessage id="Password.Reset.Success" />
            this.setState({
                disabled: false,
                modal: <SuccessModal
                    isOpen={true}
                    title={title}
                    content={content}
                    closeModal={() => this.closeModal()} />
            });
            return;
        };

        this.props.onUpdateUser(data, onUpdatePwdSuccess);
    };

    render() {
        const ConfirmationButton = (
            <Button type="submit" className="btn btn-info btn-block col-7 m-auto"
                onClick={(e) => this.update()}
                disabled={this.state.disabled || this.hasEmptyField()}>
                <FormattedMessage id="Password.Modify" />
            </Button>
        );
        return (
            <React.Fragment>
                <Form className="col-12 col-sm-8">
                    <Form.Group className="form-group row">
                        <CustomLabel label={this.props.intl.formatMessage({ id: "Password" })}
                            required htmlFor="password"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        {/* // hidden cuz not obligatory for us & our solution, but DOM & React give an advert : check -> https://www.chromium.org/developers/design-documents/create-amazing-password-forms  */}
                        <Form.Control hidden id="usernamePwChange" type="text" autoComplete="username" value={this.props.user.username} />
                        <Form.Control className="col-12 col-sm-7 form-control" id="password" type="password" autoComplete="new-password"
                            value={this.state.password}
                            onChange={(e) => this.onChange("password", e.target.value)} />
                    </Form.Group>
                    <Form.Group className="form-group row">
                        <CustomLabel label={this.props.intl.formatMessage({ id: "Password.Confirmation" })}
                            required htmlFor="passwordConfirmation"
                            labelClassName="col-12 col-sm-5 col-form-label" />
                        <Form.Control className="col-12 col-sm-7 form-control" id="passwordConfirmation" type="password" autoComplete="new-password"
                            value={this.state.passwordConfirmation}
                            onChange={(e) => this.onChange("passwordConfirmation", e.target.value)} />
                    </Form.Group>

                    <br /><br />
                    {ConfirmationButton}
                </Form>
                {this.state.modal}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUser: (data, successCallback) => dispatch(updateUser(data, successCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UpdatePwdForm));
