import XLSX from 'xlsx';

export default class ExcelUtil{

    // Will save your excelData into a file. Make sure the excelData is aoa (array of array). See method ExcelUtil.toExcel()
    static save(excelData, fileNameWithoutExtension){
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(excelData);
 
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        XLSX.writeFile(wb, fileNameWithoutExtension + ".xlsx");
    }

    // 'data' must be an array of data like this : [{field1: value1, field2: value2...}, {field1: value1, field2: value2...}, ...]
    // 'ignoreCols' : columns to ignore ('_id' etc.)
    static toExcel(data, columnsMapping, ignoreCols=[]){
        // Excel format is an array of arrays (aoa). Column names are the first array of the list
        let excel = [];

        // First, the columns
        let keys = [];
        outer:for(let key of Object.keys(data[0])){
            for(let col of ignoreCols) if(key === col) continue outer;
            keys.push(columnsMapping[key] || key);
        };
        excel.push(keys);

        // Then, the data
        for(let d of data){
            let values = [];
            outer:for(let key of Object.keys(d)){
                for(let col of ignoreCols) if(key === col) continue outer;
                values.push(d[key]);
            }
            excel.push(values);
        }

        return excel;
    }

    static parse(file, callback) {
        var reader = new FileReader();

        reader.onload = function (e) {
            var data = e.target.result;
            var workbook = XLSX.read(data, {type: 'binary'});

            /**
             * When a files had more than one sheet, import fails
             * So we tell the program only to care about the first one
             */
            /*
            workbook.SheetNames.forEach(function (sheetName) {
                var XL_raw_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);

                callback(XL_raw_object);
            });
            */

            // https://github.com/SheetJS/sheetjs/issues/50
            var XL_raw_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {defval: "", blankrows: false});
            callback(XL_raw_object);
        };

        reader.onerror = function (err) {
            throw err
        };

        reader.readAsBinaryString(file);
    };

}