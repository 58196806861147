import React from 'react';
import Creatable from 'react-select/creatable';
import { FormattedMessage } from 'react-intl';
import Util from '../../util/Util';

export default class ComboBox extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {
            option: null
        };

        // If we have an initial default option - set it
        if(this.props.defaultOption){
            var option = this.getOptionById(this.props.defaultOption);

            if(option){
                this.state = {
                    option: this.formatOption(option)
                };
            }
        }
    }

    getOptionById(optionId){
        for(let option of this.props.options){
            if(option._id === optionId) return option;
        }
    }

    formatOptions(options){
        let formattedOptions = [];
        for(let option of options) formattedOptions.push(this.formatOption(option));
        return formattedOptions;
    }

    formatOption(option){
        return {value: option._id, label: option.name};
    }

    createOption(optionName){
        if(Util.emptyString(optionName)) return;
        
        var successCallback = (newOption) => {
            this.setState({option: this.formatOption(newOption)});
            this.props.onChange(newOption._id);
        };
        
        this.props.onCreateOption({name: optionName}, successCallback);
    }

    onChange(option){
        this.setState({option: option});

        if(this.props.onChange) this.props.onChange(option.value);
    }

    onBlur(){
        if(!this.state.option) return;

        if(this.props.onBlur) this.props.onBlur(this.state.option.value);
    }

    isAValidOption(optionId){
        for(let option of this.props.options){
            if(optionId === option._id){
                return true;
            } 
        }

        return false;
    }

    render(){
        var options = this.formatOptions(this.props.options);

        var optionValue = this.state.option;

        if(!Util.emptyString(this.state.option) && !this.isAValidOption(this.props.defaultOption)){
            optionValue = null;
        }

        return (
            <Creatable
                menuPlacement={this.props.placement || "auto"}
                onCreateOption={(value) => this.createOption(value)}
                onChange={(value) => this.onChange(value)}
                onBlur={() => this.onBlur()}
                isDisabled={this.props.disabled}
                value={optionValue}
                options={options}
                noOptionsMessage={(val) => <FormattedMessage id="No.Options" />}
                formatCreateLabel={(val) => <FormattedMessage id="Create.Val" values={{val: val}} />}
                placeholder={this.props.placeholder || <FormattedMessage id="Select" />}
                />
        );
    }

}